import Axios from "axios";
import { getDailyKey } from "../../../common/utils/localStorage";
import { dailyPlotle } from "./dailyPlotle";
import { WikiData, getWikipediaPageSection } from "../../../common/wikipedia/wikiapi";

export const getDailyWikiData = async (): Promise<WikiData> => {
  const dailyKey = getDailyKey();
  const movie = dailyPlotle[dailyKey as any];
  return await getWikipediaPageSection(movie.name, movie.wikiPageTitle, "Plot")
};
