export const getGamesPlayed = (key: string): number => {
  const result = localStorage.getItem(key);
  try {
    if (result !== null) {
      const parsedData = JSON.parse(result);
      return Object.keys(parsedData).length;
    }
  } catch (error) {
    return 0;
  }
  return 0;
};

export const getGamesWon = (key: string) => {
  const result = localStorage.getItem(key);
  try {
    if (result !== null) {
      let gamesWon = 0;
      const parsedData = JSON.parse(result);
      for (const [key, value] of Object.entries(parsedData)) {
        if ((value as any).isWinner) {
          gamesWon++;
        }
      }
      return gamesWon;
    }
  } catch (error) {
    return 0;
  }
  return 0;
};

export const getCurrentStreak = (key: string) => {
  const result = localStorage.getItem(key);
  try {
    if (result !== null) {
      let streak = 0;
      const parsedData = JSON.parse(result);
      const date = new Date();
      while (parsedData[`${date.getMonth()}-${date.getDate()}`] !== undefined) {
        streak++;
        date.setDate(date.getDate() - 1);
      }
      return streak;
    }
  } catch (error) {
    return 0;
  }
  return 0;
};
