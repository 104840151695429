import { Button, Input } from "@chakra-ui/react";
import React from "react";
import { isMobile } from "../../../common/utils/mobile";

interface ActlInputProps {
  handleSubmit: Function;
  setGuessedWordsInput: Function;
  guessedWordsInput: string;
  isDisabled: boolean;
}

const Inputs = ({
  isDisabled,
  handleSubmit,
  guessedWordsInput,
  setGuessedWordsInput,
}: ActlInputProps) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Input
            isDisabled={isDisabled}
            isReadOnly={isMobile()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            value={guessedWordsInput}
            onChange={(e) => setGuessedWordsInput(e.currentTarget.value)}
          />
          <div
            style={{
              paddingLeft: "5px",
            }}
          >
            <Button isDisabled={isDisabled} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inputs;
