export interface LocalHiloData {
  gameState: string[][];
  currentSquare: number;
  currentRow: number;
  resultCol: string[];
  evalCol: number[];
  isWinner: boolean;
}

export const getLocalStorageData = () => {
  const data = localStorage.getItem("hilo");
  if (data) {
    return JSON.parse(data) as any;
  }
  return null;
};

export const getCurrentAnswer = (
  gameState: string[][],
  currentRow: number
): number => {
  let answer = 0;
  for (let i = 0; i < gameState[currentRow].length; i += 2) {
    const num = parseInt(gameState[currentRow][i]);
    if (i === 0) {
      answer += num;
    } else if (gameState[currentRow][i - 1] === "-") {
      answer -= num;
    } else {
      answer += num;
    }
  }
  return answer;
};
