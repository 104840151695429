interface DailyEncrypedia {
    name: string;
  }

export const dailyEncrypedia: {[key: string] : DailyEncrypedia} = {
    "0-0": {"name":"Jackie Robinson"},
    "0-1": {"name":"Sigourney Weaver"},
    "0-2": {"name":"Magnus Carlsen"},
    "0-3": {"name":"John Carmack"},
    "0-4": {"name":"Fyodor Dostoyevsky"},
    "0-5": {"name":"Bruno Mars"},
    "0-6": {"name":"Larry Bird"},
    "0-7": {"name":"Michael Jackson"},
    "0-8": {"name":"Tim Burton"},
    "0-9": {"name":"Julius Erving"},
    "0-10": {"name":"Ludwig Van Beethoven"},
    "0-11": {"name":"Katie Ledecky"},
    "0-12": {"name":"Harry Houdini"},
    "0-13": {"name":"Lucille Ball"},
    "0-14": {"name":"H. P. Lovecraft"},
    "0-15": {"name":"George R. R. Martin"},
    "0-16": {"name":"Alan Turing"},
    "0-17": {"name":"Nolan Bushnell"},
    "0-18": {"name":"Andrew Carnegie"},
    "0-19": {"name":"Nelson Mandela"},
    "0-20": {"name":"Dick Van Dyke"},
    "0-21": {"name":"Brad Pitt"},
    "0-22": {"name":"Roald Dahl"},
    "0-23": {"name":"F. Scott Fitzgerald"},
    "0-24": {"name":"Buddy Holly"},
    "0-25": {"name":"Jeff Bezos"},
    "0-26": {"name":"David Beckham"},
    "0-27": {"name":"Jet Li"},
    "0-28": {"name":"Zoe Saldana"},
    "0-29": {"name":"Chuck Norris"},
    "0-30": {"name":"Ted Williams"},
    "1-0": {"name":"Dr. Dre"},
    "1-1": {"name":"Mark Hamill"},
    "1-2": {"name":"Jerry Seinfeld"},
    "1-3": {"name":"Dwight Eisenhower"},
    "1-4": {"name":"Thomas Edison"},
    "1-5": {"name":"Colonel Sanders"},
    "1-6": {"name":"Alexander the Great"},
    "1-7": {"name":"Kurt Kobain"},
    "1-8": {"name":"Masahiro Sakurai"},
    "1-9": {"name":"Stanley Kubrick"},
    "1-10": {"name":"Adele"},
    "1-11": {"name":"Al Pacino"},
    "1-12": {"name":"Derek Jeter"},
    "1-13": {"name":"Lewis Carroll"},
    "1-14": {"name":"Marilyn Monroe"},
    "1-15": {"name":"Jose Rizal"},
    "1-16": {"name":"Shaun White"},
    "1-17": {"name":"Ichiro Suzuki"},
    "1-18": {"name":"Tim Allen"},
    "1-19": {"name":"Pythagoras"},
    "1-20": {"name":"Hank Aaron"},
    "1-21": {"name":"Marlon Brando"},
    "1-22": {"name":"Steve Job"},
    "1-23": {"name":"Leonardo Dicaprio"},
    "1-24": {"name":"Frank Sinatra"},
    "1-25": {"name":"Galileo Galilei"},
    "1-26": {"name":"Jane Austen"},
    "1-27": {"name":"Garry Kasparov"},
    "1-28": {"name":"Wernher Von Braun"},
    "2-0": {"name":"Yogi Berra"},
    "2-1": {"name":"Whitney Houston"},
    "2-2": {"name":"Florence Nightingale"},
    "2-3": {"name":"Louis Armstrong"},
    "2-4": {"name":"Jimi Hendrix"},
    "2-5": {"name":"David Bowie"},
    "2-6": {"name":"Mahatma Gandhi"},
    "2-7": {"name":"Snoop Dogg"},
    "2-8": {"name":"Stevie Nicks"},
    "2-9": {"name":"Little Richard"},
    "2-10": {"name":"Jack Ma"},
    "2-11": {"name":"Sam Walton"},
    "2-12": {"name":"Salvador Dal"},
    "2-13": {"name":"Steve Wozniak"},
    "2-14": {"name":"James Brown"},
    "2-15": {"name":"Helen Keller"},
    "2-16": {"name":"Olivia Newton John"},
    "2-17": {"name":"John D. Rockefeller"},
    "2-18": {"name":"Ray Romano"},
    "2-19": {"name":"Adam Sandler"},
    "2-20": {"name":"Israel Kamakawiwo’ole"},
    "2-21": {"name":"Guccio Gucci"},
    "2-22": {"name":"Theodore Roosevelt"},
    "2-23": {"name":"Robert E. Lee"},
    "2-24": {"name":"Cleopatra"},
    "2-25": {"name":"Beyonce"},
    "2-26": {"name":"Guillermo del Toro"},
    "2-27": {"name":"Pat Morita"},
    "2-28": {"name":"Tom Petty"},
    "2-29": {"name":"Miles Teller"},
    "2-30": {"name":"Lebron James"},
    "3-0": {"name":"James Cameron"},
    "3-1": {"name":"Bruce Lee"},
    "3-2": {"name":"Leslie Nielsen"},
    "3-3": {"name":"Audrey Hepburn"},
    "3-4": {"name":"Franz Kafka"},
    "3-5": {"name":"John Steinbeck"},
    "3-6": {"name":"Ada lovelace"},
    "3-7": {"name":"Ferdinand Magellan"},
    "3-8": {"name":"Novak Djokovic"},
    "3-9": {"name":"Bob Ross"},
    "3-10": {"name":"Rod Stewart"},
    "3-11": {"name":"Bernard Arnault"},
    "3-12": {"name":"George Washington"},
    "3-13": {"name":"Gordon Ramsay"},
    "3-14": {"name":"Bo Jackson"},
    "3-15": {"name":"Neil Armstrong"},
    "3-16": {"name":"Napoleon Bonaparte"},
    "3-17": {"name":"Martin Luther"},
    "3-18": {"name":"Pablo Picasso"},
    "3-19": {"name":"Pierce Brosnan"},
    "3-20": {"name":"Usain Bolt"},
    "3-21": {"name":"Marie Curie"},
    "3-22": {"name":"Orson Welles"},
    "3-23": {"name":"Lou Gehrig"},
    "3-24": {"name":"Sylvester Stallone"},
    "3-25": {"name":"Hulk Hogan"},
    "3-26": {"name":"Agatha Christie"},
    "3-27": {"name":"Lionel Messi"},
    "3-28": {"name":"Robert H. Goddard"},
    "3-29": {"name":"Frank Herbert"},
    "4-0": {"name":"Mike Myers"},
    "4-1": {"name":"Manny Pacquiao"},
    "4-2": {"name":"Russell Crowe"},
    "4-3": {"name":"Johnny Cash"},
    "4-4": {"name":"JRR Tolkien"},
    "4-5": {"name":"Eddie Murphy"},
    "4-6": {"name":"Mel Brooks"},
    "4-7": {"name":"Hidetaka Miyazaki"},
    "4-8": {"name":"Leif Erikson"},
    "4-9": {"name":"Tom Hanks"},
    "4-10": {"name":"Montgolfier brothers"},
    "4-11": {"name":"Nicolas Cage"},
    "4-12": {"name":"John Lennon"},
    "4-13": {"name":"Will Ferrell"},
    "4-14": {"name":"Fred Rogers"},
    "4-15": {"name":"Charlie Chaplin"},
    "4-16": {"name":"Jim Henson"},
    "4-17": {"name":"Aristotle"},
    "4-18": {"name":"Larry David"},
    "4-19": {"name":"Alexandre Dumas"},
    "4-20": {"name":"Archimedes"},
    "4-21": {"name":"Peter Jackson"},
    "4-22": {"name":"Tiger Woods"},
    "4-23": {"name":"Suzanne Collins"},
    "4-24": {"name":"Cristiano Ronaldo"},
    "4-25": {"name":"Alexander Hamilton"},
    "4-26": {"name":"Franklin D. Roosevelt"},
    "4-27": {"name":"Sun Tzu"},
    "4-28": {"name":"Henry Ford"},
    "4-29": {"name":"Bob Marley"},
    "4-30": {"name":"P.T. Barnum"},
    "5-0": {"name":"Quincy Jones"},
    "5-1": {"name":"C. S. Lewis"},
    "5-2": {"name":"George Lucas"},
    "5-3": {"name":"Prince"},
    "5-4": {"name":"Wright Brothers"},
    "5-5": {"name":"Quentin Tarantino"},
    "5-6": {"name":"Ray Charles"},
    "5-7": {"name":"Shigeru Miyamoto"},
    "5-8": {"name":"Johann Sebastian Bach"},
    "5-9": {"name":"Paul Mccartney"},
    "5-10": {"name":"Gabe Newell"},
    "5-11": {"name":"Orlando Bloom"},
    "5-12": {"name":"Richard Branson"},
    "5-13": {"name":"Francis Drake"},
    "5-14": {"name":"Marco Polo"},
    "5-15": {"name":"Johnny Depp"},
    "5-16": {"name":"William Hanna"},
    "5-17": {"name":"Ariana Grande"},
    "5-18": {"name":"Eli Whitney"},
    "5-19": {"name":"Michael Phelps"},
    "5-20": {"name":"Avicii"},
    "5-21": {"name":"Charles Dickens"},
    "5-22": {"name":"Amelia Earhart"},
    "5-23": {"name":"Jodie Foster"},
    "5-24": {"name":"Alfred Hitchcock"},
    "5-25": {"name":"Miles Davis"},
    "5-26": {"name":"Plato"},
    "5-27": {"name":"Mark Zuckerberg"},
    "5-28": {"name":"Jimmy Carter"},
    "5-29": {"name":"Stan Lee"},
    "6-0": {"name":"Bill Gates"},
    "6-1": {"name":"Andrew Lloyd Weber"},
    "6-2": {"name":"Rihanna"},
    "6-3": {"name":"Michael J. Fox"},
    "6-4": {"name":"Jk Rowling"},
    "6-5": {"name":"Sigmund Freud"},
    "6-6": {"name":"Taylor Swift"},
    "6-7": {"name":"H. G. Wells"},
    "6-8": {"name":"Magic Johnson"},
    "6-9": {"name":"Isaac Newton"},
    "6-10": {"name":"Gregor Mendel"},
    "6-11": {"name":"Michaelangelo"},
    "6-12": {"name":"John Locke"},
    "6-13": {"name":"Babe Ruth"},
    "6-14": {"name":"Donald Trump"},
    "6-15": {"name":"Ed Sheeran"},
    "6-16": {"name":"Auguste Lumiere"},
    "6-17": {"name":"Hideo Kojima"},
    "6-18": {"name":"Steve Irwin"},
    "6-19": {"name":"Michael Keaton"},
    "6-20": {"name":"Mick Jagger"},
    "6-21": {"name":"Paul Allen"},
    "6-22": {"name":"Arnold Schwarzenegger"},
    "6-23": {"name":"Billy Joel"},
    "6-24": {"name":"Harper Lee"},
    "6-25": {"name":"Martin Luther King Jr."},
    "6-26": {"name":"Jack Kirby"},
    "6-27": {"name":"Oprah Winfrey"},
    "6-28": {"name":"Homer"},
    "6-29": {"name":"Raphael"},
    "6-30": {"name":"Stephen Curry"},
    "7-0": {"name":"Christian Bale"},
    "7-1": {"name":"Shaquille O'Neal"},
    "7-2": {"name":"Oskar Schindler"},
    "7-3": {"name":"Johnny Knoxville"},
    "7-4": {"name":"Rodney Mullen"},
    "7-5": {"name":"Dale Earnhardt"},
    "7-6": {"name":"Kendrick Lamar"},
    "7-7": {"name":"Floyd Maywether"},
    "7-8": {"name":"Michael Jordan"},
    "7-9": {"name":"John Denver"},
    "7-10": {"name":"Francis Ford Coppola"},
    "7-11": {"name":"Elvis Presley"},
    "7-12": {"name":"Benjamin Franklin"},
    "7-13": {"name":"Wayne Gretzky"},
    "7-14": {"name":"John F. Kennedy"},
    "7-15": {"name":"Kareem Abduul Jabaar"},
    "7-16": {"name":"George Eastman"},
    "7-17": {"name":"Stephenie Meyer"},
    "7-18": {"name":"Eminem"},
    "7-19": {"name":"Venus Williams"},
    "7-20": {"name":"Hayley Williams"},
    "7-21": {"name":"George Clooney"},
    "7-22": {"name":"Otis Redding"},
    "7-23": {"name":"Joe Montana"},
    "7-24": {"name":"Payton Manning"},
    "7-25": {"name":"George Washington Carver"},
    "7-26": {"name":"Joan Ganz Cooney"},
    "7-27": {"name":"James Naismith"},
    "7-28": {"name":"Hayao Miyazaki"},
    "7-29": {"name":"Bobby Fischer"},
    "7-30": {"name":"Phil Jackson"},
    "8-0": {"name":"Ozzy Osbourne"},
    "8-1": {"name":"Satoshi Tajiri"},
    "8-2": {"name":"Frederick Douglass"},
    "8-3": {"name":"James Earl Jones"},
    "8-4": {"name":"Phil Knight"},
    "8-5": {"name":"Seth MacFarlane"},
    "8-6": {"name":"Morgan Freeman"},
    "8-7": {"name":"Thomas Jefferson"},
    "8-8": {"name":"Macaulay Culkin"},
    "8-9": {"name":"Ian Fleming"},
    "8-10": {"name":"Sam Elliott"},
    "8-11": {"name":"Julius Caesar"},
    "8-12": {"name":"Anne Frank"},
    "8-13": {"name":"Nikola Tesla"},
    "8-14": {"name":"Satoru Iwata"},
    "8-15": {"name":"Joe Dimaggio"},
    "8-16": {"name":"George Harrison"},
    "8-17": {"name":"Chris Kyle"},
    "8-18": {"name":"Larry Page"},
    "8-19": {"name":"Denzel Washington"},
    "8-20": {"name":"Tupac Shakur"},
    "8-21": {"name":"Coco Chanel"},
    "8-22": {"name":"Socrates"},
    "8-23": {"name":"Dr. Seuss"},
    "8-24": {"name":"Paul Reubens"},
    "8-25": {"name":"Robert Downey Jr."},
    "8-26": {"name":"Joe Frazier"},
    "8-27": {"name":"Ray Kroc"},
    "8-28": {"name":"Bob Barker"},
    "8-29": {"name":"Betty White"},
    "9-0": {"name":"Tom Brady"},
    "9-1": {"name":"Pelé"},
    "9-2": {"name":"Blaise Pascal"},
    "9-3": {"name":"Wolfgang Armadeus Mozart"},
    "9-4": {"name":"George Patton"},
    "9-5": {"name":"Paul Revere"},
    "9-6": {"name":"Malcolm X"},
    "9-7": {"name":"Louis Pasteur"},
    "9-8": {"name":"Jackie Chan"},
    "9-9": {"name":"John Glenn"},
    "9-10": {"name":"Herodotus"},
    "9-11": {"name":"Albert Einstein"},
    "9-12": {"name":"Robert Fulton"},
    "9-13": {"name":"Jesus"},
    "9-14": {"name":"Usher"},
    "9-15": {"name":"Vincent Van Gogh"},
    "9-16": {"name":"Lady Gaga"},
    "9-17": {"name":"Eddie Van Halen"},
    "9-18": {"name":"William Boeing"},
    "9-19": {"name":"Muhammad Ali"},
    "9-20": {"name":"Leonardo Da Vinci"},
    "9-21": {"name":"John Madden"},
    "9-22": {"name":"Stevie Wonder"},
    "9-23": {"name":"Roger Federer"},
    "9-24": {"name":"Dan Marino"},
    "9-25": {"name":"Samuel L. Jackson"},
    "9-26": {"name":"Clint Eastwood"},
    "9-27": {"name":"Molly Brown"},
    "9-28": {"name":"Johnny Knoxville"},
    "9-29": {"name":"Christover Reeve"},
    "9-30": {"name":"Carl Lewis"},
    "10-0": {"name":"Willie Mays"},
    "10-1": {"name":"Alexander Graham Bell"},
    "10-2": {"name":"Roberto Clemente"},
    "10-3": {"name":"Akira Toriyama"},
    "10-4": {"name":"George Foreman"},
    "10-5": {"name":"Tina Turner"},
    "10-6": {"name":"Louis Braille"},
    "10-7": {"name":"James Hetfield"},
    "10-8": {"name":"Samuel Morse"},
    "10-9": {"name":"Jon Bon Jovi"},
    "10-10": {"name":"Kobe Bryant"},
    "10-11": {"name":"Ben Stiller"},
    "10-12": {"name":"Mark Twain"},
    "10-13": {"name":"Walt Disney"},
    "10-14": {"name":"Steve Ditko"},
    "10-15": {"name":"Freddie Mercury"},
    "10-16": {"name":"Anne Hathaway"},
    "10-17": {"name":"Howard Hughes"},
    "10-18": {"name":"Grace Hopper"},
    "10-19": {"name":"Frankie Valli"},
    "10-20": {"name":"Willie Nelson"},
    "10-21": {"name":"Tom Cruise"},
    "10-22": {"name":"Steven Spielberg"},
    "10-23": {"name":"Gianni Versace"},
    "10-24": {"name":"William Shakespeare"},
    "10-25": {"name":"Harrison Ford"},
    "10-26": {"name":"Buzz Aldrin"},
    "10-27": {"name":"Billy Idol"},
    "10-28": {"name":"Harriet Tubman"},
    "10-29": {"name":"Leo Tolstoy"},
    "11-0": {"name":"Johannes Gutenberg"},
    "11-1": {"name":"Trey Parker"},
    "11-2": {"name":"Marcus Aurelius"},
    "11-3": {"name":"Keanu Reeves"},
    "11-4": {"name":"B. B. King"},
    "11-5": {"name":"Winston Churchill"},
    "11-6": {"name":"Barack Obama"},
    "11-7": {"name":"Steve Martin"},
    "11-8": {"name":"Marvin Gaye"},
    "11-9": {"name":"Paul Simon"},
    "11-10": {"name":"Christopher Columbus"},
    "11-11": {"name":"Dave Chappelle"},
    "11-12": {"name":"Aretha Franklin"},
    "11-13": {"name":"Werner Heisenberg"},
    "11-14": {"name":"Lin-Manuel Miranda"},
    "11-15": {"name":"Owen Wilson"},
    "11-16": {"name":"Bob Dylan"},
    "11-17": {"name":"Elon Musk"},
    "11-18": {"name":"Tony Hawk"},
    "11-19": {"name":"Pat Sajak"},
    "11-20": {"name":"George Orwell"},
    "11-21": {"name":"Mark Wahlberg"},
    "11-22": {"name":"Jack Dorsey"},
    "11-23": {"name":"Justin Bieber"},
    "11-24": {"name":"Tim Berners-Lee"},
    "11-25": {"name":"Rosa Parks"},
    "11-26": {"name":"Hironobu Sakaguchi"},
    "11-27": {"name":"Princess Diana"},
    "11-28": {"name":"Stephen King"},
    "11-29": {"name":"Warren Buffett"},
    "11-30": {"name":"Larry Ellison"}
    }