import React from "react";
import { SquareInputs } from "../utils/utils";

interface AnswerColumnCellProps {
  item: string;
  column: number;
  squareInputs: SquareInputs;
  answers: SquareInputs;
  gameBoard: string[][];
}

const getBackgroundColor = (
  column: number,
  squareInputs: SquareInputs,
  answers: SquareInputs,
  item: string,
  gameBoard: string[][]
): string => {
  try {
    const target = parseInt(item);

    let currentTotal = parseInt(
      squareInputs[`0-${column}` as keyof SquareInputs]
    );

    let num2 = parseInt(gameBoard[2][column]);
    if (column === 2) {
      num2 = parseInt(squareInputs[`2-${column}` as keyof SquareInputs]);
    }

    if (gameBoard[1][column] === "-") {
      currentTotal -= num2;
    } else {
      currentTotal += num2;
    }

    if (gameBoard[3][column] === "-") {
      currentTotal -= parseInt(
        squareInputs[`4-${column}` as keyof SquareInputs]
      );
    } else {
      currentTotal += parseInt(
        squareInputs[`4-${column}` as keyof SquareInputs]
      );
    }
    if (currentTotal === target) {
      return "green";
    }
  } catch (e) {
    return "red";
  }

  return "red";
};

const AnswerColumnCell = ({
  item,
  column,
  squareInputs,
  answers,
  gameBoard,
}: AnswerColumnCellProps) => {
  if (item === "_") {
    return <div></div>;
  }
  return (
    <div
      style={{
        marginTop: "3vh",
        color: "white",
        backgroundColor: getBackgroundColor(
          column,
          squareInputs,
          answers,
          item,
          gameBoard
        ),
      }}
    >
      <div>{item}</div>
    </div>
  );
};

export default AnswerColumnCell;
