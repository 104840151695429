export const STAGES = {
    LOCAL : "LOCAL",
    PROD: "PROD"
}

export const getStage = (hostname: string) => {
    if (hostname === "localhost") {
        return STAGES.LOCAL
    }
    return STAGES.PROD
}