import React from "react";
import { getCurrentAnswer } from "../utils";

interface ResultDisplayProps {
  resultCol: string[];
  currentRow: number;
  currentSquare: number;
  gameState: string[][];
}

const getColor = (result: string) => {
  if (result === "_") {
    return "black";
  }
  return "white";
};

const getBackgroundColor = (result: string) => {
  if (result === "_") {
    return "white";
  }
  const lowerCase = result.toLowerCase();
  if (lowerCase.includes("win")) {
    return "green";
  }
  return "red";
};

const ResultDisplay = ({
  resultCol,
  currentRow,
  currentSquare,
  gameState
}: ResultDisplayProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(5, 1fr)",
        boxSizing: "border-box",
        rowGap: "5px",
      }}
    >
      {resultCol.map((result, i) => (
        <div
          key={i}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            boxSizing: "border-box",
            columnGap: "5px",
          }}
        >
          {i === currentRow && currentSquare === 7 ? (
            <div
              style={{
                padding: "5px",
                border: "solid",
                color: "grey",
              }}
            >
              {getCurrentAnswer(gameState, currentRow)}
            </div>
          ) : (
            <div
              style={{
                padding: "5px",
                border: "solid",
                color: getColor(result),
                backgroundColor: getBackgroundColor(result),
              }}
            >
              {result}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ResultDisplay;
