interface DailyHilo {
  name: string;
  wikiPageTitle: string;
  section?: number;
}

export const dailyPlotle: { [key: string]: DailyHilo } = {
  "0-0": { name: "The Avengers", wikiPageTitle: "The_Avengers_(2012_film)" },
  "0-1": {
    name: "Guardians of the Galaxy",
    wikiPageTitle: "Guardians_of_the_Galaxy_(film)",
  },
  "0-2": { name: "The Hunger Games", wikiPageTitle: "The_Hunger_Games_(film)" },
  "0-3": { name: "Spider Man 2", wikiPageTitle: "Spider-Man_2" },
  "0-4": { name: "Coco", wikiPageTitle: "Coco_(2017_film)" },
  "0-5": { name: "Star Wars A New Hope", wikiPageTitle: "Star_Wars_(film)" },
  "0-6": { name: "The Prestige", wikiPageTitle: "The_Prestige_(film)" },
  "0-7": { name: "Zootopia", wikiPageTitle: "Zootopia" },
  "0-8": {
    name: "Pirates of the Caribbean The Curse of the Black Pearl",
    wikiPageTitle: "Pirates_of_the_Caribbean:_The_Curse_of_the_Black_Pearl",
  },
  "0-9": { name: "Rogue One", wikiPageTitle: "Rogue_One" },
  "0-10": { name: "Mulan", wikiPageTitle: "Mulan_(1998_film)" },
  "0-11": { name: "Toy Story 3", wikiPageTitle: "Toy_Story_3" },
  "0-12": { name: "The Matrix", wikiPageTitle: "The_Matrix" },
  "0-13": { name: "Avengers Endgame", wikiPageTitle: "Avengers:_Endgame" },
  "0-14": {
    name: "The Bourne Identity",
    wikiPageTitle: "The_Bourne_Identity_(2002_film)",
  },
  "0-15": { name: "Top Gun", wikiPageTitle: "Top_Gun" },
  "0-16": {
    name: "The Lord of the Rings The Return of the King",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Return_of_the_King",
  },
  "0-17": {
    name: "Spider Man Homecoming",
    wikiPageTitle: "Spider-Man:_Homecoming",
  },
  "0-18": { name: "Peter Pan", wikiPageTitle: "Peter_Pan_(1953_film)" },
  "0-19": {
    name: "Home Alone 2 Lost in New York",
    wikiPageTitle: "Home_Alone_2:_Lost_in_New_York",
  },
  "0-20": {
    name: "Guardians of the Galaxy Volume 2",
    wikiPageTitle: "Guardians_of_the_Galaxy_Vol._2",
  },
  "0-21": {
    name: "The Lord of the Rings The Two Towers",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Two_Towers",
  },
  "0-22": { name: "Ant Man", wikiPageTitle: "Ant-Man_(film)" },
  "0-23": {
    name: "Ferris Buellers Day Off",
    wikiPageTitle: "Ferris_Bueller%27s_Day_Off",
  },
  "0-24": { name: "Transformers", wikiPageTitle: "Transformers_(film)" },
  "0-25": {
    name: "How to Train Your Dragon",
    wikiPageTitle: "How_to_Train_Your_Dragon_(film)",
  },
  "0-26": { name: "Turning Red", wikiPageTitle: "Turning_Red" },
  "0-27": { name: "Shrek 2", wikiPageTitle: "Shrek_2" },
  "0-28": { name: "Rain Man", wikiPageTitle: "Rain_Man" },
  "0-29": { name: "Black Panther", wikiPageTitle: "Black_Panther_(film)" },
  "0-30": {
    name: "Chicken Little",
    wikiPageTitle: "Chicken_Little_(2005_film)",
  },
  "0-31": {
    name: "Harry Potter and the Deathly Hallows Part 1",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_1",
  },
  "1-0": { name: "Spider-Man_3", wikiPageTitle: "Spider-Man_3" },
  "1-1": { name: "Lilo and Stitch", wikiPageTitle: "Lilo_%26_Stitch" },
  "1-2": {
    name: "The Hunger Games Catching Fire",
    wikiPageTitle: "The_Hunger_Games:_Catching_Fire",
  },
  "1-3": {
    name: "The Wizard of Oz",
    wikiPageTitle: "The_Wizard_of_Oz_(1939_film)",
  },
  "1-4": { name: "Toy Story 2", wikiPageTitle: "Toy_Story_2" },
  "1-5": {
    name: "Harry Potter and the Half Blood Prince",
    wikiPageTitle: "Harry_Potter_and_the_Half-Blood_Prince_(film)",
  },
  "1-6": { name: "Inside Out", wikiPageTitle: "Inside_Out_(2015_film)" },
  "1-7": { name: "Pocahontas", wikiPageTitle: "Pocahontas_(1995_film)" },
  "1-8": { name: "Incredibles 2", wikiPageTitle: "Incredibles_2" },
  "1-9": { name: "Tarzan", wikiPageTitle: "Tarzan_(1999_film)" },
  "1-10": { name: "Oceans Eleven", wikiPageTitle: "Ocean%27s_Eleven" },
  "1-11": {
    name: "Its a Wonderful Life",
    wikiPageTitle: "It%27s_a_Wonderful_Life",
  },
  "1-12": {
    name: "Harry Potter and the Order of the Phoenix",
    wikiPageTitle: "Harry_Potter_and_the_Order_of_the_Phoenix_(film)",
  },
  "1-13": { name: "Zoolander", wikiPageTitle: "Zoolander" },
  "1-14": { name: "Cinderella", wikiPageTitle: "Cinderella_(1950_film)" },
  "1-15": { name: "Finding Nemo", wikiPageTitle: "Finding_Nemo" },
  "1-16": {
    name: "Beauty and the Beast",
    wikiPageTitle: "Beauty_and_the_Beast_(1991_film)",
  },
  "1-17": { name: "Hercules", wikiPageTitle: "Hercules_(1997_film)" },
  "1-18": { name: "Spider Man", wikiPageTitle: "Spider-Man_(2002_film)" },
  "1-19": { name: "Titanic", wikiPageTitle: "Titanic_(1997_film)" },
  "1-20": { name: "First Blood", wikiPageTitle: "First_Blood" },
  "1-21": { name: "Home Alone", wikiPageTitle: "Home_Alone" },
  "1-22": { name: "Thor", wikiPageTitle: "Thor_(film)" },
  "1-23": { name: "Shrek", wikiPageTitle: "Shrek" },
  "1-24": { name: "School of Rock", wikiPageTitle: "School_of_Rock" },
  "1-25": { name: "Toy Story 4", wikiPageTitle: "Toy_Story_4" },
  "1-26": { name: "Back to the Future", wikiPageTitle: "Back_to_the_Future" },
  "1-27": {
    name: "The Fox and the Hound",
    wikiPageTitle: "The_Fox_and_the_Hound",
  },
  "1-28": { name: "A Bug's Life", wikiPageTitle: "A_Bug%27s_Life" },
  "1-29": { name: "Tangled", wikiPageTitle: "Tangled" },
  "1-30": { name: "Thor Ragnarok", wikiPageTitle: "Thor:_Ragnarok" },
  "1-31": {
    name: "Harry Potter and the Prisoner of Azkaban",
    wikiPageTitle: "Harry_Potter_and_the_Prisoner_of_Azkaban_(film)",
  },
  "2-0": { name: "Elf", wikiPageTitle: "Elf_(film)" },
  "2-1": {
    name: "Star Wars Return of the Jedi",
    wikiPageTitle: "Return_of_the_Jedi",
  },
  "2-2": { name: "Ratatouille", wikiPageTitle: "Ratatouille_(film)" },
  "2-3": { name: "Up", wikiPageTitle: "Up_(2009_film)" },
  "2-4": {
    name: "Star Wars The Phantom Menace",
    wikiPageTitle: "Star_Wars:_Episode_I_–_The_Phantom_Menace",
  },
  "2-5": { name: "Good Will Hunting", wikiPageTitle: "Good_Will_Hunting" },
  "2-6": {
    name: "Captain America The First Avenger",
    wikiPageTitle: "Captain_America:_The_First_Avenger",
  },
  "2-7": { name: "Moana", wikiPageTitle: "Moana_(2016_film)" },
  "2-8": { name: "The Lion King", wikiPageTitle: "The_Lion_King" },
  "2-9": { name: "Pinocchio", wikiPageTitle: "Pinocchio_(1940_film)" },
  "2-10": { name: "The Godfather", wikiPageTitle: "The_Godfather" },
  "2-11": {
    name: "Harry Potter and the Chamber of Secrets",
    wikiPageTitle: "Harry_Potter_and_the_Chamber_of_Secrets_(film)",
  },
  "2-12": { name: "Frozen 2", wikiPageTitle: "Frozen_II" },
  "2-13": {
    name: "How the Grinch Stole Christmas",
    wikiPageTitle: "How_the_Grinch_Stole_Christmas!_(TV_special)",
  },
  "2-14": { name: "Incredibles", wikiPageTitle: "The_Incredibles" },
  "2-15": {
    name: "Avengers Infinity War",
    wikiPageTitle: "Avengers:_Infinity_War",
  },
  "2-16": { name: "The Dark Knight", wikiPageTitle: "The_Dark_Knight" },
  "2-17": { name: "Madagascar", wikiPageTitle: "Madagascar_(2005_film)" },
  "2-18": { name: "Aladdin", wikiPageTitle: "Aladdin_(2019_film)" },
  "2-19": {
    name: "One Hundred and One Dalmatians",
    wikiPageTitle: "One_Hundred_and_One_Dalmatians",
  },
  "2-20": { name: "Frozen", wikiPageTitle: "Frozen_(2013_film)" },
  "2-21": {
    name: "Harry Potter and the Sorcerers Stone",
    wikiPageTitle: "Harry_Potter_and_the_Philosopher%27s_Stone_(film)",
  },
  "2-22": { name: "Inception", wikiPageTitle: "Inception" },
  "2-23": { name: "Iron Man 2", wikiPageTitle: "Iron_Man_2" },
  "2-24": { name: "Die Hard", wikiPageTitle: "Die_Hard" },
  "2-25": {
    name: "Ace Ventura Pet Detective",
    wikiPageTitle: "Ace_Ventura:_Pet_Detective",
  },
  "2-26": { name: "Batman Begins", wikiPageTitle: "Batman_Begins" },
  "2-27": {
    name: "The Hunger Games Mockingjay Part 2",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_2",
  },
  "2-28": {
    name: "Ralph Breaks the Internet",
    wikiPageTitle: "Ralph_Breaks_the_Internet",
  },
  "2-29": {
    name: "Avengers Age of Ultron",
    wikiPageTitle: "Avengers%3A_Age_of_Ultron",
  },
  "2-30": {
    name: "The Lord of the Rings The Fellowship of the Ring",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Fellowship_of_the_Ring",
  },
  "2-31": { name: "Monsters Inc", wikiPageTitle: "Monsters,_Inc." },
  "3-0": { name: "Iron Man", wikiPageTitle: "Iron_Man_(2008_film)" },
  "3-1": {
    name: "The Jungle Book",
    wikiPageTitle: "The_Jungle_Book_(1967_film)",
  },
  "3-2": {
    name: "The Dark Knight Rises",
    wikiPageTitle: "The_Dark_Knight_Rises",
  },
  "3-3": { name: "Bambi", wikiPageTitle: "Bambi" },
  "3-4": {
    name: "The Hunchback of Notre Dame",
    wikiPageTitle: "The_Hunchback_of_Notre_Dame_(1996_film)",
  },
  "3-5": { name: "Meet the Robinsons", wikiPageTitle: "Meet_the_Robinsons" },
  "3-6": { name: "Ice Age", wikiPageTitle: "Ice_Age_(2002_film)" },
  "3-7": {
    name: "Spider Man Far From Home",
    wikiPageTitle: "Spider-Man:_Far_From_Home",
  },
  "3-8": {
    name: "Raya and the Last Dragon",
    wikiPageTitle: "Raya_and_the_Last_Dragon",
  },
  "3-9": { name: "Captain Marvel", wikiPageTitle: "Captain_Marvel_(film)" },
  "3-10": {
    name: "The Hunger Games Mockingjay Part 1",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_1",
  },
  "3-11": {
    name: "Captain America The Winter Soldier",
    wikiPageTitle: "Captain_America:_The_Winter_Soldier",
  },
  "3-12": { name: "Cars", wikiPageTitle: "Cars_(film)" },
  "3-13": { name: "Monsters University", wikiPageTitle: "Monsters_University" },
  "3-14": {
    name: "Monty Python and the Holy Grail",
    wikiPageTitle: "Monty_Python_and_the_Holy_Grail",
  },
  "3-15": {
    name: "Raiders of the Lost Ark",
    wikiPageTitle: "Raiders_of_the_Lost_Ark",
  },
  "3-16": {
    name: "Captain America Civil War",
    wikiPageTitle: "Captain_America:_Civil_War",
  },
  "3-17": { name: "Cast Away", wikiPageTitle: "Cast_Away" },
  "3-18": { name: "Iron Man 3", wikiPageTitle: "Iron_Man_3" },
  "3-19": { name: "The Hangover", wikiPageTitle: "The_Hangover" },
  "3-20": {
    name: "Star Wars The Empire Strikes Back",
    wikiPageTitle: "The_Empire_Strikes_Back",
  },
  "3-21": {
    name: "The Little Mermaid",
    wikiPageTitle: "The_Little_Mermaid_(1989_film)",
  },
  "3-22": { name: "Lady and the Tramp", wikiPageTitle: "Lady_and_the_Tramp" },
  "3-23": { name: "Rocky", wikiPageTitle: "Rocky" },
  "3-24": { name: "Wreck It Ralph", wikiPageTitle: "Wreck-It_Ralph" },
  "3-25": { name: "Avatar", wikiPageTitle: "Avatar_(2009_film)" },
  "3-26": { name: "Toy Story", wikiPageTitle: "Toy_Story" },
  "3-27": {
    name: "Star Wars Revenge of the Sith",
    wikiPageTitle: "Star_Wars:_Episode_III_–_Revenge_of_the_Sith",
  },
  "3-28": {
    name: "Harry Potter and the Deathly Hallows Part 2",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_2",
  },
  "3-29": { name: "WALL E", wikiPageTitle: "WALL-E" },
  "3-30": {
    name: "Star Wars Attack of the Clones",
    wikiPageTitle: "Star_Wars:_Episode_II_–_Attack_of_the_Clones",
  },
  "3-31": { name: "Forrest Gump", wikiPageTitle: "Forrest_Gump" },
  "4-0": { name: "Jurassic Park", wikiPageTitle: "Jurassic_Park_(film)" },
  "4-1": { name: "Lightyear", wikiPageTitle: "Lightyear_(film)" },
  "4-2": { name: "Kung Fu Panda", wikiPageTitle: "Kung_Fu_Panda" },
  "4-3": { name: "Dumb and Dumber", wikiPageTitle: "Dumb_and_Dumber" },
  "4-4": { name: "Big hero 6", wikiPageTitle: "Big_Hero_6_(film)" },
  "4-5": { name: "Grease", wikiPageTitle: "Grease_(film)" },
  "4-6": {
    name: "Spider Man No Way Home",
    wikiPageTitle: "Spider-Man:_No_Way_Home",
  },
  "4-7": {
    name: "Harry Potter and the Goblet of Fire",
    wikiPageTitle: "Harry_Potter_and_the_Goblet_of_Fire_(film)",
  },
  "4-8": { name: "21 Jump Street", wikiPageTitle: "21_Jump_Street_(film)" },
  "4-9": { name: "Toy Story 2", wikiPageTitle: "Toy_Story_2" },
  "4-10": {
    name: "The Jungle Book",
    wikiPageTitle: "The_Jungle_Book_(1967_film)",
  },
  "4-11": {
    name: "Captain America Civil War",
    wikiPageTitle: "Captain_America:_Civil_War",
  },
  "4-12": { name: "Oceans Eleven", wikiPageTitle: "Ocean%27s_Eleven" },
  "4-13": { name: "Monsters Inc", wikiPageTitle: "Monsters,_Inc." },
  "4-14": {
    name: "Home Alone 2 Lost in New York",
    wikiPageTitle: "Home_Alone_2:_Lost_in_New_York",
  },
  "4-15": { name: "Finding Nemo", wikiPageTitle: "Finding_Nemo" },
  "4-16": { name: "Rogue One", wikiPageTitle: "Rogue_One" },
  "4-17": { name: "Hercules", wikiPageTitle: "Hercules_(1997_film)" },
  "4-18": {
    name: "Monty Python and the Holy Grail",
    wikiPageTitle: "Monty_Python_and_the_Holy_Grail",
  },
  "4-19": { name: "School of Rock", wikiPageTitle: "School_of_Rock" },
  "4-20": {
    name: "Captain America The Winter Soldier",
    wikiPageTitle: "Captain_America:_The_Winter_Soldier",
  },
  "4-21": {
    name: "Ralph Breaks the Internet",
    wikiPageTitle: "Ralph_Breaks_the_Internet",
  },
  "4-22": { name: "Shrek", wikiPageTitle: "Shrek" },
  "4-23": { name: "Shrek 2", wikiPageTitle: "Shrek_2" },
  "4-24": { name: "The Dark Knight", wikiPageTitle: "The_Dark_Knight" },
  "4-25": {
    name: "Harry Potter and the Order of the Phoenix",
    wikiPageTitle: "Harry_Potter_and_the_Order_of_the_Phoenix_(film)",
  },
  "4-26": { name: "Star Wars A New Hope", wikiPageTitle: "Star_Wars_(film)" },
  "4-27": {
    name: "Avengers Age of Ultron",
    wikiPageTitle: "Avengers%3A_Age_of_Ultron",
  },
  "4-28": { name: "Jurassic Park", wikiPageTitle: "Jurassic_Park_(film)" },
  "4-29": {
    name: "Harry Potter and the Half Blood Prince",
    wikiPageTitle: "Harry_Potter_and_the_Half-Blood_Prince_(film)",
  },
  "4-30": { name: "Lightyear", wikiPageTitle: "Lightyear_(film)" },
  "4-31": { name: "First Blood", wikiPageTitle: "First_Blood" },
  "5-0": { name: "Wreck It Ralph", wikiPageTitle: "Wreck-It_Ralph" },
  "5-1": { name: "Frozen", wikiPageTitle: "Frozen_(2013_film)" },
  "5-2": { name: "Black Panther", wikiPageTitle: "Black_Panther_(film)" },
  "5-3": {
    name: "Spider Man Far From Home",
    wikiPageTitle: "Spider-Man:_Far_From_Home",
  },
  "5-4": { name: "Coco", wikiPageTitle: "Coco_(2017_film)" },
  "5-5": { name: "Spider Man", wikiPageTitle: "Spider-Man_(2002_film)" },
  "5-6": { name: "Thor Ragnarok", wikiPageTitle: "Thor:_Ragnarok" },
  "5-7": { name: "Pinocchio", wikiPageTitle: "Pinocchio_(1940_film)" },
  "5-8": {
    name: "Its a Wonderful Life",
    wikiPageTitle: "It%27s_a_Wonderful_Life",
  },
  "5-9": { name: "Kung Fu Panda", wikiPageTitle: "Kung_Fu_Panda" },
  "5-10": { name: "Inception", wikiPageTitle: "Inception" },
  "5-11": { name: "Avengers Endgame", wikiPageTitle: "Avengers:_Endgame" },
  "5-12": {
    name: "Spider Man No Way Home",
    wikiPageTitle: "Spider-Man:_No_Way_Home",
  },
  "5-13": {
    name: "Harry Potter and the Deathly Hallows Part 2",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_2",
  },
  "5-14": { name: "Die Hard", wikiPageTitle: "Die_Hard" },
  "5-15": {
    name: "Star Wars Attack of the Clones",
    wikiPageTitle: "Star_Wars:_Episode_II_–_Attack_of_the_Clones",
  },
  "5-16": { name: "Ice Age", wikiPageTitle: "Ice_Age_(2002_film)" },
  "5-17": { name: "Cinderella", wikiPageTitle: "Cinderella_(1950_film)" },
  "5-18": { name: "Cars", wikiPageTitle: "Cars_(film)" },
  "5-19": { name: "Toy Story", wikiPageTitle: "Toy_Story" },
  "5-20": {
    name: "The Hunger Games Mockingjay Part 2",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_2",
  },
  "5-21": {
    name: "Harry Potter and the Goblet of Fire",
    wikiPageTitle: "Harry_Potter_and_the_Goblet_of_Fire_(film)",
  },
  "5-22": {
    name: "The Wizard of Oz",
    wikiPageTitle: "The_Wizard_of_Oz_(1939_film)",
  },
  "5-23": { name: "WALL E", wikiPageTitle: "WALL-E" },
  "5-24": { name: "The Godfather", wikiPageTitle: "The_Godfather" },
  "5-25": { name: "Bambi", wikiPageTitle: "Bambi" },
  "5-26": { name: "Aladdin", wikiPageTitle: "Aladdin_(2019_film)" },
  "5-27": { name: "Incredibles 2", wikiPageTitle: "Incredibles_2" },
  "5-28": {
    name: "Star Wars Return of the Jedi",
    wikiPageTitle: "Return_of_the_Jedi",
  },
  "5-29": {
    name: "The Bourne Identity",
    wikiPageTitle: "The_Bourne_Identity_(2002_film)",
  },
  "5-30": { name: "Rain Man", wikiPageTitle: "Rain_Man" },
  "5-31": {
    name: "The Hunger Games Catching Fire",
    wikiPageTitle: "The_Hunger_Games:_Catching_Fire",
  },
  "6-0": { name: "Inside Out", wikiPageTitle: "Inside_Out_(2015_film)" },
  "6-1": { name: "21 Jump Street", wikiPageTitle: "21_Jump_Street_(film)" },
  "6-2": { name: "Iron Man 3", wikiPageTitle: "Iron_Man_3" },
  "6-3": { name: "The Lion King", wikiPageTitle: "The_Lion_King" },
  "6-4": { name: "Mulan", wikiPageTitle: "Mulan_(1998_film)" },
  "6-5": { name: "Good Will Hunting", wikiPageTitle: "Good_Will_Hunting" },
  "6-6": {
    name: "Harry Potter and the Chamber of Secrets",
    wikiPageTitle: "Harry_Potter_and_the_Chamber_of_Secrets_(film)",
  },
  "6-7": { name: "Forrest Gump", wikiPageTitle: "Forrest_Gump" },
  "6-8": {
    name: "The Lord of the Rings The Return of the King",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Return_of_the_King",
  },
  "6-9": { name: "Toy Story 4", wikiPageTitle: "Toy_Story_4" },
  "6-10": { name: "Madagascar", wikiPageTitle: "Madagascar_(2005_film)" },
  "6-11": { name: "Toy Story 3", wikiPageTitle: "Toy_Story_3" },
  "6-12": { name: "The Prestige", wikiPageTitle: "The_Prestige_(film)" },
  "6-13": {
    name: "How the Grinch Stole Christmas",
    wikiPageTitle: "How_the_Grinch_Stole_Christmas!_(TV_special)",
  },
  "6-14": {
    name: "The Lord of the Rings The Fellowship of the Ring",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Fellowship_of_the_Ring",
  },
  "6-15": {
    name: "Harry Potter and the Prisoner of Azkaban",
    wikiPageTitle: "Harry_Potter_and_the_Prisoner_of_Azkaban_(film)",
  },
  "6-16": {
    name: "One Hundred and One Dalmatians",
    wikiPageTitle: "One_Hundred_and_One_Dalmatians",
  },
  "6-17": {
    name: "Beauty and the Beast",
    wikiPageTitle: "Beauty_and_the_Beast_(1991_film)",
  },
  "6-18": {
    name: "Harry Potter and the Sorcerers Stone",
    wikiPageTitle: "Harry_Potter_and_the_Philosopher%27s_Stone_(film)",
  },
  "6-19": {
    name: "Raya and the Last Dragon",
    wikiPageTitle: "Raya_and_the_Last_Dragon",
  },
  "6-20": {
    name: "Avengers Infinity War",
    wikiPageTitle: "Avengers:_Infinity_War",
  },
  "6-21": { name: "Zootopia", wikiPageTitle: "Zootopia" },
  "6-22": { name: "Iron Man 2", wikiPageTitle: "Iron_Man_2" },
  "6-23": { name: "Frozen 2", wikiPageTitle: "Frozen_II" },
  "6-24": {
    name: "Star Wars Revenge of the Sith",
    wikiPageTitle: "Star_Wars:_Episode_III_–_Revenge_of_the_Sith",
  },
  "6-25": { name: "Transformers", wikiPageTitle: "Transformers_(film)" },
  "6-26": { name: "Spider Man 2", wikiPageTitle: "Spider-Man_2" },
  "6-27": { name: "Home Alone", wikiPageTitle: "Home_Alone" },
  "6-28": { name: "Cast Away", wikiPageTitle: "Cast_Away" },
  "6-29": { name: "Spider-Man_3", wikiPageTitle: "Spider-Man_3" },
  "6-30": {
    name: "The Fox and the Hound",
    wikiPageTitle: "The_Fox_and_the_Hound",
  },
  "6-31": { name: "Thor", wikiPageTitle: "Thor_(film)" },
  "7-0": {
    name: "Chicken Little",
    wikiPageTitle: "Chicken_Little_(2005_film)",
  },
  "7-1": { name: "The Matrix", wikiPageTitle: "The_Matrix" },
  "7-2": { name: "Pocahontas", wikiPageTitle: "Pocahontas_(1995_film)" },
  "7-3": { name: "Avatar", wikiPageTitle: "Avatar_(2009_film)" },
  "7-4": {
    name: "The Hunchback of Notre Dame",
    wikiPageTitle: "The_Hunchback_of_Notre_Dame_(1996_film)",
  },
  "7-5": { name: "The Hunger Games", wikiPageTitle: "The_Hunger_Games_(film)" },
  "7-6": {
    name: "Ace Ventura Pet Detective",
    wikiPageTitle: "Ace_Ventura:_Pet_Detective",
  },
  "7-7": { name: "Rocky", wikiPageTitle: "Rocky" },
  "7-8": { name: "Up", wikiPageTitle: "Up_(2009_film)" },
  "7-9": {
    name: "Captain America The First Avenger",
    wikiPageTitle: "Captain_America:_The_First_Avenger",
  },
  "7-10": {
    name: "Guardians of the Galaxy Volume 2",
    wikiPageTitle: "Guardians_of_the_Galaxy_Vol._2",
  },
  "7-11": { name: "Zoolander", wikiPageTitle: "Zoolander" },
  "7-12": { name: "Lady and the Tramp", wikiPageTitle: "Lady_and_the_Tramp" },
  "7-13": {
    name: "Star Wars The Empire Strikes Back",
    wikiPageTitle: "The_Empire_Strikes_Back",
  },
  "7-14": { name: "Peter Pan", wikiPageTitle: "Peter_Pan_(1953_film)" },
  "7-15": { name: "Grease", wikiPageTitle: "Grease_(film)" },
  "7-16": { name: "Lilo and Stitch", wikiPageTitle: "Lilo_%26_Stitch" },
  "7-17": { name: "Monsters University", wikiPageTitle: "Monsters_University" },
  "7-18": { name: "Ratatouille", wikiPageTitle: "Ratatouille_(film)" },
  "7-19": { name: "Big hero 6", wikiPageTitle: "Big_Hero_6_(film)" },
  "7-20": { name: "Batman Begins", wikiPageTitle: "Batman_Begins" },
  "7-21": { name: "Meet the Robinsons", wikiPageTitle: "Meet_the_Robinsons" },
  "7-22": {
    name: "Harry Potter and the Deathly Hallows Part 1",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_1",
  },
  "7-23": {
    name: "Pirates of the Caribbean The Curse of the Black Pearl",
    wikiPageTitle: "Pirates_of_the_Caribbean:_The_Curse_of_the_Black_Pearl",
  },
  "7-24": { name: "Incredibles", wikiPageTitle: "The_Incredibles" },
  "7-25": { name: "Back to the Future", wikiPageTitle: "Back_to_the_Future" },
  "7-26": { name: "A Bug's Life", wikiPageTitle: "A_Bug%27s_Life" },
  "7-27": { name: "Ant Man", wikiPageTitle: "Ant-Man_(film)" },
  "7-28": { name: "Dumb and Dumber", wikiPageTitle: "Dumb_and_Dumber" },
  "7-29": {
    name: "Guardians of the Galaxy",
    wikiPageTitle: "Guardians_of_the_Galaxy_(film)",
  },
  "7-30": {
    name: "How to Train Your Dragon",
    wikiPageTitle: "How_to_Train_Your_Dragon_(film)",
  },
  "7-31": {
    name: "The Dark Knight Rises",
    wikiPageTitle: "The_Dark_Knight_Rises",
  },
  "8-0": { name: "Titanic", wikiPageTitle: "Titanic_(1997_film)" },
  "8-1": { name: "The Hangover", wikiPageTitle: "The_Hangover" },
  "8-2": { name: "Captain Marvel", wikiPageTitle: "Captain_Marvel_(film)" },
  "8-3": {
    name: "The Hunger Games Mockingjay Part 1",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_1",
  },
  "8-4": {
    name: "The Lord of the Rings The Two Towers",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Two_Towers",
  },
  "8-5": {
    name: "Ferris Buellers Day Off",
    wikiPageTitle: "Ferris_Bueller%27s_Day_Off",
  },
  "8-6": { name: "Elf", wikiPageTitle: "Elf_(film)" },
  "8-7": {
    name: "Star Wars The Phantom Menace",
    wikiPageTitle: "Star_Wars:_Episode_I_–_The_Phantom_Menace",
  },
  "8-8": {
    name: "Raiders of the Lost Ark",
    wikiPageTitle: "Raiders_of_the_Lost_Ark",
  },
  "8-9": { name: "Iron Man", wikiPageTitle: "Iron_Man_(2008_film)" },
  "8-10": { name: "Moana", wikiPageTitle: "Moana_(2016_film)" },
  "8-11": {
    name: "Spider Man Homecoming",
    wikiPageTitle: "Spider-Man:_Homecoming",
  },
  "8-12": { name: "Tangled", wikiPageTitle: "Tangled" },
  "8-13": {
    name: "The Little Mermaid",
    wikiPageTitle: "The_Little_Mermaid_(1989_film)",
  },
  "8-14": { name: "Tarzan", wikiPageTitle: "Tarzan_(1999_film)" },
  "8-15": { name: "The Avengers", wikiPageTitle: "The_Avengers_(2012_film)" },
  "8-16": { name: "Top Gun", wikiPageTitle: "Top_Gun" },
  "8-17": { name: "Turning Red", wikiPageTitle: "Turning_Red" },
  "8-18": {
    name: "The Lord of the Rings The Fellowship of the Ring",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Fellowship_of_the_Ring",
  },
  "8-19": {
    name: "The Hunchback of Notre Dame",
    wikiPageTitle: "The_Hunchback_of_Notre_Dame_(1996_film)",
  },
  "8-20": { name: "Forrest Gump", wikiPageTitle: "Forrest_Gump" },
  "8-21": { name: "Titanic", wikiPageTitle: "Titanic_(1997_film)" },
  "8-22": { name: "Spider-Man_3", wikiPageTitle: "Spider-Man_3" },
  "8-23": { name: "Elf", wikiPageTitle: "Elf_(film)" },
  "8-24": {
    name: "Guardians of the Galaxy Volume 2",
    wikiPageTitle: "Guardians_of_the_Galaxy_Vol._2",
  },
  "8-25": {
    name: "Star Wars The Phantom Menace",
    wikiPageTitle: "Star_Wars:_Episode_I_–_The_Phantom_Menace",
  },
  "8-26": { name: "Lightyear", wikiPageTitle: "Lightyear_(film)" },
  "8-27": { name: "Ice Age", wikiPageTitle: "Ice_Age_(2002_film)" },
  "8-28": { name: "The Prestige", wikiPageTitle: "The_Prestige_(film)" },
  "8-29": {
    name: "Harry Potter and the Sorcerers Stone",
    wikiPageTitle: "Harry_Potter_and_the_Philosopher%27s_Stone_(film)",
  },
  "8-30": {
    name: "Avengers Infinity War",
    wikiPageTitle: "Avengers:_Infinity_War",
  },
  "8-31": {
    name: "Harry Potter and the Prisoner of Azkaban",
    wikiPageTitle: "Harry_Potter_and_the_Prisoner_of_Azkaban_(film)",
  },
  "9-0": {
    name: "The Dark Knight Rises",
    wikiPageTitle: "The_Dark_Knight_Rises",
  },
  "9-1": { name: "Ratatouille", wikiPageTitle: "Ratatouille_(film)" },
  "9-2": { name: "Cinderella", wikiPageTitle: "Cinderella_(1950_film)" },
  "9-3": { name: "Spider Man", wikiPageTitle: "Spider-Man_(2002_film)" },
  "9-4": {
    name: "Harry Potter and the Deathly Hallows Part 2",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_2",
  },
  "9-5": { name: "Peter Pan", wikiPageTitle: "Peter_Pan_(1953_film)" },
  "9-6": { name: "Thor", wikiPageTitle: "Thor_(film)" },
  "9-7": {
    name: "Guardians of the Galaxy",
    wikiPageTitle: "Guardians_of_the_Galaxy_(film)",
  },
  "9-8": { name: "Turning Red", wikiPageTitle: "Turning_Red" },
  "9-9": { name: "Toy Story 2", wikiPageTitle: "Toy_Story_2" },
  "9-10": { name: "Lady and the Tramp", wikiPageTitle: "Lady_and_the_Tramp" },
  "9-11": {
    name: "Harry Potter and the Deathly Hallows Part 1",
    wikiPageTitle: "Harry_Potter_and_the_Deathly_Hallows_–_Part_1",
  },
  "9-12": {
    name: "Captain America The Winter Soldier",
    wikiPageTitle: "Captain_America:_The_Winter_Soldier",
  },
  "9-13": { name: "Mulan", wikiPageTitle: "Mulan_(1998_film)" },
  "9-14": {
    name: "How the Grinch Stole Christmas",
    wikiPageTitle: "How_the_Grinch_Stole_Christmas!_(TV_special)",
  },
  "9-15": { name: "Cars", wikiPageTitle: "Cars_(film)" },
  "9-16": {
    name: "Captain America Civil War",
    wikiPageTitle: "Captain_America:_Civil_War",
  },
  "9-17": {
    name: "Captain America The First Avenger",
    wikiPageTitle: "Captain_America:_The_First_Avenger",
  },
  "9-18": { name: "Iron Man 2", wikiPageTitle: "Iron_Man_2" },
  "9-19": {
    name: "Pirates of the Caribbean The Curse of the Black Pearl",
    wikiPageTitle: "Pirates_of_the_Caribbean:_The_Curse_of_the_Black_Pearl",
  },
  "9-20": { name: "Cast Away", wikiPageTitle: "Cast_Away" },
  "9-21": { name: "Thor Ragnarok", wikiPageTitle: "Thor:_Ragnarok" },
  "9-22": { name: "Shrek 2", wikiPageTitle: "Shrek_2" },
  "9-23": { name: "Oceans Eleven", wikiPageTitle: "Ocean%27s_Eleven" },
  "9-24": { name: "Meet the Robinsons", wikiPageTitle: "Meet_the_Robinsons" },
  "9-25": {
    name: "The Bourne Identity",
    wikiPageTitle: "The_Bourne_Identity_(2002_film)",
  },
  "9-26": {
    name: "Spider Man Homecoming",
    wikiPageTitle: "Spider-Man:_Homecoming",
  },
  "9-27": { name: "Inside Out", wikiPageTitle: "Inside_Out_(2015_film)" },
  "9-28": { name: "Pinocchio", wikiPageTitle: "Pinocchio_(1940_film)" },
  "9-29": { name: "Good Will Hunting", wikiPageTitle: "Good_Will_Hunting" },
  "9-30": {
    name: "The Hunger Games Mockingjay Part 2",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_2",
  },
  "9-31": { name: "Rocky", wikiPageTitle: "Rocky" },
  "10-0": { name: "School of Rock", wikiPageTitle: "School_of_Rock" },
  "10-1": { name: "Toy Story 4", wikiPageTitle: "Toy_Story_4" },
  "10-2": {
    name: "Avengers Age of Ultron",
    wikiPageTitle: "Avengers%3A_Age_of_Ultron",
  },
  "10-3": {
    name: "Chicken Little",
    wikiPageTitle: "Chicken_Little_(2005_film)",
  },
  "10-4": {
    name: "Beauty and the Beast",
    wikiPageTitle: "Beauty_and_the_Beast_(1991_film)",
  },
  "10-5": {
    name: "Harry Potter and the Goblet of Fire",
    wikiPageTitle: "Harry_Potter_and_the_Goblet_of_Fire_(film)",
  },
  "10-6": {
    name: "Home Alone 2 Lost in New York",
    wikiPageTitle: "Home_Alone_2:_Lost_in_New_York",
  },
  "10-7": { name: "Top Gun", wikiPageTitle: "Top_Gun" },
  "10-8": {
    name: "The Hunger Games Mockingjay Part 1",
    wikiPageTitle: "The_Hunger_Games:_Mockingjay_–_Part_1",
  },
  "10-9": { name: "Ant Man", wikiPageTitle: "Ant-Man_(film)" },
  "10-10": {
    name: "Ralph Breaks the Internet",
    wikiPageTitle: "Ralph_Breaks_the_Internet",
  },
  "10-11": { name: "First Blood", wikiPageTitle: "First_Blood" },
  "10-12": { name: "Captain Marvel", wikiPageTitle: "Captain_Marvel_(film)" },
  "10-13": {
    name: "The Little Mermaid",
    wikiPageTitle: "The_Little_Mermaid_(1989_film)",
  },
  "10-14": {
    name: "Star Wars Return of the Jedi",
    wikiPageTitle: "Return_of_the_Jedi",
  },
  "10-15": {
    name: "The Lord of the Rings The Two Towers",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Two_Towers",
  },
  "10-16": { name: "Finding Nemo", wikiPageTitle: "Finding_Nemo" },
  "10-17": { name: "The Godfather", wikiPageTitle: "The_Godfather" },
  "10-18": {
    name: "Raya and the Last Dragon",
    wikiPageTitle: "Raya_and_the_Last_Dragon",
  },
  "10-19": { name: "Coco", wikiPageTitle: "Coco_(2017_film)" },
  "10-20": { name: "Kung Fu Panda", wikiPageTitle: "Kung_Fu_Panda" },
  "10-21": { name: "Avatar", wikiPageTitle: "Avatar_(2009_film)" },
  "10-22": {
    name: "Spider Man Far From Home",
    wikiPageTitle: "Spider-Man:_Far_From_Home",
  },
  "10-23": { name: "Tangled", wikiPageTitle: "Tangled" },
  "10-24": { name: "Transformers", wikiPageTitle: "Transformers_(film)" },
  "10-25": { name: "Toy Story 3", wikiPageTitle: "Toy_Story_3" },
  "10-26": { name: "A Bug's Life", wikiPageTitle: "A_Bug%27s_Life" },
  "10-27": { name: "Tarzan", wikiPageTitle: "Tarzan_(1999_film)" },
  "10-28": { name: "Pocahontas", wikiPageTitle: "Pocahontas_(1995_film)" },
  "10-29": { name: "Monsters Inc", wikiPageTitle: "Monsters,_Inc." },
  "10-30": { name: "Wreck It Ralph", wikiPageTitle: "Wreck-It_Ralph" },
  "10-31": { name: "21 Jump Street", wikiPageTitle: "21_Jump_Street_(film)" },
  "11-0": { name: "The Hangover", wikiPageTitle: "The_Hangover" },
  "11-1": {
    name: "One Hundred and One Dalmatians",
    wikiPageTitle: "One_Hundred_and_One_Dalmatians",
  },
  "11-2": {
    name: "The Fox and the Hound",
    wikiPageTitle: "The_Fox_and_the_Hound",
  },
  "11-3": {
    name: "Its a Wonderful Life",
    wikiPageTitle: "It%27s_a_Wonderful_Life",
  },
  "11-4": { name: "Lilo and Stitch", wikiPageTitle: "Lilo_%26_Stitch" },
  "11-5": { name: "Grease", wikiPageTitle: "Grease_(film)" },
  "11-6": { name: "Monsters University", wikiPageTitle: "Monsters_University" },
  "11-7": {
    name: "Harry Potter and the Chamber of Secrets",
    wikiPageTitle: "Harry_Potter_and_the_Chamber_of_Secrets_(film)",
  },
  "11-8": { name: "The Lion King", wikiPageTitle: "The_Lion_King" },
  "11-9": { name: "WALL E", wikiPageTitle: "WALL-E" },
  "11-10": {
    name: "The Hunger Games",
    wikiPageTitle: "The_Hunger_Games_(film)",
  },
  "11-11": { name: "Batman Begins", wikiPageTitle: "Batman_Begins" },
  "11-12": { name: "Up", wikiPageTitle: "Up_(2009_film)" },
  "11-13": { name: "Frozen", wikiPageTitle: "Frozen_(2013_film)" },
  "11-14": {
    name: "The Lord of the Rings The Return of the King",
    wikiPageTitle: "The_Lord_of_the_Rings:_The_Return_of_the_King",
  },
  "11-15": {
    name: "Raiders of the Lost Ark",
    wikiPageTitle: "Raiders_of_the_Lost_Ark",
  },
  "11-16": {
    name: "Star Wars Revenge of the Sith",
    wikiPageTitle: "Star_Wars:_Episode_III_–_Revenge_of_the_Sith",
  },
  "11-17": { name: "Incredibles", wikiPageTitle: "The_Incredibles" },
  "11-18": {
    name: "The Jungle Book",
    wikiPageTitle: "The_Jungle_Book_(1967_film)",
  },
  "11-19": {
    name: "Harry Potter and the Half Blood Prince",
    wikiPageTitle: "Harry_Potter_and_the_Half-Blood_Prince_(film)",
  },
  "11-20": { name: "Zootopia", wikiPageTitle: "Zootopia" },
  "11-21": { name: "Zoolander", wikiPageTitle: "Zoolander" },
  "11-22": { name: "Black Panther", wikiPageTitle: "Black_Panther_(film)" },
  "11-23": { name: "Die Hard", wikiPageTitle: "Die_Hard" },
  "11-24": {
    name: "The Wizard of Oz",
    wikiPageTitle: "The_Wizard_of_Oz_(1939_film)",
  },
  "11-25": { name: "Madagascar", wikiPageTitle: "Madagascar_(2005_film)" },
  "11-26": { name: "The Avengers", wikiPageTitle: "The_Avengers_(2012_film)" },
  "11-27": { name: "Iron Man", wikiPageTitle: "Iron_Man_(2008_film)" },
  "11-28": {
    name: "Star Wars The Empire Strikes Back",
    wikiPageTitle: "The_Empire_Strikes_Back",
  },
  "11-29": { name: "Iron Man 3", wikiPageTitle: "Iron_Man_3" },
  "11-30": {
    name: "Harry Potter and the Order of the Phoenix",
    wikiPageTitle: "Harry_Potter_and_the_Order_of_the_Phoenix_(film)",
  },
  "11-31": {
    name: "Spider Man No Way Home",
    wikiPageTitle: "Spider-Man:_No_Way_Home",
  },
};
