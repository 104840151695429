import React from "react";

interface CenteredProps {
  content: React.ReactNode;
}

const Centered = ({ content }: CenteredProps) => {
  return (
    <div
      style={{
        marginTop: " 10vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
      }}
    >
      {content}
    </div>
  );
};

export default Centered;
