import React from "react";
import { Container, Heading } from "@chakra-ui/react";
import GamesCards from "./GamesCards";
import Navbar from "./common/Navbar";
import { configureAnalytics } from "./configure-analytics";

configureAnalytics(window.location.hostname);

function App() {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>
          <Heading
            size="2xl"
            style={{
              paddingTop: "20vh",
              marginBottom: "10vh",
            }}
          >
            One a Day Games
          </Heading>

          <GamesCards />
        </div>
      </div>
    </>
  );
}

export default App;
