import React from "react";
import Centered from "../../common/Centered";
import Gameplay from "./components/Gameplay";
import Navbar from "../../common/Navbar";

const Encrypedia = () => {
  return (
    <>
      <Navbar />
      <Centered content={<Gameplay />} />
    </>
  );
};

export default Encrypedia;
