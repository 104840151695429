import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React from "react";

import { useNavigate } from "react-router-dom";
import { STAGES, getStage } from "./utils/stage";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "fixed" /* Set the navbar to fixed position */,
        top: 0 /* Position the navbar at the top of the page */,
        width: "100%" /* Full width */,
        backgroundColor: "teal",
        zIndex: 9999,
      }}
    >
      <div
        onClick={() => navigate("/")}
        style={{
          float: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          cursor: "pointer",
          padding: "14px 16px",
        }}
      >
        <Heading as="h2" size="md">
          One a Day Games
        </Heading>
      </div>
      {getStage(window.location.hostname) === STAGES.LOCAL && (
        <div
          style={{
            float: "right",
            display: "block",
            padding: "7px",
          }}
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem onClick={() => navigate("/account")}>Account</MenuItem>
              <MenuItem>Stats</MenuItem>
              <MenuItem>Archive</MenuItem>
            </MenuList>
          </Menu>
        </div>
      )}
    </div>
  );
};

export default Navbar;
