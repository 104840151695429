import { Button, Container, Stack, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { SelectedSquare } from "../MathSquares";

const nums = [1, 2, 3, 4, 5, 6, 7, 8, 9];

interface GameInputsProps {
  selectedSquare: null | SelectedSquare;
  handleNumButtonPress: Function;
  handleDelete: Function;
  usedNums: number[];
}

const GameInputs = ({
  selectedSquare,
  handleNumButtonPress,
  handleDelete,
  usedNums,
}: GameInputsProps) => {
  return (
    <div>
      <Container>
        <SimpleGrid columns={10} spacing={3}>
          {nums.map((num) => (
            <Button
              colorScheme={usedNums.includes(num) ? "blackAlpha" : "teal"}
              isDisabled={selectedSquare === null || usedNums.includes(num)}
              onClick={() => handleNumButtonPress(num)}
              size="sm"
            >
              {num}
            </Button>
          ))}
          <Button
            colorScheme="red"
            size="sm"
            isDisabled={selectedSquare === null}
            onClick={() => handleDelete()}
          >
            D
          </Button>
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default GameInputs;
