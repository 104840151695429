import { Text } from "@chakra-ui/react";
import React from "react";

interface ExpandWordProps {
  firstLetter: string;
  currentInput: string;
  lastLetter: string;
}

const ExpandWord = ({
  firstLetter,
  currentInput,
  lastLetter,
}: ExpandWordProps) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Text fontSize='3xl' color="teal">
        {firstLetter}
      </Text>
      <Text fontSize='3xl'>{currentInput}</Text>
      <Text fontSize='3xl' color="teal">
        {lastLetter}
      </Text>
    </div>
  );
};

export default ExpandWord;
