import React from "react";

interface PlotleStepsContentProps {
  step: number;
}

const  PlotleStepsContent = ({ step }: PlotleStepsContentProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {step === 1 ? (
        <div>
          Welcome to Plotle! Try to guess the movie by unscrambling the wikipedia plot text in order to solve today's puzzle.
        </div>
      ) : (
        <></>
      )}
      {step === 2 ? (
        <div>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <img
              alt="squares"
              src="/assets/plotle.GIF"
              style={{
                height: "200px",
              }}
            />
          </div>
          <div>
            Type in a word inside the input box to gradually unscramble words within the box.
            As you unscramble words, more of the plot will become clear, making it more likely to solve
            the movie title.
          </div>
        </div>
      ) : (
        <></>
      )}
      {step === 3 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <div>Try to guess the title to win the game!</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlotleStepsContent;
