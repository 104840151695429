import { Card, CardFooter, Button } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const GamesCards = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
        gridRowGap: "16px",

        margin: "10px",
      }}
    >
      <Card variant="outline" overflow={"hidden"}>
        <a href="/plotle">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="plotle"
              src="/assets/Plotle.png"
              style={{
                position: "relative",
                width: "200px",
              }}
            />
          </div>
        </a>
        <CardFooter>
          <Button
            colorScheme="teal"
            onClick={() => navigate("/plotle")}
            flex="1"
            variant="outline"
          >
            Play
          </Button>
        </CardFooter>
      </Card>

      <Card variant="outline" overflow={"hidden"}>
        <a href="/squares">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="squares"
              src="/assets/Squares.png"
              style={{
                position: "relative",
                width: "200px",
              }}
            />
          </div>
        </a>
        <CardFooter justify="center">
          <Button
            colorScheme="teal"
            onClick={() => navigate("/squares")}
            flex="1"
            variant="outline"
          >
            Play
          </Button>
        </CardFooter>
      </Card>
      <Card variant="outline" overflow={"hidden"}>
        <a href="/expansion">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="expansion"
              src="/assets/Expansion.png"
              style={{
                position: "relative",
                width: "200px",
              }}
            />
          </div>
        </a>
        <CardFooter justify="center">
          <Button
            colorScheme="teal"
            onClick={() => navigate("/expansion")}
            flex="1"
            variant="outline"
          >
            Play
          </Button>
        </CardFooter>
      </Card>
      <Card variant="outline" overflow={"hidden"}>
        <a href="/encrypedia">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="encrypedia"
              src="/assets/Encrypedia.png"
              style={{
                position: "relative",
                width: "200px",
              }}
            />
          </div>
        </a>
        <CardFooter justify="center">
          <Button
            colorScheme="teal"
            onClick={() => navigate("/encrypedia")}
            flex="1"
            variant="outline"
          >
            Play
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default GamesCards;
