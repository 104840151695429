import React from "react";
import { SelectedSquare } from "../MathSquares";

interface SelectCellProps {
  selectedSquare: SelectedSquare | null;
  i: number;
  j: number;
  setSelectedSquare: Function;
  value : string;
}

const SelectCell = ({ selectedSquare, i, j, setSelectedSquare, value }: SelectCellProps) => {
  return (
    <div
      style={{
        border: "solid",
        backgroundColor:
          selectedSquare !== null &&
          selectedSquare.row === i &&
          selectedSquare.col === j
            ? "yellow"
            : "white",
      }}
      onClick={() =>
        setSelectedSquare({
          row: i,
          col: j,
        })
      }
    >
      {value}
    </div>
  );
};

export default SelectCell;
