import React from "react";

interface SquaresStepsContentProps {
  step: number;
}

const SquaresStepsContent = ({ step }: SquaresStepsContentProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {step === 1 ? (
        <div>
          Welcome to squares! Fill in each of the blank squares with numbers to
          complete the daily puzzle.
        </div>
      ) : (
        <></>
      )}
      {step === 2 ? (
        <div>
          <div>
            <img
              alt="squares"
              src="/assets/Squares.gif"
              style={{
                position: "relative",
                height: "200px",
              }}
            />
          </div>
          <div>
            Click on a square and fill in a number. If the corresponding end row
            or column cell is green, then that equation is correct. If the end
            cell is red, it means that the row or column evaluation is not
            correct.
          </div>
        </div>
      ) : (
        <></>
      )}
      {step === 3 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <img
              alt="squares"
              src="/assets/SquaresSolution.PNG"
              style={{
                position: "relative",
                height: "200px",
              }}
            />
            <div>Try to fill in the puzzle to win the game!</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SquaresStepsContent;
