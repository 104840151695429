import React, { useEffect, useState } from "react";
import {
  SquareInputs,
  SquaresGame,
  checkWinner,
  getDailySquares,
  handleSettingWinner,
} from "./utils/utils";
import GameDisplay from "./components/GameDisplay";
import GameInputs from "./components/GameInputs";
import { Container, Heading } from "@chakra-ui/react";
import WinnerModal from "./components/WinnerModal";
import { checkLocalStorageIfWinner } from "./utils/utils";
import Navbar from "../../common/Navbar";
import HowToPlayModal from "../../common/HowToPlayModal";
import SquaresStepsContent from "./components/SquaresStepsContent";
import HowToPlaySquaresModal from "./components/HowToPlaySquaresModal";

export interface SelectedSquare {
  row: number;
  col: number;
}

const MathSquares = () => {
  const [squaresGame, setSquaresGame] = useState<SquaresGame | null>(null);

  const [selectedSquare, setSelectedSquare] = useState<null | SelectedSquare>(
    null
  );
  const [usedNums, setUsedNums] = useState<number[]>([]);
  const [squareInputs, setSquareInputs] = useState<SquareInputs>({
    "0-0": "",
    "0-2": "",
    "0-4": "",
    "2-2": "",
    "4-0": "",
    "4-4": "",
    "4-2": "",
  });
  const [isWinner, setIsWinner] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  useEffect(() => {
    const localStorageWinnerResult = checkLocalStorageIfWinner();
    if (localStorageWinnerResult) {
      setIsWinner(true);
    }

    const board = getDailySquares();
    setSquaresGame(board);
    setUsedNums([...board.revealedNumbers]);
  }, []);
  useEffect(() => {
    if (!isWinner && squaresGame !== null) {
      const result = checkWinner(squaresGame.gameBoard, squareInputs);
      if (result) {
        handleSettingWinner();
      }

      setIsWinner(result);
    }
  }, [usedNums]);

  const handleDelete = () => {
    if (selectedSquare !== null) {
      const key = `${selectedSquare?.row}-${selectedSquare?.col}`;
      const numToDelete = squareInputs[key as keyof SquareInputs];
      setUsedNums(
        usedNums.filter((num) => num.toString() !== numToDelete.toString())
      );
      setSquareInputs({
        ...squareInputs,
        [`${selectedSquare?.row}-${selectedSquare?.col}`]: "",
      });
    }

    setSelectedSquare(null);
  };

  const handleNumButtonPress = (num: number) => {
    try {
      const value =
        squareInputs[
          `${selectedSquare?.row}-${selectedSquare?.col}` as keyof SquareInputs
        ];
      const parsedValue = parseInt(value);
      setUsedNums([...usedNums, num].filter((num) => num !== parsedValue));
    } catch (e) {
      setUsedNums([...usedNums, num]);
    }
    setSquareInputs({
      ...squareInputs,
      [`${selectedSquare?.row}-${selectedSquare?.col}`]: num.toString(),
    });
    setSelectedSquare(null);
  };
  if (squaresGame === null) {
    return <>{/**TODO - add loading spinner */}</>;
  }
  return (
    <>
      <Navbar />
      <HowToPlaySquaresModal />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Container>
          <Heading
            style={{
              marginBottom: "10px",
            }}
            textAlign={"center"}
          >
            Squares
          </Heading>
          <GameDisplay
            squareInputs={squareInputs}
            gameBoard={squaresGame === null ? [] : squaresGame.gameBoard}
            answers={squaresGame.answers}
            selectedSquare={selectedSquare}
            setSelectedSquare={!isWinner ? setSelectedSquare : () => {}}
          />
          {isWinner && (
            <WinnerModal
              isOpen={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />
          )}
          {!isWinner && (
            <GameInputs
              usedNums={usedNums}
              handleDelete={handleDelete}
              selectedSquare={selectedSquare}
              handleNumButtonPress={handleNumButtonPress}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default MathSquares;
