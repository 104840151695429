import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

interface LoserModalProps {
  isShowing: boolean;
  target: number;
  setModalShowing: Function;
}

const LoserModal = ({
  isShowing,
  target,
  setModalShowing,
}: LoserModalProps) => {
  return (
    <div>
      <Modal isOpen={isShowing} onClose={() => setModalShowing(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>You Lost!</ModalHeader>
          <ModalBody>
            <div>The number was {target}</div>
          </ModalBody>
          <ModalFooter>{/*TODO:Add ok button */}</ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LoserModal;
