import React, { useState } from "react";
import HowToPlayModal from "../../../common/HowToPlayModal";
import SquaresStepsContent from "./SquaresStepsContent";

const steps = [
  { title: "1", description: "Overview" },
  { title: "2", description: "How-To" },
  { title: "3", description: "Goal" },
];

const HowToPlaySquaresModal = () => {
  const [step, setStep] = useState(1);

  return (
    <HowToPlayModal
      steps={steps}
      content={<SquaresStepsContent step={step} />}
      step={step}
      setStep={setStep}
    />
  );
};

export default HowToPlaySquaresModal;
