import {
  Box,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
} from "@chakra-ui/react";
import React from "react";

interface HowToStepperProps {
  activeStep: number;
  steps: any[];
}

const HowToStepper = ({ activeStep, steps }: HowToStepperProps) => (
  <div
    style={{
      marginTop: "2vh",
    }}
  >
    <Stepper index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  </div>
);

export default HowToStepper;
