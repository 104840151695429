import React, { useEffect, useState } from "react";
import WikiTextDisplay from "./WikiTextDisplay";
import {
  alphabet,
  isOnlyLetters,
} from "../../../common/utils/letters";
import { pickRandomIndexFromList } from "../../../common/utils/random";
import Inputs from "./Inputs";
import GuessModal from "./GuessModal";
import {
  WikiData,
  getWikipediaPageIntro,
} from "../../../common/wikipedia/wikiapi";
import { Heading } from "@chakra-ui/react";
import { getKeyToValue, getLettersRemaining } from "../utils";
import { dailyEncrypedia } from "../wikipedia/dailyEncrypedia";
import { getDailyKey } from "../../../common/utils/localStorage";

const generateAlphabetMapping = () => {
  const updatedAlphabetMap = new Map<string, string>();
  let alphabetList = alphabet.split("");
  for (let i = 0; i < alphabet.length; i++) {
    const randIndex = pickRandomIndexFromList(alphabetList);
    updatedAlphabetMap.set(alphabet[i], alphabetList[randIndex]);
    alphabetList.splice(randIndex, 1);
  }
  return updatedAlphabetMap;
};

const generateReverseMapping = (map: Map<string, string>) => {
  const invertedMap = new Map(
    [...map.entries()].map(([key, value]) => [value, key])
  );
  return invertedMap;
};

const convertText = (text: string[], alphabetMapping: Map<string, string>) => {
  const convertedText = [];
  for (let i = 0; i < text.length; i++) {
    if (!isOnlyLetters(text[i])) {
      convertedText.push(text[i]);
      continue;
    }
    let convertedWord = "";
    for (let j = 0; j < text[i].length; j++) {
      convertedWord += alphabetMapping.get(text[i][j].toLowerCase());
    }
    convertedText.push(convertedWord);
  }
  return convertedText;
};

const Gameplay = () => {
  const [mistakes, setMistakes] = useState(0);
  const [alphabetMapping, setAlphabetMapping] = useState<Map<string, string>>(
    new Map()
  );
  const [reverseMapping, setReverseMapping] = useState<Map<string, string>>(
    new Map()
  );
  const [wikiData, setWikiData] = useState<WikiData>({
    name: "",
    plotText: [],
  });
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    letter: "",
  });
  const [correctlyGuessedLetters, setCorrectlyGuessedLetters] = useState<
    string[]
  >([]);
  const [isWinner, setIsWinner] = useState(false);

  useEffect(() => {
    const setupWikiPageText = async () => {
      const dailyKey = getDailyKey();
      const encrypedia = dailyEncrypedia[dailyKey as any];
      const wikiData = await getWikipediaPageIntro(encrypedia.name);
      const letterToCryptic = generateAlphabetMapping();
      const crypticToLetter = generateReverseMapping(letterToCryptic);
      const nonExistentLetters = getNonExistentLetters(wikiData.plotText);
      setAlphabetMapping(letterToCryptic);
      setReverseMapping(crypticToLetter);
      const nonExistentLettersMapping = [];
      for (const nonExistentLetter of nonExistentLetters) {
        nonExistentLettersMapping.push(letterToCryptic.get(nonExistentLetter)!);
      }
      setWikiData({
        name: wikiData.name,
        plotText: convertText(wikiData.plotText, letterToCryptic),
      });
      setCorrectlyGuessedLetters(nonExistentLettersMapping);
    };
    setupWikiPageText();
  }, []);

  const getNonExistentLetters = (text: string[]) => {
    const lettersMap = new Map<string, string>();
    for (let i = 0; i < text.length; i++) {
      for (let j = 0; j < text[i].length; j++) {
        lettersMap.set(text[i][j].toLowerCase(), "placeholder");
      }
    }
    const nonExistentLetters = [];
    for (let i = 0; i < alphabet.length; i++) {
      if (!lettersMap.has(alphabet[i])) {
        nonExistentLetters.push(alphabet[i]);
      }
    }
    return nonExistentLetters;
  };

  const handleInput = (letter: string) => {
    setModalProps({ isOpen: true, letter });
  };

  const handleSubmit = (letterSelected: string, guessedLetter: string) => {
    const key = reverseMapping.get(letterSelected);
    if (key === guessedLetter.toLowerCase()) {
      const updatedCorrectlyGuessedLetters = [
        ...correctlyGuessedLetters,
        letterSelected.toLowerCase(),
      ];
      setCorrectlyGuessedLetters(updatedCorrectlyGuessedLetters);
      if (updatedCorrectlyGuessedLetters.length === 26) {
        setIsWinner(true);
      }

      setModalProps({ isOpen: false, letter: "" });
    } else {
      alert(`${letterSelected} does not map to ${guessedLetter}, try again.`);
      setMistakes(mistakes + 1);
    }
  };
  //TODO: display mistake counter
  return (
    <div>
      <Heading>Encrypedia</Heading>
      {isWinner && <div>You win!</div>}
      <WikiTextDisplay
        alphabetMapping={alphabetMapping}
        reverseMapping={reverseMapping}
        correctlyGuessedLetters={correctlyGuessedLetters}
        text={wikiData.plotText}
      />
      <GuessModal
        correctLettersGuessed={getLettersRemaining(correctlyGuessedLetters, reverseMapping)}
        isOpen={modalProps.isOpen}
        setModalOpen={(isOpen: boolean) => {
          setModalProps({ ...modalProps, isOpen });
        }}
        handleSubmit={handleSubmit}
        letter={modalProps.letter}
      />
      <Inputs
        handleInput={handleInput}
        correctLettersGuessed={correctlyGuessedLetters}
      />
    </div>
  );
};

export default Gameplay;
