import { Button } from "@chakra-ui/react";
import React from "react";

const numbersRow = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
const rowOne = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"];
const rowTwo = ["a", "s", "d", "f", "g", "h", "j", "k", "l"];
const rowThree = ["z", "x", "c", "v", "b", "n", "m"];

interface KeyboardProps {
  handleClick: Function;
  onEnterClick: Function;
  onBackClick: Function;
  includesNumbers?: boolean;
  highlightedLetters?: Map<string, string>;
}

const Keyboard = ({
  handleClick,
  includesNumbers = true,
  highlightedLetters = new Map(),
  onEnterClick,
  onBackClick
}: KeyboardProps) => {
  return (
    <div
      style={{
        margin: "10px",
      }}
    >
      <div>
        {includesNumbers && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {numbersRow.map((num) => (
              <Button
                size={"sm"}
                style={{ margin: "1px" }}
                onClick={() => handleClick(num)}
              >
                {num}
              </Button>
            ))}
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {rowOne.map((letter) => (
            <Button
              colorScheme={
                highlightedLetters.has(letter)
                  ? highlightedLetters.get(letter)
                  : "gray"
              }
              size={"sm"}
              style={{ margin: "1px" }}
              onClick={() => handleClick(letter)}
            >
              {letter}
            </Button>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {rowTwo.map((letter) => (
            <Button
              colorScheme={
                highlightedLetters.has(letter)
                  ? highlightedLetters.get(letter)
                  : "gray"
              }
              size={"sm"}
              style={{ margin: "1px" }}
              onClick={() => handleClick(letter)}
            >
              {letter}
            </Button>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            colorScheme={"gray"}
            size={"sm"}
            style={{ margin: "1px" }}
            onClick={() => onEnterClick()}
          >
            Enter
          </Button>
          {rowThree.map((letter) => (
            <Button
              colorScheme={
                highlightedLetters.has(letter)
                  ? highlightedLetters.get(letter)
                  : "gray"
              }
              size={"sm"}
              style={{ margin: "1px" }}
              onClick={() => handleClick(letter)}
            >
              {letter}
            </Button>
          ))}
          <Button
            colorScheme={"gray"}
            size={"sm"}
            style={{ margin: "1px" }}
            onClick={() => onBackClick()}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Keyboard;
