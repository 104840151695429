import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import HowToStepper from "./HowToStepper";

import React, { useState } from "react";

interface HowToPlayModalProps {
  steps: any[];
  content: React.ReactNode;
  step: number;
  setStep: Function;
}

const HowToPlayModal = ({
  step,
  setStep,
  steps,
  content,
}: HowToPlayModalProps) => {
  const [isOpen, setModalOpen] = useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How to play</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {content}
          <HowToStepper activeStep={step} steps={steps} />
        </ModalBody>
        <div
          style={{
            marginTop: "2vh",
          }}
        >
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Close
            </Button>
            {step < steps.length && <Button colorScheme="blue" onClick={() => setStep(step + 1)}>
              Next
            </Button>}
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default HowToPlayModal;
