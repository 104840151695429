import React from "react";
import RedactedText from "./RedactedText";

interface RedactedSectionProps {
  textArr: string[];
  guessedWordsMap: Map<string, string>;
}

const RedactedSection = ({
  textArr,
  guessedWordsMap,
}: RedactedSectionProps) => {
  return (
    <>
      {textArr.map((word: string) => {
        const lowerCaseWord = word.toLowerCase();
        return (
          <>
            {" "}
            <span>
              {guessedWordsMap.has(lowerCaseWord) ? (
                lowerCaseWord
              ) : (
                <RedactedText word={word} />
              )}
            </span>{" "}
          </>
        );
      })}
    </>
  );
};

export default RedactedSection;
