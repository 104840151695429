import { getLocalStorageData } from "../../hilo/utils";
import { dailySquares } from "./dailySquares";

export interface SquareInputs {
  "0-0": string;
  "0-2": string;
  "0-4": string;
  "2-2": string;
  "4-0": string;
  "4-2": string;
  "4-4": string;
}

const getRandNum = (nums: string[]) => {
  const randIndex = Math.floor(Math.random() * nums.length);
  const randNum = nums[randIndex];
  nums.splice(randIndex, 1);
  return randNum;
};

export const addLastTotalColumn = (gameBoard: string[][], row: number) => {
  let total = 0;
  const rowList = gameBoard[row];
  for (let i = 0; i < rowList.length; i++) {
    if (i % 2 === 0) {
      const num = parseInt(rowList[i]);
      if (i === 0) {
        total += num;
      } else {
        if (rowList[i - 1] === "-") {
          total -= num;
        } else {
          total += num;
        }
      }
    }
  }
  rowList[5] = total.toString();
};

export const addLastTotalRow = (gameBoard: string[][], col: number) => {
  let total = 0;

  for (let i = 0; i < 5; i++) {
    if (i % 2 === 0) {
      const num = parseInt(gameBoard[i][col]);
      if (i === 0) {
        total += num;
      } else {
        if (gameBoard[i - 1][col] === "-") {
          total -= num;
        } else {
          total += num;
        }
      }
    }
  }
  gameBoard[5][col] = total.toString();
};

export interface SquaresGame {
  gameBoard: string[][];
  answers: SquareInputs;
  revealedNumbers: number[];
}

export const isRevealedNumber = (row: number, col: number) => {
  const isLeftCross = row === 2 && col === 0;
  const isRightCross = row === 2 && col === 4;
  if (isLeftCross || isRightCross) {
    return true;
  }
  return false;
};

export const generateBoard = (): SquaresGame => {
  const nums = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const operations = ["+", "-"];
  const gameBoard: string[][] = [];
  const retrievedNums: number[] = [];
  const revealedNumbers: number[] = [];
  for (let i = 0; i < 5; i++) {
    gameBoard.push([]);
    for (let j = 0; j < 5; j++) {
      if (j % 2 === 0 && i % 2 === 0) {
        const randNum = getRandNum(nums);
        gameBoard[i][j] = randNum;
        retrievedNums.push(parseInt(randNum));
        if (isRevealedNumber(i, j)) {
          revealedNumbers.push(parseInt(randNum));
        }
      } else {
        const randomOperation = Math.floor(Math.random() * operations.length);
        gameBoard[i][j] = operations[randomOperation];
      }
    }
  }

  //adjust answers

  const answers = {
    "0-0": gameBoard[0][0],
    "0-2": gameBoard[0][2],
    "0-4": gameBoard[0][4],
    "2-2": gameBoard[2][2],
    "4-0": gameBoard[4][0],
    "4-2": gameBoard[4][2],
    "4-4": gameBoard[4][4],
  };

  // fill out with underscores
  gameBoard[1][1] = "_";
  gameBoard[1][3] = "_";
  gameBoard[3][1] = "_";
  gameBoard[3][3] = "_";
  //add total column
  addLastTotalColumn(gameBoard, 0);
  addLastTotalColumn(gameBoard, 2);
  addLastTotalColumn(gameBoard, 4);

  //add total row
  gameBoard.push([]);
  addLastTotalRow(gameBoard, 0);
  addLastTotalRow(gameBoard, 2);
  addLastTotalRow(gameBoard, 4);
  gameBoard[5][1] = "_";
  gameBoard[5][3] = "_";

  const result = {
    gameBoard,
    answers,
    revealedNumbers,
  };
  return result;
};

export interface SquaresData {
  isWinner: boolean;
}

export const checkLocalStorageIfWinner = (): boolean => {
  const data = localStorage.getItem("squares");
  const squaresKey = getDailySquaresKey();
  if (data) {
    try {
      const localStorageData = JSON.parse(data) as any;
      if (localStorageData[squaresKey] !== undefined) {
        const todayData = localStorageData[squaresKey] as SquaresData;
        return todayData.isWinner;
      } else {
        localStorage.setItem(
          "squares",
          JSON.stringify({
            ...localStorageData,
            [squaresKey]: { isWinner: false },
          })
        );
      }
    } catch (e) {
      localStorage.removeItem("squares");
    }
  } else {
    // first time playing
    localStorage.setItem(
      "squares",
      JSON.stringify({ [squaresKey]: { isWinner: false } })
    );
  }
  return false;
};

export const handleSettingWinner = () => {
  const data = localStorage.getItem("squares");
  const squaresKey = getDailySquaresKey();
  if (data) {
    const localStorageData = JSON.parse(data) as any;
    localStorage.setItem(
      "squares",
      JSON.stringify({
        ...localStorageData,
        [squaresKey]: { isWinner: true },
      })
    );
  } else {
    localStorage.setItem(
      "squares",
      JSON.stringify({
        [squaresKey]: { isWinner: true },
      })
    );
  }
};

export const getDailySquares = () => {
  const key = getDailySquaresKey();
  return dailySquares[key];
};

export const getDailySquaresKey = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  return `${month}-${day}`;
};

const getColumnTotal = (
  gameBoard: string[][],
  column: number,
  squareInputs: SquareInputs
) => {
  let currentTotal = parseInt(
    squareInputs[`0-${column}` as keyof SquareInputs]
  );

  let num2 = parseInt(gameBoard[2][column]);
  if (column === 2) {
    num2 = parseInt(squareInputs[`2-${column}` as keyof SquareInputs]);
  }

  if (gameBoard[1][column] === "-") {
    currentTotal -= num2;
  } else {
    currentTotal += num2;
  }

  if (gameBoard[3][column] === "-") {
    currentTotal -= parseInt(squareInputs[`4-${column}` as keyof SquareInputs]);
  } else {
    currentTotal += parseInt(squareInputs[`4-${column}` as keyof SquareInputs]);
  }
  return currentTotal;
};

const getRowTotal = (
  gameBoard: string[][],
  row: number,
  squareInputs: SquareInputs
) => {
  let currentTotal = parseInt(squareInputs[`${row}-0` as keyof SquareInputs]);
  if (row === 2) {
    currentTotal = parseInt(gameBoard[2][0]);
  }

  if (gameBoard[row][1] === "-") {
    currentTotal -= parseInt(squareInputs[`${row}-2` as keyof SquareInputs]);
  } else {
    currentTotal += parseInt(squareInputs[`${row}-2` as keyof SquareInputs]);
  }

  let thirdNum = parseInt(squareInputs[`${row}-4` as keyof SquareInputs]);
  if (row === 2) {
    thirdNum = parseInt(gameBoard[2][4]);
  }

  if (gameBoard[row][3] === "-") {
    currentTotal -= thirdNum;
  } else {
    currentTotal += thirdNum;
  }
  return currentTotal;
};

export const checkWinner = (gameBoard: string[][], inputs: SquareInputs) => {
  const indexes = [0, 2, 4];

  for (let i = 0; i < indexes.length; i++) {
    const target = parseInt(gameBoard[5][indexes[i]]);
    const colTotal = getColumnTotal(gameBoard, indexes[i], inputs);
    if (colTotal !== target) {
      return false;
    }
  }

  for (let i = 0; i < indexes.length; i++) {
    const target = parseInt(gameBoard[indexes[i]][5]);
    const rowTotal = getRowTotal(gameBoard, indexes[i], inputs);
    if (rowTotal !== target) {
      return false;
    }
  }

  return true;
};
