import Axios from "axios";

export interface WikiData {
  name: string;
  plotText: string[];
}

export const extractSection = (text: string, section: string) => {
  const plotStr = `== ${section} ==`;

  const index = text.indexOf(plotStr);

  let finalIndex = -1;
  for (let i = index + plotStr.length; i < text.length; i++) {
    if (text[i] === "=" && text[i + 1] === "=") {
      finalIndex = i;
      break;
    }
  }

  return text.substring(index + plotStr.length, finalIndex);
};

const extractIntro = (text : string) => {

  let finalIndex = -1;
  for (let i = 0; i < text.length; i++) {
    if (text[i] === "=" && text[i + 1] === "=") {
      finalIndex = i;
      break;
    }
  }

  return text.substring(0, finalIndex);
}

export const getWikipediaPageIntro = async (
  name: string,
): Promise<WikiData> => {
  const result = await Axios.get(
    `https://en.wikipedia.org/w/api.php?action=query&explaintext=&prop=extracts&titles=${name}&format=json&origin=*`
  );
  const pages = result.data.query.pages;
  const text = pages[Object.keys(pages)[0]].extract;
  const extractedSection = extractIntro(text);

  return {
    name,
    plotText: getStringArr(extractedSection),
  };
};

export const getWikipediaPageSection = async (
  movieName: string,
  wikiPageTitle: string,
  section: string
): Promise<WikiData> => {
  const result = await Axios.get(
    `https://en.wikipedia.org/w/api.php?action=query&explaintext=&prop=extracts&titles=${wikiPageTitle}&format=json&origin=*`
  );
  const pages = result.data.query.pages;
  const text = pages[Object.keys(pages)[0]].extract;
  const extractedSection = extractSection(text, section);

  return {
    name: movieName,
    plotText: getStringArr(extractedSection),
  };
};

export const getStringArr = (plotText: string | null): string[] => {
  if (plotText === null) {
    return [];
  }

  return plotText.split(/\b/);
};

export interface WikiObject {
  name: string;
  wikiPageTitle: string;
  imageUrl?: string;
  section?: number;
}
