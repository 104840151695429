import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers"; 

interface WordsData {
    startingLetter : string;
    endingLetter: string;
}

export const getWordsData = async (wordsData: WordsData) => {
    const client = new LambdaClient({
      region: "us-east-1",
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: "us-east-1" },
        identityPoolId: "us-east-1:2ef54d02-04bc-4e62-acf2-03f11eda00dd",
      }),
      
    });
    let params = {
      FunctionName: "expand-lambda",
      InvocationType: "RequestResponse",
      LogType: "None",
      Payload: new TextEncoder().encode(JSON.stringify(wordsData)),
    };
    const command = new InvokeCommand(params);
    try {
      const response = await client.send(command);
      const parsedResult = JSON.parse(new TextDecoder().decode(response.Payload));
      const result = JSON.parse(parsedResult.body) as any
      if (result.words !== undefined && result.words !== null) {
        return result.words;
      }
    } catch (error) {
      console.log("Error: ", error);
      return [];
    }
    return [];
  };