import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import { getGamesPlayed, getGamesWon, getCurrentStreak } from "../utils/stats";

interface StatsSectionProps {
  gameName: string;
}

const StatsSection = ({ gameName }: StatsSectionProps) => {
  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Box p={5} shadow="md">
        <Heading fontSize="md">Games Played</Heading>
        <Text>{getGamesPlayed(gameName)}</Text>
      </Box>
      <Box p={5} shadow="md">
        <Heading fontSize="md">Games Won</Heading>
        <Text>{getGamesWon(gameName)}</Text>
      </Box>
      <Box p={5} shadow="md">
        <Heading fontSize="md">Streak</Heading>
        <Text>{getCurrentStreak(gameName)}</Text>
      </Box>
    </div>
  );
};

export default StatsSection;
