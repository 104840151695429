const dailyExpansion : {[key: string] : string} = {
    "0-0": "jewelry",
    "0-1": "engineer",
    "0-2": "running",
    "0-3": "annual",
    "0-4": "capture",
    "0-5": "impossible",
    "0-6": "operation",
    "0-7": "confused",
    "0-8": "continue",
    "0-9": "boat",
    "0-10": "highlight",
    "0-11": "cheerful",
    "0-12": "mobile",
    "0-13": "disaster",
    "0-14": "expand",
    "0-15": "grass",
    "0-16": "smooth",
    "0-17": "wine",
    "0-18": "client",
    "0-19": "container",
    "0-20": "temporary",
    "0-21": "tightly",
    "0-22": "exception",
    "0-23": "serious",
    "0-24": "admire",
    "0-25": "relationship",
    "0-26": "officially",
    "0-27": "awkward",
    "0-28": "edge",
    "0-29": "sudden",
    "0-30": "song",
    "0-31": "supply",
    "1-0": "limited",
    "1-1": "event",
    "1-2": "nature",
    "1-3": "orange",
    "1-4": "drunk",
    "1-5": "score",
    "1-6": "blue",
    "1-7": "officer",
    "1-8": "instruction",
    "1-9": "enthusiastically",
    "1-10": "outside",
    "1-11": "peace",
    "1-12": "cope",
    "1-13": "hospital",
    "1-14": "packaging",
    "1-15": "angle",
    "1-16": "permission",
    "1-17": "surroundings",
    "1-18": "membership",
    "1-19": "category",
    "1-20": "locate",
    "1-21": "society",
    "1-22": "relax",
    "1-23": "insist",
    "1-24": "believe",
    "1-25": "helpful",
    "1-26": "stretch",
    "1-27": "occasionally",
    "1-28": "wave",
    "1-29": "shocked",
    "1-30": "illegal",
    "1-31": "contribute",
    "2-0": "niece",
    "2-1": "soup",
    "2-2": "eighteen",
    "2-3": "investigation",
    "2-4": "street",
    "2-5": "medical",
    "2-6": "truth",
    "2-7": "trap",
    "2-8": "against",
    "2-9": "organized",
    "2-10": "holy",
    "2-11": "butter",
    "2-12": "department",
    "2-13": "satisfying",
    "2-14": "slight",
    "2-15": "underwear",
    "2-16": "lift",
    "2-17": "explain",
    "2-18": "judgement",
    "2-19": "whoever",
    "2-20": "credit",
    "2-21": "variety",
    "2-22": "continuous",
    "2-23": "airport",
    "2-24": "record",
    "2-25": "sure",
    "2-26": "prefer",
    "2-27": "swell",
    "2-28": "save",
    "2-29": "badly",
    "2-30": "uncontrolled",
    "2-31": "surprise",
    "3-0": "runner",
    "3-1": "strip",
    "3-2": "drug",
    "3-3": "vocabulary",
    "3-4": "signature",
    "3-5": "life",
    "3-6": "surprising",
    "3-7": "grain",
    "3-8": "museum",
    "3-9": "especially",
    "3-10": "restriction",
    "3-11": "theatre",
    "3-12": "conference",
    "3-13": "window",
    "3-14": "married",
    "3-15": "borrow",
    "3-16": "despite",
    "3-17": "minimum",
    "3-18": "social",
    "3-19": "prayer",
    "3-20": "need",
    "3-21": "person",
    "3-22": "understanding",
    "3-23": "create",
    "3-24": "keep",
    "3-25": "indicate",
    "3-26": "that",
    "3-27": "hesitate",
    "3-28": "decrease",
    "3-29": "outdoor",
    "3-30": "shout",
    "3-31": "castle",
    "4-0": "ruler",
    "4-1": "find",
    "4-2": "dressed",
    "4-3": "nest",
    "4-4": "rain",
    "4-5": "shiny",
    "4-6": "adjust",
    "4-7": "thousandth",
    "4-8": "rank",
    "4-9": "regret",
    "4-10": "origin",
    "4-11": "rear",
    "4-12": "introduction",
    "4-13": "underground",
    "4-14": "inform",
    "4-15": "painful",
    "4-16": "mother",
    "4-17": "traditionally",
    "4-18": "frightening",
    "4-19": "harm",
    "4-20": "gentleman",
    "4-21": "bowl",
    "4-22": "bath",
    "4-23": "passport",
    "4-24": "concentrate",
    "4-25": "discovery",
    "4-26": "last",
    "4-27": "since",
    "4-28": "connection",
    "4-29": "husband",
    "4-30": "whole",
    "4-31": "survey",
    "5-0": "midday",
    "5-1": "saving",
    "5-2": "comfort",
    "5-3": "infectious",
    "5-4": "capacity",
    "5-5": "promote",
    "5-6": "yours",
    "5-7": "strangely",
    "5-8": "route",
    "5-9": "crime",
    "5-10": "further",
    "5-11": "extend",
    "5-12": "boring",
    "5-13": "shame",
    "5-14": "complicated",
    "5-15": "resistance",
    "5-16": "contemporary",
    "5-17": "volume",
    "5-18": "below",
    "5-19": "mall",
    "5-20": "noticeable",
    "5-21": "everyone",
    "5-22": "litre",
    "5-23": "month",
    "5-24": "gold",
    "5-25": "week",
    "5-26": "ambition",
    "5-27": "enter",
    "5-28": "worth",
    "5-29": "bend",
    "5-30": "somehow",
    "5-31": "ease",
    "6-0": "proof",
    "6-1": "error",
    "6-2": "react",
    "6-3": "pretend",
    "6-4": "privately",
    "6-5": "temperature",
    "6-6": "poor",
    "6-7": "influence",
    "6-8": "crazy",
    "6-9": "invitation",
    "6-10": "iron",
    "6-11": "establish",
    "6-12": "worker",
    "6-13": "inability",
    "6-14": "roof",
    "6-15": "community",
    "6-16": "negative",
    "6-17": "encourage",
    "6-18": "finally",
    "6-19": "uniform",
    "6-20": "specially",
    "6-21": "noisy",
    "6-22": "thirty",
    "6-23": "rude",
    "6-24": "entry",
    "6-25": "taste",
    "6-26": "bone",
    "6-27": "struggle",
    "6-28": "side",
    "6-29": "determined",
    "6-30": "item",
    "6-31": "woman",
    "7-0": "test",
    "7-1": "crush",
    "7-2": "judge",
    "7-3": "degree",
    "7-4": "clothes",
    "7-5": "attraction",
    "7-6": "significant",
    "7-7": "accidentally",
    "7-8": "provided",
    "7-9": "stress",
    "7-10": "weather",
    "7-11": "brick",
    "7-12": "festival",
    "7-13": "remember",
    "7-14": "surrounding",
    "7-15": "adapt",
    "7-16": "crack",
    "7-17": "bomb",
    "7-18": "mysterious",
    "7-19": "chicken",
    "7-20": "deeply",
    "7-21": "alphabetically",
    "7-22": "artist",
    "7-23": "totally",
    "7-24": "decade",
    "7-25": "concentration",
    "7-26": "initiative",
    "7-27": "fiftieth",
    "7-28": "enough",
    "7-29": "enthusiastic",
    "7-30": "scared",
    "7-31": "index",
    "8-0": "knock",
    "8-1": "yard",
    "8-2": "normal",
    "8-3": "demonstrate",
    "8-4": "knitting",
    "8-5": "offensive",
    "8-6": "gallon",
    "8-7": "impact",
    "8-8": "guess",
    "8-9": "purple",
    "8-10": "young",
    "8-11": "activity",
    "8-12": "wool",
    "8-13": "period",
    "8-14": "birthday",
    "8-15": "third",
    "8-16": "finance",
    "8-17": "pronounce",
    "8-18": "major",
    "8-19": "never",
    "8-20": "bottle",
    "8-21": "morally",
    "8-22": "knitted",
    "8-23": "priority",
    "8-24": "appear",
    "8-25": "mention",
    "8-26": "brightly",
    "8-27": "widely",
    "8-28": "later",
    "8-29": "frighten",
    "8-30": "former",
    "8-31": "might",
    "9-0": "music",
    "9-1": "industrial",
    "9-2": "commercial",
    "9-3": "shell",
    "9-4": "lose",
    "9-5": "picture",
    "9-6": "automatically",
    "9-7": "previously",
    "9-8": "survey",
    "9-9": "digital",
    "9-10": "focus",
    "9-11": "west",
    "9-12": "detail",
    "9-13": "farthest",
    "9-14": "some",
    "9-15": "often",
    "9-16": "rented",
    "9-17": "explanation",
    "9-18": "obey",
    "9-19": "reasonable",
    "9-20": "attend",
    "9-21": "lunch",
    "9-22": "travel",
    "9-23": "swing",
    "9-24": "topic",
    "9-25": "satisfied",
    "9-26": "author",
    "9-27": "wander",
    "9-28": "victim",
    "9-29": "send",
    "9-30": "assist",
    "9-31": "multiply",
    "10-0": "somewhere",
    "10-1": "skilful",
    "10-2": "soon",
    "10-3": "software",
    "10-4": "output",
    "10-5": "purely",
    "10-6": "cousin",
    "10-7": "accurate",
    "10-8": "leave",
    "10-9": "chief",
    "10-10": "oddly",
    "10-11": "square",
    "10-12": "waiter",
    "10-13": "fine",
    "10-14": "printing",
    "10-15": "substantially",
    "10-16": "hole",
    "10-17": "merely",
    "10-18": "disturb",
    "10-19": "screen",
    "10-20": "love",
    "10-21": "implication",
    "10-22": "unfair",
    "10-23": "embarrass",
    "10-24": "favourite",
    "10-25": "ship",
    "10-26": "reservation",
    "10-27": "brown",
    "10-28": "ideal",
    "10-29": "feather",
    "10-30": "transform",
    "10-31": "cancel",
    "11-0": "child",
    "11-1": "level",
    "11-2": "invite",
    "11-3": "financial",
    "11-4": "thank",
    "11-5": "difficulty",
    "11-6": "board",
    "11-7": "game",
    "11-8": "whatever",
    "11-9": "federal",
    "11-10": "most",
    "11-11": "post",
    "11-12": "break",
    "11-13": "them",
    "11-14": "horn",
    "11-15": "customs",
    "11-16": "rise",
    "11-17": "nineteen",
    "11-18": "sock",
    "11-19": "growth",
    "11-20": "return",
    "11-21": "behave",
    "11-22": "accent",
    "11-23": "tank",
    "11-24": "drink",
    "11-25": "lend",
    "11-26": "needle",
    "11-27": "food",
    "11-28": "spicy",
    "11-29": "frozen",
    "11-30": "stomach",
    "11-31": "praise",
    }

export const getDailyExpansion = (key : string) => {
    return dailyExpansion[key];
}