import { isOnlyLettersAndNumbers } from "../../../common/utils/letters";

export const getPercentageUncovered = (guessedWords : string[], totalWords : string[]) => {
  return (((guessedWords.length / totalWords.length) * 100).toFixed(2))
}

const areAllLettersSame = (word: string) => {
  const letter = word.charAt(0);
  for (let i = 1; i < word.length; i++) {
    
    if (letter !== word.charAt(i)) {
      return false;
    }
  }
  return true;
};

const reverseStr = (word: string) => {
  let reversedWord = "";
  for (let i = word.length - 1; i >= 0; i--) {
    reversedWord = `${reversedWord}${word[i]}`;
  }
  return reversedWord;
};

export const shuffleWord = (word: string) => {
  if (word.trim().length === 0) {
    return word;
  }
  const originalWord = word;
  let shuffledWord = "";
  while (word.length !== 0) {
    const randIndex = Math.floor(Math.random() * word.length);
    shuffledWord += word[randIndex];
    const letter = word[randIndex];
    word = word.replace(letter, "");
  }
  if (
    originalWord === shuffledWord &&
    originalWord.length > 1 &&
    !areAllLettersSame(originalWord)
  ) {
    shuffledWord = reverseStr(originalWord);
  }
  return shuffledWord.toLowerCase();
};

export interface Scramble {
  scrambledWord: string;
  numOccurrences: number;
}

/**
 * Returns a map of all words in a shuffle
 * @param guessedWords
 */
export const createScrambledWordsMap = (
  plotText: string[],
  name: string
): Map<string, Scramble> => {
  const shuffledWordsMap = new Map<string, Scramble>();
  for (const word of plotText) {
    if (!isOnlyLettersAndNumbers(word)) {
      continue;
    }
    if (shuffledWordsMap.has(word)) {
      const value: Scramble = shuffledWordsMap.get(word)!;
      value.numOccurrences++;
      shuffledWordsMap.set(word, {
        ...value,
        numOccurrences: value.numOccurrences + 1,
      });
    } else {
      const scrambledWord = shuffleWord(word.toLowerCase());
      shuffledWordsMap.set(word.toLowerCase(), { scrambledWord, numOccurrences: 1 });
    }
  }

  const nameArr = name.split(" ");
  for (const word of nameArr) {
    if (!shuffledWordsMap.has(word)) {
      const scrambledWord = shuffleWord(word);
      shuffledWordsMap.set(word.toLowerCase(), { scrambledWord, numOccurrences: 1 });
    }
  }
  return shuffledWordsMap;
};
