import React from "react";

interface ExpansionStepsContentProps {
  step: number;
}

const ExpansionStepsContent = ({ step }: ExpansionStepsContentProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {step === 1 ? (
        <div>
          Welcome to Expansion! Try to guess the word of the day in order to win
          the puzzle.
        </div>
      ) : (
        <></>
      )}
      {step === 2 ? (
        <div>
          <div>
            <img
              alt="expansion"
              src="/assets/Expansion.gif"
              style={{
                position: "relative",
                height: "200px",
              }}
            />
          </div>
          <div>
            <p>
              You will be provided with the starting and ending letters. As you
              add input to the keyboard, the word will expand and press 'Enter'
              to submit a guess for what the word is.
            </p>
            <p>
              Once a guess is made, the keyboard will light up with correct and
              incorrect letters. The word within the rows will also light up.
              Green means that the letter is in the correct position, orange
              means that the letter is in the word, just in the wrong position.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {step === 3 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <div>Try to guess the word within 6 tries to win the game!</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ExpansionStepsContent;
