import React from "react";
import { alphabet } from "../../../common/utils/letters";
import { Button } from "@chakra-ui/react";

interface InputsProps {
  handleInput: Function;
  correctLettersGuessed: string[];
}

const Inputs = ({ handleInput, correctLettersGuessed }: InputsProps) => {
  return (
    <div>
      {alphabet.split("").map((letter) => (
        <Button
          style={{
            margin: "5px",
          }}
          size="sm"
          key={letter}
          isDisabled={correctLettersGuessed.includes(letter)}
          colorScheme={
            correctLettersGuessed.includes(letter) ? "green" : "gray"
          }
          onClick={() => handleInput(letter)}
        >
          {letter}
        </Button>
      ))}
    </div>
  );
};

export default Inputs;
