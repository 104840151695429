import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
} from "@chakra-ui/react";
import StatsSection from "../../../common/StatsSection";

interface WinnerModalProps {
  isShowing: boolean;
  setModalShowing: Function;
}

const WinnerModal = ({ isShowing, setModalShowing }: WinnerModalProps) => {
  return (
    <div>
      <Modal isOpen={isShowing} onClose={() => setModalShowing(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>You Win!</ModalHeader>
          <ModalBody>
            <div>
              Nice job! Look forward to seeing you for tomorrow's puzzle!
            </div>

            <StatsSection gameName="hilo" />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setModalShowing(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default WinnerModal;
