import React, { useEffect, useState } from "react";
import Keyboard from "../../common/Keyboard";
import { Button, Heading, Spinner, Text } from "@chakra-ui/react";
import { getWordsData } from "../../common/utils/getWords";
import ExpandWord from "./components/ExpandWord";
import GuessedWords from "./components/GuessedWords";
import { getDailyKey } from "../../common/utils/localStorage";
import { getDailyExpansion } from "./dailyExpansion";
import { setData } from "../../common/utils/localStorage";
import { getLocalStorageData } from "../../common/utils/localStorage";
import HowToPlayExpansionModal from "./components/HowToPlayExpansionModal";
import { alphabet } from "../../common/utils/letters";

interface ExpansionLocalStorageData {
  possibleWords: string[];
  guessedWords: string[];
  solutionWord: string;
  isWinner: boolean;
}

const getHighlightedLetters = (
  guessedWords: string[],
  solutionWord: string
) => {
  const highlightedLettersMap = new Map<string, string>();
  for (let i = 0; i < guessedWords.length; i++) {
    for (const letter of guessedWords[i]) {
      if (!highlightedLettersMap.has(letter) && solutionWord.includes(letter)) {
        highlightedLettersMap.set(letter, "green");
      } else if (!highlightedLettersMap.has(letter)) {
        highlightedLettersMap.set(letter, "blackAlpha");
      }
    }
  }
  return highlightedLettersMap;
};

const Gameplay = () => {
  const [currentInput, setCurrentInput] = useState("");
  const [guessedWords, setGuessedWords] = useState<string[]>([]);
  const [solutionWord, setSolutionWord] = useState<string>("");
  const [isWinner, setIsWinner] = useState<boolean>(false);
  const [possibleWords, setPossibleWords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);

  useEffect(() => {
    const dailyKey = getDailyKey();
    const word = getDailyExpansion(dailyKey);
    const startingLetter = word[0];
    const endingLetter = word[word.length - 1];
    setSolutionWord(word);
    const localStorageData = getLocalStorageData("expansion");
    if (localStorageData) {
      const expansionData = localStorageData as ExpansionLocalStorageData;
      if (expansionData.possibleWords.length === 0) {
        getWordsData({ startingLetter, endingLetter }).then(
          (data: string[]) => {
            setPossibleWords(data);
          }
        );
      } else {
        setPossibleWords(expansionData.possibleWords);
        setGuessedWords(expansionData.guessedWords);
        setIsWinner(expansionData.isWinner);
      }
      setIsLoading(false);
    } else {
      setShowHowToPlayModal(true);
      getWordsData({ startingLetter, endingLetter }).then((data: string[]) => {
        setPossibleWords(data);
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    }
    function handleKeyDown(e: any) {
      const character = String.fromCharCode(e.keyCode).toLowerCase();
      if (e.keyCode === 13) {
        enterClickHandler();
      } else if (e.keyCode === 8) {
        backspaceHandler();
      } else if (alphabet.includes(character)) {
        setCurrentInput(currentInput + character);
      }
    }
    if (isWinner) {
      cleanup();
    } else {
      document.addEventListener("keydown", handleKeyDown);
    }

    return cleanup;
  }, [currentInput, isWinner]);

  useEffect(() => {
    setData(getDailyKey(), localStorage, "expansion", {
      guessedWords,
      possibleWords,
      isWinner,
    });
  }, [guessedWords, possibleWords, isWinner]);

  const enterClickHandler = () => {
    const firstLetter = solutionWord[0];
    const lastLetter = solutionWord[solutionWord.length - 1];
    const guess = `${firstLetter}${currentInput}${lastLetter}`.toLowerCase();
    if (!possibleWords.includes(guess)) {
      alert(`${guess} is not a valid word`);
    } else if (!guessedWords.includes(guess)) {
      setGuessedWords([...guessedWords, guess]);
      if (guess === solutionWord) {
        setIsWinner(true);
      }
      setCurrentInput("");
    } else {
      alert(`You've already guessed: ${guess}`);
    }
  };

  const backspaceHandler = () => {
    const guessedWordsInputMinusOne = currentInput.slice(
      0,
      currentInput.length - 1
    );
    setCurrentInput(guessedWordsInputMinusOne);
  };

  if (isLoading) {
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
        <Heading>Generating word possibilities...</Heading>
      </div>
    );
  }

  const isLoser = guessedWords.length === 6 && !isWinner;
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {showHowToPlayModal && <HowToPlayExpansionModal />}
      {solutionWord.length !== 0 && (
        <>
          <GuessedWords
            guessedWords={guessedWords}
            solutionWord={solutionWord}
          />
          <Text fontSize="md">Length: {solutionWord.length} letters</Text>
          {!isWinner && !isLoser && (
            <ExpandWord
              firstLetter={solutionWord[0]}
              currentInput={currentInput}
              lastLetter={solutionWord[solutionWord.length - 1]}
            />
          )}
        </>
      )}
      {isLoser && <div>You lost - The word was {solutionWord}</div>}
      {isWinner && <div>You win! Check in tomorrow for a new puzzle!</div>}
      {!isWinner && !isLoser && (
        <>
          <Button
            onClick={() => {
              setCurrentInput("");
            }}
          >
            Clear
          </Button>

          <Keyboard
            highlightedLetters={getHighlightedLetters(
              guessedWords,
              solutionWord
            )}
            includesNumbers={false}
            handleClick={(input: string) =>
              setCurrentInput(currentInput + input)
            }
            onEnterClick={enterClickHandler}
            onBackClick={backspaceHandler}
          />
        </>
      )}
    </div>
  );
};

export default Gameplay;
