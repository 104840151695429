import { Authenticator } from '@aws-amplify/ui-react'
import React from 'react'
import '@aws-amplify/ui-react/styles.css';
import { Button } from '@chakra-ui/react';
import Navbar from '../common/Navbar';

const Account = () => {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh"
    }}>
      <Navbar />
      <Authenticator >
    {({ signOut, user }) => (
        <div>
          <p>Welcome {user?.attributes?.email}</p>
          <Button onClick={signOut}>Sign out</Button>
        </div>
    )}
  </Authenticator></div>
  )
}

export default Account