import { SquaresGame } from "./utils";

export const dailySquares: { [key: string]: SquaresGame} = {
  "0-0": {
    gameBoard: [
      ["5", "+", "7", "+", "3", "15"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "1", "-", "9", "0"],
      ["-", "_", "+", "_", "+"],
      ["4", "-", "2", "-", "6", "-4"],
      ["9", "_", "8", "_", "18"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "3",
      "2-2": "1",
      "4-0": "4",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [8, 9],
  },
  "0-1": {
    gameBoard: [
      ["1", "+", "3", "+", "5", "9"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "6", "-", "7", "8"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "8", "+", "4", "-2"],
      ["8", "_", "5", "_", "2"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "5",
      "2-2": "6",
      "4-0": "2",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [9, 7],
  },
  "0-2": {
    gameBoard: [
      ["7", "-", "2", "-", "1", "4"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "3", "-", "5", "6"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "9", "+", "4", "19"],
      ["21", "_", "-4", "_", "0"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "1",
      "2-2": "3",
      "4-0": "6",
      "4-2": "9",
      "4-4": "4",
    },
    revealedNumbers: [8, 5],
  },
  "0-3": {
    gameBoard: [
      ["2", "-", "3", "+", "5", "4"],
      ["-", "_", "+", "_", "-"],
      ["6", "+", "4", "+", "1", "11"],
      ["-", "_", "+", "_", "-"],
      ["7", "+", "9", "-", "8", "8"],
      ["-11", "_", "16", "_", "-4"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "5",
      "2-2": "4",
      "4-0": "7",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [6, 1],
  },
  "0-4": {
    gameBoard: [
      ["8", "-", "6", "-", "5", "-3"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "1", "+", "9", "10"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "7", "-", "3", "-6"],
      ["10", "_", "-2", "_", "-7"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "5",
      "2-2": "1",
      "4-0": "4",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [2, 9],
  },
  "0-5": {
    gameBoard: [
      ["9", "-", "5", "-", "6", "-2"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "2", "+", "3", "6"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "7", "-", "8", "-11"],
      ["14", "_", "10", "_", "17"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "5",
      "0-4": "6",
      "2-2": "2",
      "4-0": "4",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [1, 3],
  },
  "0-6": {
    gameBoard: [
      ["2", "+", "8", "-", "7", "3"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "4", "-", "9", "0"],
      ["-", "_", "+", "_", "-"],
      ["1", "-", "6", "-", "3", "-8"],
      ["-4", "_", "18", "_", "-5"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "7",
      "2-2": "4",
      "4-0": "1",
      "4-2": "6",
      "4-4": "3",
    },
    revealedNumbers: [5, 9],
  },
  "0-7": {
    gameBoard: [
      ["5", "-", "3", "+", "7", "9"],
      ["-", "_", "+", "_", "-"],
      ["1", "+", "4", "-", "6", "-1"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "9", "+", "8", "19"],
      ["2", "_", "16", "_", "9"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "7",
      "2-2": "4",
      "4-0": "2",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [1, 6],
  },
  "0-8": {
    gameBoard: [
      ["7", "-", "9", "+", "2", "0"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "6", "-", "3", "-1"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "1", "-", "4", "0"],
      ["20", "_", "2", "_", "9"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "2",
      "2-2": "6",
      "4-0": "5",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [8, 3],
  },
  "0-9": {
    gameBoard: [
      ["5", "+", "2", "-", "8", "-1"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "1", "-", "6", "-3"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "3", "+", "9", "19"],
      ["16", "_", "0", "_", "-7"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "2",
      "0-4": "8",
      "2-2": "1",
      "4-0": "7",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [4, 6],
  },
  "0-10": {
    gameBoard: [
      ["7", "+", "2", "+", "4", "13"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "8", "-", "3", "14"],
      ["-", "_", "-", "_", "+"],
      ["5", "-", "1", "+", "6", "10"],
      ["11", "_", "-7", "_", "13"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "4",
      "2-2": "8",
      "4-0": "5",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [9, 3],
  },
  "0-11": {
    gameBoard: [
      ["8", "-", "4", "+", "2", "6"],
      ["+", "_", "-", "_", "-"],
      ["6", "+", "1", "+", "9", "16"],
      ["+", "_", "+", "_", "+"],
      ["3", "-", "7", "-", "5", "-9"],
      ["17", "_", "10", "_", "-2"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "4",
      "0-4": "2",
      "2-2": "1",
      "4-0": "3",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [6, 9],
  },
  "0-12": {
    gameBoard: [
      ["4", "-", "8", "+", "5", "1"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "6", "+", "1", "4"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "7", "+", "3", "-2"],
      ["11", "_", "9", "_", "7"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "5",
      "2-2": "6",
      "4-0": "2",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [9, 1],
  },
  "0-13": {
    gameBoard: [
      ["1", "+", "7", "-", "3", "5"],
      ["+", "_", "+", "_", "-"],
      ["2", "-", "4", "+", "9", "7"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "5", "+", "6", "19"],
      ["-5", "_", "6", "_", "0"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "7",
      "0-4": "3",
      "2-2": "4",
      "4-0": "8",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [2, 9],
  },
  "0-14": {
    gameBoard: [
      ["6", "-", "2", "+", "9", "13"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "5", "+", "4", "17"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "1", "+", "3", "9"],
      ["21", "_", "8", "_", "16"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "9",
      "2-2": "5",
      "4-0": "7",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [8, 4],
  },
  "0-15": {
    gameBoard: [
      ["5", "-", "7", "-", "9", "-11"],
      ["+", "_", "+", "_", "-"],
      ["2", "-", "4", "-", "1", "-3"],
      ["+", "_", "+", "_", "-"],
      ["6", "+", "8", "-", "3", "11"],
      ["13", "_", "19", "_", "5"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "9",
      "2-2": "4",
      "4-0": "6",
      "4-2": "8",
      "4-4": "3",
    },
    revealedNumbers: [2, 1],
  },
  "0-16": {
    gameBoard: [
      ["3", "+", "1", "-", "9", "-5"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "6", "+", "7", "17"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "2", "+", "5", "11"],
      ["15", "_", "-7", "_", "7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "9",
      "2-2": "6",
      "4-0": "8",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [4, 7],
  },
  "0-17": {
    gameBoard: [
      ["1", "+", "8", "+", "5", "14"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "4", "-", "7", "6"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "2", "+", "3", "7"],
      ["16", "_", "14", "_", "1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "5",
      "2-2": "4",
      "4-0": "6",
      "4-2": "2",
      "4-4": "3",
    },
    revealedNumbers: [9, 7],
  },
  "0-18": {
    gameBoard: [
      ["5", "+", "8", "+", "1", "14"],
      ["-", "_", "-", "_", "+"],
      ["4", "+", "3", "-", "9", "-2"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "6", "-", "2", "11"],
      ["8", "_", "11", "_", "8"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "1",
      "2-2": "3",
      "4-0": "7",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [4, 9],
  },
  "0-19": {
    gameBoard: [
      ["4", "-", "2", "-", "9", "-7"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "6", "-", "3", "4"],
      ["-", "_", "+", "_", "-"],
      ["8", "+", "7", "-", "5", "10"],
      ["-3", "_", "15", "_", "7"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "9",
      "2-2": "6",
      "4-0": "8",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [1, 3],
  },
  "0-20": {
    gameBoard: [
      ["6", "+", "7", "+", "9", "22"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "4", "-", "5", "2"],
      ["-", "_", "-", "_", "+"],
      ["2", "-", "1", "-", "8", "-7"],
      ["7", "_", "2", "_", "12"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "9",
      "2-2": "4",
      "4-0": "2",
      "4-2": "1",
      "4-4": "8",
    },
    revealedNumbers: [3, 5],
  },
  "0-21": {
    gameBoard: [
      ["3", "-", "1", "+", "5", "7"],
      ["+", "_", "-", "_", "+"],
      ["2", "-", "4", "+", "9", "7"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "8", "-", "6", "9"],
      ["12", "_", "-11", "_", "8"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "5",
      "2-2": "4",
      "4-0": "7",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [2, 9],
  },
  "0-22": {
    gameBoard: [
      ["9", "-", "1", "+", "6", "14"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "2", "+", "4", "9"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "8", "-", "3", "10"],
      ["-3", "_", "7", "_", "-1"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "6",
      "2-2": "2",
      "4-0": "5",
      "4-2": "8",
      "4-4": "3",
    },
    revealedNumbers: [7, 4],
  },
  "0-23": {
    gameBoard: [
      ["6", "-", "5", "+", "1", "2"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "9", "+", "4", "16"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "7", "+", "2", "3"],
      ["11", "_", "21", "_", "7"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "5",
      "0-4": "1",
      "2-2": "9",
      "4-0": "8",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [3, 4],
  },
  "0-24": {
    gameBoard: [
      ["2", "-", "7", "-", "9", "-14"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "4", "+", "6", "13"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "5", "-", "8", "-12"],
      ["-2", "_", "6", "_", "-5"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "7",
      "0-4": "9",
      "2-2": "4",
      "4-0": "1",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [3, 6],
  },
  "0-25": {
    gameBoard: [
      ["5", "+", "9", "-", "3", "11"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "1", "-", "8", "-3"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "4", "+", "2", "5"],
      ["4", "_", "6", "_", "-7"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "3",
      "2-2": "1",
      "4-0": "7",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [6, 8],
  },
  "0-26": {
    gameBoard: [
      ["1", "+", "7", "+", "3", "11"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "6", "-", "4", "-1"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "5", "-", "2", "11"],
      ["-16", "_", "8", "_", "1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "7",
      "0-4": "3",
      "2-2": "6",
      "4-0": "8",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [9, 4],
  },
  "0-27": {
    gameBoard: [
      ["3", "-", "7", "+", "2", "-2"],
      ["-", "_", "-", "_", "+"],
      ["6", "-", "8", "+", "4", "2"],
      ["-", "_", "+", "_", "+"],
      ["9", "+", "1", "-", "5", "5"],
      ["-12", "_", "0", "_", "11"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "2",
      "2-2": "8",
      "4-0": "9",
      "4-2": "1",
      "4-4": "5",
    },
    revealedNumbers: [6, 4],
  },
  "0-28": {
    gameBoard: [
      ["2", "-", "3", "-", "6", "-7"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "7", "+", "4", "6"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "8", "-", "1", "12"],
      ["16", "_", "18", "_", "1"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "6",
      "2-2": "7",
      "4-0": "5",
      "4-2": "8",
      "4-4": "1",
    },
    revealedNumbers: [9, 4],
  },
  "0-29": {
    gameBoard: [
      ["9", "-", "4", "+", "2", "7"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "1", "-", "5", "1"],
      ["+", "_", "+", "_", "+"],
      ["3", "+", "8", "+", "6", "17"],
      ["19", "_", "13", "_", "13"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "2",
      "2-2": "1",
      "4-0": "3",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [7, 5],
  },
  "0-30": {
    gameBoard: [
      ["6", "+", "9", "+", "2", "17"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "3", "-", "1", "6"],
      ["-", "_", "-", "_", "+"],
      ["8", "-", "5", "+", "7", "10"],
      ["2", "_", "1", "_", "10"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "9",
      "0-4": "2",
      "2-2": "3",
      "4-0": "8",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [4, 1],
  },
  "0-31": {
    gameBoard: [
      ["3", "-", "4", "-", "1", "-2"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "7", "+", "9", "7"],
      ["-", "_", "-", "_", "+"],
      ["2", "+", "8", "+", "6", "16"],
      ["-4", "_", "3", "_", "-2"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "1",
      "2-2": "7",
      "4-0": "2",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [5, 9],
  },
  "1-0": {
    gameBoard: [
      ["6", "-", "4", "+", "9", "11"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "5", "-", "2", "11"],
      ["+", "_", "-", "_", "+"],
      ["7", "+", "1", "-", "3", "5"],
      ["21", "_", "-2", "_", "14"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "9",
      "2-2": "5",
      "4-0": "7",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [8, 2],
  },
  "1-1": {
    gameBoard: [
      ["3", "+", "7", "-", "4", "6"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "1", "-", "2", "7"],
      ["-", "_", "-", "_", "+"],
      ["6", "+", "5", "-", "9", "2"],
      ["5", "_", "1", "_", "15"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "4",
      "2-2": "1",
      "4-0": "6",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [8, 2],
  },
  "1-2": {
    gameBoard: [
      ["5", "-", "7", "+", "3", "1"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "1", "-", "9", "-4"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "6", "+", "2", "16"],
      ["17", "_", "12", "_", "-8"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "3",
      "2-2": "1",
      "4-0": "8",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [4, 9],
  },
  "1-3": {
    gameBoard: [
      ["2", "-", "6", "-", "7", "-11"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "4", "+", "3", "12"],
      ["+", "_", "+", "_", "+"],
      ["1", "-", "8", "+", "9", "2"],
      ["-2", "_", "18", "_", "19"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "7",
      "2-2": "4",
      "4-0": "1",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [5, 3],
  },
  "1-4": {
    gameBoard: [
      ["3", "-", "9", "+", "2", "-4"],
      ["-", "_", "+", "_", "+"],
      ["4", "+", "5", "-", "8", "1"],
      ["-", "_", "-", "_", "+"],
      ["1", "+", "6", "-", "7", "0"],
      ["-2", "_", "8", "_", "17"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "9",
      "0-4": "2",
      "2-2": "5",
      "4-0": "1",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [4, 8],
  },
  "1-5": {
    gameBoard: [
      ["1", "-", "8", "-", "6", "-13"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "4", "-", "7", "6"],
      ["-", "_", "-", "_", "+"],
      ["2", "-", "3", "+", "5", "4"],
      ["8", "_", "9", "_", "4"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "6",
      "2-2": "4",
      "4-0": "2",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [9, 7],
  },
  "1-6": {
    gameBoard: [
      ["4", "+", "1", "-", "5", "0"],
      ["-", "_", "-", "_", "+"],
      ["3", "+", "2", "+", "7", "12"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "6", "-", "9", "5"],
      ["9", "_", "-7", "_", "21"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "5",
      "2-2": "2",
      "4-0": "8",
      "4-2": "6",
      "4-4": "9",
    },
    revealedNumbers: [3, 7],
  },
  "1-7": {
    gameBoard: [
      ["9", "-", "3", "-", "8", "-2"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "1", "-", "6", "0"],
      ["-", "_", "-", "_", "-"],
      ["5", "+", "4", "-", "2", "7"],
      ["-3", "_", "0", "_", "0"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "8",
      "2-2": "1",
      "4-0": "5",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [7, 6],
  },
  "1-8": {
    gameBoard: [
      ["5", "+", "3", "+", "9", "17"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "6", "-", "1", "12"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "4", "-", "2", "10"],
      ["-10", "_", "1", "_", "12"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "9",
      "2-2": "6",
      "4-0": "8",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [7, 1],
  },
  "1-9": {
    gameBoard: [
      ["9", "-", "8", "+", "7", "8"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "2", "+", "6", "8"],
      ["+", "_", "+", "_", "+"],
      ["5", "+", "1", "-", "3", "3"],
      ["18", "_", "7", "_", "16"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "7",
      "2-2": "2",
      "4-0": "5",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [4, 6],
  },
  "1-10": {
    gameBoard: [
      ["4", "-", "1", "-", "2", "1"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "8", "-", "3", "12"],
      ["+", "_", "-", "_", "+"],
      ["9", "-", "5", "-", "6", "-2"],
      ["20", "_", "4", "_", "5"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "2",
      "2-2": "8",
      "4-0": "9",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [7, 3],
  },
  "1-11": {
    gameBoard: [
      ["6", "+", "4", "-", "9", "1"],
      ["-", "_", "-", "_", "+"],
      ["1", "-", "8", "+", "5", "-2"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "7", "-", "3", "6"],
      ["3", "_", "3", "_", "11"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "9",
      "2-2": "8",
      "4-0": "2",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [1, 5],
  },
  "1-12": {
    gameBoard: [
      ["5", "+", "8", "-", "1", "12"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "6", "+", "2", "0"],
      ["-", "_", "+", "_", "+"],
      ["7", "-", "3", "-", "9", "-5"],
      ["2", "_", "5", "_", "8"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "1",
      "2-2": "6",
      "4-0": "7",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [4, 2],
  },
  "1-13": {
    gameBoard: [
      ["5", "+", "6", "-", "9", "2"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "8", "-", "2", "9"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "1", "-", "4", "4"],
      ["-5", "_", "13", "_", "15"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "6",
      "0-4": "9",
      "2-2": "8",
      "4-0": "7",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [3, 2],
  },
  "1-14": {
    gameBoard: [
      ["7", "+", "1", "+", "4", "12"],
      ["+", "_", "-", "_", "-"],
      ["6", "+", "5", "-", "9", "2"],
      ["-", "_", "-", "_", "+"],
      ["3", "+", "2", "-", "8", "-3"],
      ["10", "_", "-6", "_", "3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "1",
      "0-4": "4",
      "2-2": "5",
      "4-0": "3",
      "4-2": "2",
      "4-4": "8",
    },
    revealedNumbers: [6, 9],
  },
  "1-15": {
    gameBoard: [
      ["6", "-", "7", "-", "5", "-6"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "1", "+", "4", "8"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "2", "+", "8", "15"],
      ["12", "_", "10", "_", "1"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "5",
      "2-2": "1",
      "4-0": "9",
      "4-2": "2",
      "4-4": "8",
    },
    revealedNumbers: [3, 4],
  },
  "1-16": {
    gameBoard: [
      ["3", "-", "2", "+", "7", "8"],
      ["+", "_", "+", "_", "-"],
      ["1", "+", "6", "+", "4", "11"],
      ["+", "_", "-", "_", "-"],
      ["5", "-", "8", "+", "9", "6"],
      ["9", "_", "0", "_", "-6"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "7",
      "2-2": "6",
      "4-0": "5",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [1, 4],
  },
  "1-17": {
    gameBoard: [
      ["2", "+", "1", "-", "7", "-4"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "4", "-", "5", "-3"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "9", "-", "8", "4"],
      ["5", "_", "14", "_", "10"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "1",
      "0-4": "7",
      "2-2": "4",
      "4-0": "3",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [6, 5],
  },
  "1-18": {
    gameBoard: [
      ["3", "+", "6", "-", "5", "4"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "1", "+", "7", "17"],
      ["+", "_", "+", "_", "-"],
      ["2", "+", "8", "+", "4", "14"],
      ["14", "_", "13", "_", "8"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "5",
      "2-2": "1",
      "4-0": "2",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [9, 7],
  },
  "1-19": {
    gameBoard: [
      ["9", "+", "2", "-", "5", "6"],
      ["+", "_", "-", "_", "+"],
      ["3", "+", "7", "-", "4", "6"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "8", "+", "6", "-1"],
      ["11", "_", "-13", "_", "3"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "2",
      "0-4": "5",
      "2-2": "7",
      "4-0": "1",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [3, 4],
  },
  "1-20": {
    gameBoard: [
      ["8", "+", "9", "-", "4", "13"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "5", "-", "7", "0"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "3", "+", "1", "4"],
      ["16", "_", "17", "_", "10"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "4",
      "2-2": "5",
      "4-0": "6",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [2, 7],
  },
  "1-21": {
    gameBoard: [
      ["6", "+", "4", "-", "2", "8"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "5", "+", "3", "-1"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "9", "+", "8", "6"],
      ["14", "_", "18", "_", "7"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "2",
      "2-2": "5",
      "4-0": "7",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [1, 3],
  },
  "1-22": {
    gameBoard: [
      ["4", "-", "2", "+", "5", "7"],
      ["+", "_", "+", "_", "-"],
      ["3", "+", "9", "+", "6", "18"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "7", "-", "1", "14"],
      ["-1", "_", "18", "_", "0"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "5",
      "2-2": "9",
      "4-0": "8",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [3, 6],
  },
  "1-23": {
    gameBoard: [
      ["6", "-", "1", "-", "9", "-4"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "4", "-", "2", "10"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "3", "+", "5", "15"],
      ["21", "_", "8", "_", "2"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "9",
      "2-2": "4",
      "4-0": "7",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [8, 2],
  },
  "1-24": {
    gameBoard: [
      ["6", "-", "1", "-", "5", "0"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "9", "-", "7", "-14"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "8", "+", "3", "-1"],
      ["12", "_", "18", "_", "15"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "5",
      "2-2": "9",
      "4-0": "4",
      "4-2": "8",
      "4-4": "3",
    },
    revealedNumbers: [2, 7],
  },
  "1-25": {
    gameBoard: [
      ["3", "+", "2", "-", "7", "-2"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "4", "+", "9", "13"],
      ["+", "_", "+", "_", "+"],
      ["5", "-", "6", "-", "1", "-2"],
      ["16", "_", "4", "_", "17"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "7",
      "2-2": "4",
      "4-0": "5",
      "4-2": "6",
      "4-4": "1",
    },
    revealedNumbers: [8, 9],
  },
  "1-26": {
    gameBoard: [
      ["6", "+", "2", "-", "7", "1"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "4", "+", "1", "6"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "5", "-", "8", "0"],
      ["18", "_", "-7", "_", "-2"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "7",
      "2-2": "4",
      "4-0": "3",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [9, 1],
  },
  "1-27": {
    gameBoard: [
      ["9", "-", "8", "+", "6", "7"],
      ["+", "_", "-", "_", "+"],
      ["3", "+", "5", "-", "4", "4"],
      ["-", "_", "-", "_", "+"],
      ["1", "+", "7", "-", "2", "6"],
      ["11", "_", "-4", "_", "12"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "6",
      "2-2": "5",
      "4-0": "1",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [3, 4],
  },
  "1-28": {
    gameBoard: [
      ["4", "+", "2", "-", "5", "1"],
      ["+", "_", "+", "_", "-"],
      ["3", "+", "6", "-", "8", "1"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "9", "+", "1", "-1"],
      ["14", "_", "17", "_", "-4"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "5",
      "2-2": "6",
      "4-0": "7",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [3, 8],
  },
  "1-29": {
    gameBoard: [
      ["8", "+", "9", "+", "2", "19"],
      ["-", "_", "-", "_", "+"],
      ["5", "-", "6", "+", "7", "6"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "3", "-", "1", "0"],
      ["7", "_", "6", "_", "10"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "2",
      "2-2": "6",
      "4-0": "4",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [5, 7],
  },
  "1-30": {
    gameBoard: [
      ["9", "+", "4", "+", "2", "15"],
      ["-", "_", "+", "_", "-"],
      ["6", "-", "5", "-", "7", "-6"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "3", "+", "1", "6"],
      ["11", "_", "6", "_", "-6"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "2",
      "2-2": "5",
      "4-0": "8",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [6, 7],
  },
  "1-31": {
    gameBoard: [
      ["7", "+", "9", "+", "2", "18"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "8", "+", "1", "12"],
      ["+", "_", "+", "_", "+"],
      ["5", "-", "4", "-", "6", "-5"],
      ["9", "_", "21", "_", "7"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "2",
      "2-2": "8",
      "4-0": "5",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [3, 1],
  },
  "2-0": {
    gameBoard: [
      ["4", "+", "6", "+", "3", "13"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "2", "+", "5", "11"],
      ["-", "_", "+", "_", "+"],
      ["9", "-", "7", "-", "1", "1"],
      ["3", "_", "15", "_", "9"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "3",
      "2-2": "2",
      "4-0": "9",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [8, 5],
  },
  "2-1": {
    gameBoard: [
      ["2", "-", "3", "+", "6", "5"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "1", "+", "9", "12"],
      ["+", "_", "-", "_", "+"],
      ["7", "-", "8", "-", "5", "-6"],
      ["13", "_", "-4", "_", "2"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "6",
      "2-2": "1",
      "4-0": "7",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [4, 9],
  },
  "2-2": {
    gameBoard: [
      ["9", "+", "3", "-", "7", "5"],
      ["+", "_", "+", "_", "+"],
      ["4", "+", "6", "-", "5", "5"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "8", "-", "1", "-7"],
      ["11", "_", "17", "_", "13"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "7",
      "2-2": "6",
      "4-0": "2",
      "4-2": "8",
      "4-4": "1",
    },
    revealedNumbers: [4, 5],
  },
  "2-3": {
    gameBoard: [
      ["2", "-", "6", "+", "9", "5"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "3", "+", "1", "3"],
      ["+", "_", "+", "_", "+"],
      ["4", "+", "8", "-", "7", "5"],
      ["11", "_", "11", "_", "17"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "9",
      "2-2": "3",
      "4-0": "4",
      "4-2": "8",
      "4-4": "7",
    },
    revealedNumbers: [5, 1],
  },
  "2-4": {
    gameBoard: [
      ["5", "-", "9", "+", "8", "4"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "6", "+", "7", "3"],
      ["-", "_", "+", "_", "+"],
      ["1", "-", "4", "+", "3", "0"],
      ["6", "_", "7", "_", "4"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "8",
      "2-2": "6",
      "4-0": "1",
      "4-2": "4",
      "4-4": "3",
    },
    revealedNumbers: [2, 7],
  },
  "2-5": {
    gameBoard: [
      ["1", "-", "5", "+", "9", "5"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "2", "+", "4", "12"],
      ["+", "_", "+", "_", "-"],
      ["8", "-", "3", "+", "7", "12"],
      ["15", "_", "6", "_", "6"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "9",
      "2-2": "2",
      "4-0": "8",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [6, 4],
  },
  "2-6": {
    gameBoard: [
      ["6", "+", "7", "+", "3", "16"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "9", "+", "2", "1"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "1", "-", "4", "2"],
      ["3", "_", "17", "_", "1"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "3",
      "2-2": "9",
      "4-0": "5",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [8, 2],
  },
  "2-7": {
    gameBoard: [
      ["6", "-", "2", "+", "4", "8"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "9", "-", "8", "2"],
      ["+", "_", "-", "_", "-"],
      ["5", "-", "3", "+", "7", "9"],
      ["12", "_", "-10", "_", "5"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "4",
      "2-2": "9",
      "4-0": "5",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [1, 8],
  },
  "2-8": {
    gameBoard: [
      ["2", "+", "5", "-", "6", "1"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "1", "-", "3", "6"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "7", "-", "9", "-12"],
      ["-2", "_", "-3", "_", "0"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "5",
      "0-4": "6",
      "2-2": "1",
      "4-0": "4",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [8, 3],
  },
  "2-9": {
    gameBoard: [
      ["3", "+", "4", "+", "8", "15"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "9", "-", "6", "-8"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "2", "+", "5", "8"],
      ["-3", "_", "-7", "_", "19"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "8",
      "2-2": "9",
      "4-0": "1",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [7, 6],
  },
  "2-10": {
    gameBoard: [
      ["2", "-", "4", "+", "8", "6"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "6", "+", "9", "10"],
      ["-", "_", "+", "_", "-"],
      ["3", "-", "5", "-", "1", "-3"],
      ["6", "_", "15", "_", "-2"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "8",
      "2-2": "6",
      "4-0": "3",
      "4-2": "5",
      "4-4": "1",
    },
    revealedNumbers: [7, 9],
  },
  "2-11": {
    gameBoard: [
      ["3", "+", "6", "-", "4", "5"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "1", "-", "5", "2"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "7", "+", "2", "4"],
      ["4", "_", "0", "_", "7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "4",
      "2-2": "1",
      "4-0": "9",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [8, 5],
  },
  "2-12": {
    gameBoard: [
      ["7", "+", "6", "-", "2", "11"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "3", "+", "8", "16"],
      ["+", "_", "-", "_", "+"],
      ["9", "-", "4", "+", "1", "6"],
      ["11", "_", "-1", "_", "11"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "2",
      "2-2": "3",
      "4-0": "9",
      "4-2": "4",
      "4-4": "1",
    },
    revealedNumbers: [5, 8],
  },
  "2-13": {
    gameBoard: [
      ["5", "-", "7", "-", "3", "-5"],
      ["-", "_", "+", "_", "-"],
      ["2", "-", "9", "+", "8", "1"],
      ["+", "_", "-", "_", "-"],
      ["1", "-", "6", "-", "4", "-9"],
      ["4", "_", "10", "_", "-9"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "3",
      "2-2": "9",
      "4-0": "1",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [2, 8],
  },
  "2-14": {
    gameBoard: [
      ["7", "+", "2", "+", "6", "15"],
      ["+", "_", "-", "_", "+"],
      ["3", "-", "9", "+", "4", "-2"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "5", "-", "1", "2"],
      ["2", "_", "-2", "_", "11"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "6",
      "2-2": "9",
      "4-0": "8",
      "4-2": "5",
      "4-4": "1",
    },
    revealedNumbers: [3, 4],
  },
  "2-15": {
    gameBoard: [
      ["3", "+", "5", "-", "9", "-1"],
      ["-", "_", "+", "_", "+"],
      ["6", "+", "1", "+", "4", "11"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "8", "-", "2", "13"],
      ["-10", "_", "-2", "_", "11"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "9",
      "2-2": "1",
      "4-0": "7",
      "4-2": "8",
      "4-4": "2",
    },
    revealedNumbers: [6, 4],
  },
  "2-16": {
    gameBoard: [
      ["4", "+", "2", "-", "9", "-3"],
      ["-", "_", "-", "_", "+"],
      ["5", "-", "8", "-", "6", "-9"],
      ["-", "_", "+", "_", "-"],
      ["7", "+", "1", "+", "3", "11"],
      ["-8", "_", "-5", "_", "12"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "9",
      "2-2": "8",
      "4-0": "7",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [5, 6],
  },
  "2-17": {
    gameBoard: [
      ["5", "+", "1", "+", "7", "13"],
      ["-", "_", "-", "_", "+"],
      ["8", "-", "6", "+", "4", "6"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "3", "+", "2", "14"],
      ["6", "_", "-2", "_", "9"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "1",
      "0-4": "7",
      "2-2": "6",
      "4-0": "9",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [8, 4],
  },
  "2-18": {
    gameBoard: [
      ["8", "-", "9", "-", "4", "-5"],
      ["-", "_", "+", "_", "+"],
      ["5", "-", "6", "+", "3", "2"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "1", "+", "7", "10"],
      ["1", "_", "16", "_", "14"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "4",
      "2-2": "6",
      "4-0": "2",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [5, 3],
  },
  "2-19": {
    gameBoard: [
      ["8", "+", "1", "+", "2", "11"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "5", "+", "9", "10"],
      ["-", "_", "-", "_", "+"],
      ["3", "+", "4", "-", "7", "0"],
      ["11", "_", "-8", "_", "18"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "1",
      "0-4": "2",
      "2-2": "5",
      "4-0": "3",
      "4-2": "4",
      "4-4": "7",
    },
    revealedNumbers: [6, 9],
  },
  "2-20": {
    gameBoard: [
      ["7", "-", "6", "+", "9", "10"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "5", "+", "8", "15"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "1", "-", "3", "2"],
      ["1", "_", "12", "_", "-2"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "9",
      "2-2": "5",
      "4-0": "4",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [2, 8],
  },
  "2-21": {
    gameBoard: [
      ["9", "-", "3", "+", "6", "12"],
      ["-", "_", "+", "_", "-"],
      ["8", "-", "4", "+", "5", "9"],
      ["+", "_", "+", "_", "-"],
      ["1", "+", "2", "+", "7", "10"],
      ["2", "_", "9", "_", "-6"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "6",
      "2-2": "4",
      "4-0": "1",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [8, 5],
  },
  "2-22": {
    gameBoard: [
      ["3", "+", "2", "-", "9", "-4"],
      ["-", "_", "+", "_", "+"],
      ["5", "-", "8", "-", "1", "-4"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "6", "-", "4", "-3"],
      ["-9", "_", "4", "_", "6"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "9",
      "2-2": "8",
      "4-0": "7",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [5, 1],
  },
  "2-23": {
    gameBoard: [
      ["9", "-", "4", "-", "2", "3"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "1", "+", "6", "12"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "3", "-", "8", "-4"],
      ["-3", "_", "2", "_", "16"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "2",
      "2-2": "1",
      "4-0": "7",
      "4-2": "3",
      "4-4": "8",
    },
    revealedNumbers: [5, 6],
  },
  "2-24": {
    gameBoard: [
      ["1", "-", "9", "+", "8", "0"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "6", "-", "4", "-8"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "5", "-", "3", "-1"],
      ["-4", "_", "20", "_", "9"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "9",
      "0-4": "8",
      "2-2": "6",
      "4-0": "7",
      "4-2": "5",
      "4-4": "3",
    },
    revealedNumbers: [2, 4],
  },
  "2-25": {
    gameBoard: [
      ["1", "-", "5", "-", "6", "-10"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "3", "-", "8", "-4"],
      ["+", "_", "-", "_", "-"],
      ["2", "+", "9", "-", "4", "7"],
      ["-4", "_", "-7", "_", "-6"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "6",
      "2-2": "3",
      "4-0": "2",
      "4-2": "9",
      "4-4": "4",
    },
    revealedNumbers: [7, 8],
  },
  "2-26": {
    gameBoard: [
      ["5", "+", "2", "+", "8", "15"],
      ["-", "_", "-", "_", "-"],
      ["9", "-", "4", "+", "3", "8"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "7", "-", "6", "2"],
      ["-3", "_", "-9", "_", "-1"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "2",
      "0-4": "8",
      "2-2": "4",
      "4-0": "1",
      "4-2": "7",
      "4-4": "6",
    },
    revealedNumbers: [9, 3],
  },
  "2-27": {
    gameBoard: [
      ["9", "+", "7", "+", "2", "18"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "5", "+", "8", "4"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "3", "+", "4", "7"],
      ["14", "_", "-1", "_", "-2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "7",
      "0-4": "2",
      "2-2": "5",
      "4-0": "6",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [1, 8],
  },
  "2-28": {
    gameBoard: [
      ["8", "+", "7", "+", "1", "16"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "3", "+", "9", "12"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "2", "-", "4", "-1"],
      ["19", "_", "2", "_", "14"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "7",
      "0-4": "1",
      "2-2": "3",
      "4-0": "5",
      "4-2": "2",
      "4-4": "4",
    },
    revealedNumbers: [6, 9],
  },
  "2-29": {
    gameBoard: [
      ["2", "+", "6", "+", "1", "9"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "9", "+", "3", "-2"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "5", "-", "7", "6"],
      ["14", "_", "2", "_", "11"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "1",
      "2-2": "9",
      "4-0": "8",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [4, 3],
  },
  "2-30": {
    gameBoard: [
      ["7", "-", "5", "-", "6", "-4"],
      ["+", "_", "+", "_", "+"],
      ["9", "+", "8", "+", "4", "21"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "1", "-", "3", "-2"],
      ["18", "_", "12", "_", "7"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "5",
      "0-4": "6",
      "2-2": "8",
      "4-0": "2",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [9, 4],
  },
  "2-31": {
    gameBoard: [
      ["7", "+", "8", "-", "9", "6"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "1", "-", "2", "2"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "6", "+", "5", "3"],
      ["8", "_", "3", "_", "12"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "8",
      "0-4": "9",
      "2-2": "1",
      "4-0": "4",
      "4-2": "6",
      "4-4": "5",
    },
    revealedNumbers: [3, 2],
  },
  "3-0": {
    gameBoard: [
      ["1", "+", "2", "+", "8", "11"],
      ["+", "_", "+", "_", "+"],
      ["6", "+", "4", "-", "7", "3"],
      ["-", "_", "-", "_", "+"],
      ["3", "-", "5", "+", "9", "7"],
      ["4", "_", "1", "_", "24"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "2",
      "0-4": "8",
      "2-2": "4",
      "4-0": "3",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [6, 7],
  },
  "3-1": {
    gameBoard: [
      ["3", "+", "4", "-", "5", "2"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "1", "-", "6", "-5"],
      ["-", "_", "+", "_", "-"],
      ["8", "-", "9", "-", "7", "-8"],
      ["-7", "_", "14", "_", "4"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "5",
      "2-2": "1",
      "4-0": "8",
      "4-2": "9",
      "4-4": "7",
    },
    revealedNumbers: [2, 6],
  },
  "3-2": {
    gameBoard: [
      ["9", "-", "4", "+", "8", "13"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "3", "-", "2", "0"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "6", "-", "7", "-12"],
      ["5", "_", "1", "_", "13"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "8",
      "2-2": "3",
      "4-0": "1",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [5, 2],
  },
  "3-3": {
    gameBoard: [
      ["3", "+", "4", "+", "5", "12"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "6", "-", "9", "-7"],
      ["-", "_", "-", "_", "+"],
      ["2", "-", "7", "-", "1", "-6"],
      ["-7", "_", "3", "_", "15"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "5",
      "2-2": "6",
      "4-0": "2",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [8, 9],
  },
  "3-4": {
    gameBoard: [
      ["8", "+", "2", "+", "9", "19"],
      ["-", "_", "-", "_", "-"],
      ["3", "-", "1", "+", "4", "6"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "6", "+", "5", "6"],
      ["-2", "_", "7", "_", "0"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "2",
      "0-4": "9",
      "2-2": "1",
      "4-0": "7",
      "4-2": "6",
      "4-4": "5",
    },
    revealedNumbers: [3, 4],
  },
  "3-5": {
    gameBoard: [
      ["7", "-", "5", "+", "1", "3"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "8", "-", "9", "-14"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "6", "-", "2", "-4"],
      ["0", "_", "7", "_", "12"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "5",
      "0-4": "1",
      "2-2": "8",
      "4-0": "4",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [3, 9],
  },
  "3-6": {
    gameBoard: [
      ["4", "-", "1", "-", "5", "-2"],
      ["-", "_", "-", "_", "+"],
      ["6", "+", "7", "-", "2", "11"],
      ["-", "_", "+", "_", "+"],
      ["9", "-", "3", "+", "8", "14"],
      ["-11", "_", "-3", "_", "15"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "5",
      "2-2": "7",
      "4-0": "9",
      "4-2": "3",
      "4-4": "8",
    },
    revealedNumbers: [6, 2],
  },
  "3-7": {
    gameBoard: [
      ["3", "-", "5", "+", "7", "5"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "4", "-", "1", "3"],
      ["-", "_", "+", "_", "+"],
      ["6", "-", "2", "+", "9", "13"],
      ["-11", "_", "11", "_", "17"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "7",
      "2-2": "4",
      "4-0": "6",
      "4-2": "2",
      "4-4": "9",
    },
    revealedNumbers: [8, 1],
  },
  "3-8": {
    gameBoard: [
      ["5", "-", "7", "-", "8", "-10"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "6", "-", "1", "14"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "4", "-", "3", "-5"],
      ["16", "_", "-3", "_", "6"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "8",
      "2-2": "6",
      "4-0": "2",
      "4-2": "4",
      "4-4": "3",
    },
    revealedNumbers: [9, 1],
  },
  "3-9": {
    gameBoard: [
      ["3", "+", "9", "+", "2", "14"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "5", "-", "1", "1"],
      ["-", "_", "+", "_", "-"],
      ["8", "+", "6", "+", "4", "18"],
      ["2", "_", "20", "_", "-1"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "9",
      "0-4": "2",
      "2-2": "5",
      "4-0": "8",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [7, 1],
  },
  "3-10": {
    gameBoard: [
      ["4", "-", "8", "+", "1", "-3"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "3", "+", "5", "8"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "7", "+", "9", "18"],
      ["12", "_", "18", "_", "5"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "1",
      "2-2": "3",
      "4-0": "2",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [6, 5],
  },
  "3-11": {
    gameBoard: [
      ["6", "-", "1", "+", "9", "14"],
      ["+", "_", "+", "_", "-"],
      ["4", "+", "8", "+", "3", "15"],
      ["+", "_", "-", "_", "+"],
      ["7", "-", "2", "-", "5", "0"],
      ["17", "_", "7", "_", "11"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "9",
      "2-2": "8",
      "4-0": "7",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [4, 3],
  },
  "3-12": {
    gameBoard: [
      ["4", "-", "5", "+", "6", "5"],
      ["-", "_", "+", "_", "-"],
      ["8", "-", "7", "-", "1", "0"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "3", "-", "9", "-4"],
      ["-2", "_", "15", "_", "14"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "6",
      "2-2": "7",
      "4-0": "2",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [8, 1],
  },
  "3-13": {
    gameBoard: [
      ["6", "+", "8", "-", "9", "5"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "2", "+", "1", "4"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "7", "-", "3", "-6"],
      ["7", "_", "3", "_", "11"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "8",
      "0-4": "9",
      "2-2": "2",
      "4-0": "4",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [5, 1],
  },
  "3-14": {
    gameBoard: [
      ["2", "-", "4", "-", "8", "-10"],
      ["+", "_", "+", "_", "-"],
      ["6", "+", "3", "-", "9", "0"],
      ["+", "_", "+", "_", "+"],
      ["1", "-", "5", "+", "7", "3"],
      ["9", "_", "12", "_", "6"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "8",
      "2-2": "3",
      "4-0": "1",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [6, 9],
  },
  "3-15": {
    gameBoard: [
      ["4", "-", "1", "+", "2", "5"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "6", "+", "8", "23"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "3", "-", "5", "5"],
      ["6", "_", "-2", "_", "15"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "2",
      "2-2": "6",
      "4-0": "7",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [9, 8],
  },
  "3-16": {
    gameBoard: [
      ["8", "-", "2", "-", "1", "5"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "5", "+", "4", "6"],
      ["-", "_", "-", "_", "+"],
      ["6", "-", "9", "-", "3", "-6"],
      ["9", "_", "-2", "_", "8"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "2",
      "0-4": "1",
      "2-2": "5",
      "4-0": "6",
      "4-2": "9",
      "4-4": "3",
    },
    revealedNumbers: [7, 4],
  },
  "3-17": {
    gameBoard: [
      ["4", "+", "6", "-", "2", "8"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "7", "+", "3", "11"],
      ["-", "_", "-", "_", "+"],
      ["9", "-", "5", "+", "8", "12"],
      ["-4", "_", "8", "_", "13"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "2",
      "2-2": "7",
      "4-0": "9",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [1, 3],
  },
  "3-18": {
    gameBoard: [
      ["3", "-", "5", "-", "1", "-3"],
      ["+", "_", "-", "_", "-"],
      ["6", "-", "9", "-", "8", "-11"],
      ["+", "_", "+", "_", "+"],
      ["7", "+", "4", "+", "2", "13"],
      ["16", "_", "0", "_", "-5"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "1",
      "2-2": "9",
      "4-0": "7",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [6, 8],
  },
  "3-19": {
    gameBoard: [
      ["9", "+", "7", "+", "2", "18"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "6", "+", "3", "2"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "1", "+", "4", "11"],
      ["-4", "_", "12", "_", "-5"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "7",
      "0-4": "2",
      "2-2": "6",
      "4-0": "8",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [5, 3],
  },
  "3-20": {
    gameBoard: [
      ["5", "+", "7", "+", "2", "14"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "8", "+", "6", "2"],
      ["-", "_", "+", "_", "-"],
      ["1", "+", "3", "-", "9", "-5"],
      ["8", "_", "2", "_", "-13"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "2",
      "2-2": "8",
      "4-0": "1",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [4, 6],
  },
  "3-21": {
    gameBoard: [
      ["3", "-", "2", "-", "7", "-6"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "6", "-", "9", "-7"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "1", "+", "5", "10"],
      ["-1", "_", "-5", "_", "-7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "7",
      "2-2": "6",
      "4-0": "4",
      "4-2": "1",
      "4-4": "5",
    },
    revealedNumbers: [8, 9],
  },
  "3-22": {
    gameBoard: [
      ["1", "-", "7", "+", "6", "0"],
      ["+", "_", "+", "_", "+"],
      ["9", "-", "3", "-", "8", "-2"],
      ["-", "_", "-", "_", "-"],
      ["4", "-", "2", "+", "5", "7"],
      ["6", "_", "8", "_", "9"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "7",
      "0-4": "6",
      "2-2": "3",
      "4-0": "4",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [9, 8],
  },
  "3-23": {
    gameBoard: [
      ["2", "+", "9", "-", "4", "7"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "5", "+", "8", "14"],
      ["-", "_", "+", "_", "-"],
      ["6", "-", "3", "+", "7", "10"],
      ["-3", "_", "7", "_", "-11"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "9",
      "0-4": "4",
      "2-2": "5",
      "4-0": "6",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [1, 8],
  },
  "3-24": {
    gameBoard: [
      ["5", "+", "8", "+", "9", "22"],
      ["-", "_", "+", "_", "+"],
      ["7", "-", "3", "-", "4", "0"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "2", "+", "6", "9"],
      ["-1", "_", "9", "_", "7"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "9",
      "2-2": "3",
      "4-0": "1",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [7, 4],
  },
  "3-25": {
    gameBoard: [
      ["7", "+", "9", "-", "5", "11"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "2", "+", "6", "7"],
      ["+", "_", "+", "_", "+"],
      ["1", "-", "8", "-", "4", "-11"],
      ["5", "_", "19", "_", "15"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "5",
      "2-2": "2",
      "4-0": "1",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [3, 6],
  },
  "3-26": {
    gameBoard: [
      ["8", "-", "4", "-", "9", "-5"],
      ["-", "_", "-", "_", "+"],
      ["6", "+", "3", "-", "2", "7"],
      ["+", "_", "-", "_", "+"],
      ["5", "+", "7", "+", "1", "13"],
      ["7", "_", "-6", "_", "12"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "4",
      "0-4": "9",
      "2-2": "3",
      "4-0": "5",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [6, 2],
  },
  "3-27": {
    gameBoard: [
      ["7", "+", "6", "+", "9", "22"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "8", "+", "4", "13"],
      ["-", "_", "-", "_", "-"],
      ["3", "+", "5", "-", "2", "6"],
      ["5", "_", "-7", "_", "3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "9",
      "2-2": "8",
      "4-0": "3",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [1, 4],
  },
  "3-28": {
    gameBoard: [
      ["6", "-", "4", "+", "5", "7"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "3", "-", "7", "4"],
      ["-", "_", "+", "_", "-"],
      ["1", "-", "9", "+", "2", "-6"],
      ["13", "_", "16", "_", "-4"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "5",
      "2-2": "3",
      "4-0": "1",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [8, 7],
  },
  "3-29": {
    gameBoard: [
      ["1", "+", "9", "+", "8", "18"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "6", "+", "7", "5"],
      ["+", "_", "-", "_", "-"],
      ["2", "+", "5", "+", "3", "10"],
      ["7", "_", "-2", "_", "-2"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "9",
      "0-4": "8",
      "2-2": "6",
      "4-0": "2",
      "4-2": "5",
      "4-4": "3",
    },
    revealedNumbers: [4, 7],
  },
  "3-30": {
    gameBoard: [
      ["7", "-", "9", "-", "6", "-8"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "3", "+", "1", "2"],
      ["+", "_", "+", "_", "-"],
      ["2", "-", "5", "-", "8", "-11"],
      ["13", "_", "17", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "6",
      "2-2": "3",
      "4-0": "2",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [4, 1],
  },
  "3-31": {
    gameBoard: [
      ["8", "+", "9", "+", "7", "24"],
      ["-", "_", "+", "_", "-"],
      ["6", "-", "3", "-", "4", "-1"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "2", "+", "5", "8"],
      ["1", "_", "14", "_", "8"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "7",
      "2-2": "3",
      "4-0": "1",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [6, 4],
  },
  "4-0": {
    gameBoard: [
      ["8", "-", "3", "+", "1", "6"],
      ["+", "_", "-", "_", "-"],
      ["7", "-", "4", "+", "2", "5"],
      ["-", "_", "+", "_", "-"],
      ["6", "+", "9", "+", "5", "20"],
      ["9", "_", "8", "_", "-6"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "3",
      "0-4": "1",
      "2-2": "4",
      "4-0": "6",
      "4-2": "9",
      "4-4": "5",
    },
    revealedNumbers: [7, 2],
  },
  "4-1": {
    gameBoard: [
      ["9", "-", "3", "+", "1", "7"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "8", "+", "6", "19"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "2", "+", "7", "9"],
      ["0", "_", "13", "_", "0"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "1",
      "2-2": "8",
      "4-0": "4",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [5, 6],
  },
  "4-2": {
    gameBoard: [
      ["2", "+", "3", "+", "8", "13"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "1", "+", "4", "9"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "5", "+", "9", "21"],
      ["15", "_", "-1", "_", "3"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "8",
      "2-2": "1",
      "4-0": "7",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [6, 4],
  },
  "4-3": {
    gameBoard: [
      ["5", "-", "8", "+", "9", "6"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "4", "-", "6", "-3"],
      ["+", "_", "+", "_", "-"],
      ["3", "+", "1", "+", "2", "6"],
      ["1", "_", "5", "_", "13"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "9",
      "2-2": "4",
      "4-0": "3",
      "4-2": "1",
      "4-4": "2",
    },
    revealedNumbers: [7, 6],
  },
  "4-4": {
    gameBoard: [
      ["9", "-", "3", "+", "7", "13"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "8", "-", "2", "-9"],
      ["+", "_", "-", "_", "-"],
      ["5", "-", "4", "-", "6", "-5"],
      ["15", "_", "7", "_", "-1"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "7",
      "2-2": "8",
      "4-0": "5",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [1, 2],
  },
  "4-5": {
    gameBoard: [
      ["1", "-", "3", "+", "6", "4"],
      ["-", "_", "-", "_", "-"],
      ["4", "-", "8", "-", "5", "-9"],
      ["+", "_", "-", "_", "-"],
      ["7", "-", "9", "-", "2", "-4"],
      ["4", "_", "-14", "_", "-1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "6",
      "2-2": "8",
      "4-0": "7",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [4, 5],
  },
  "4-6": {
    gameBoard: [
      ["5", "-", "8", "-", "1", "-4"],
      ["+", "_", "-", "_", "+"],
      ["2", "-", "4", "+", "3", "1"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "9", "-", "7", "8"],
      ["13", "_", "-5", "_", "11"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "1",
      "2-2": "4",
      "4-0": "6",
      "4-2": "9",
      "4-4": "7",
    },
    revealedNumbers: [2, 3],
  },
  "4-7": {
    gameBoard: [
      ["4", "-", "7", "+", "9", "6"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "1", "-", "6", "1"],
      ["-", "_", "+", "_", "-"],
      ["2", "-", "3", "+", "5", "4"],
      ["-6", "_", "9", "_", "-2"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "7",
      "0-4": "9",
      "2-2": "1",
      "4-0": "2",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [8, 6],
  },
  "4-8": {
    gameBoard: [
      ["8", "+", "6", "+", "7", "21"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "1", "-", "5", "-2"],
      ["-", "_", "-", "_", "-"],
      ["3", "+", "9", "-", "2", "10"],
      ["9", "_", "-2", "_", "10"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "7",
      "2-2": "1",
      "4-0": "3",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [4, 5],
  },
  "4-9": {
    gameBoard: [
      ["1", "+", "6", "-", "7", "0"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "3", "+", "9", "8"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "8", "+", "5", "17"],
      ["7", "_", "-5", "_", "-7"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "6",
      "0-4": "7",
      "2-2": "3",
      "4-0": "4",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [2, 9],
  },
  "4-10": {
    gameBoard: [
      ["3", "+", "1", "-", "4", "0"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "7", "-", "5", "-6"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "2", "-", "9", "-3"],
      ["1", "_", "10", "_", "18"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "4",
      "2-2": "7",
      "4-0": "8",
      "4-2": "2",
      "4-4": "9",
    },
    revealedNumbers: [6, 5],
  },
  "4-11": {
    gameBoard: [
      ["3", "-", "8", "-", "6", "-11"],
      ["-", "_", "+", "_", "+"],
      ["4", "+", "1", "-", "7", "-2"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "5", "+", "2", "16"],
      ["8", "_", "4", "_", "11"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "8",
      "0-4": "6",
      "2-2": "1",
      "4-0": "9",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [4, 7],
  },
  "4-12": {
    gameBoard: [
      ["3", "-", "2", "-", "5", "-4"],
      ["-", "_", "-", "_", "+"],
      ["1", "-", "8", "-", "4", "-11"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "7", "+", "6", "22"],
      ["-7", "_", "1", "_", "3"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "5",
      "2-2": "8",
      "4-0": "9",
      "4-2": "7",
      "4-4": "6",
    },
    revealedNumbers: [1, 4],
  },
  "4-13": {
    gameBoard: [
      ["7", "+", "2", "-", "6", "3"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "3", "+", "4", "8"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "8", "-", "9", "4"],
      ["3", "_", "7", "_", "19"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "6",
      "2-2": "3",
      "4-0": "5",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [1, 4],
  },
  "4-14": {
    gameBoard: [
      ["6", "+", "7", "+", "9", "22"],
      ["-", "_", "+", "_", "+"],
      ["4", "-", "8", "+", "3", "-1"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "5", "+", "1", "8"],
      ["0", "_", "20", "_", "11"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "9",
      "2-2": "8",
      "4-0": "2",
      "4-2": "5",
      "4-4": "1",
    },
    revealedNumbers: [4, 3],
  },
  "4-15": {
    gameBoard: [
      ["3", "+", "6", "+", "5", "14"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "2", "-", "9", "-3"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "1", "-", "7", "-4"],
      ["-1", "_", "7", "_", "21"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "5",
      "2-2": "2",
      "4-0": "4",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [8, 9],
  },
  "4-16": {
    gameBoard: [
      ["4", "-", "1", "-", "5", "-2"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "8", "+", "6", "0"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "9", "-", "3", "-5"],
      ["-5", "_", "-16", "_", "-4"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "5",
      "2-2": "8",
      "4-0": "7",
      "4-2": "9",
      "4-4": "3",
    },
    revealedNumbers: [2, 6],
  },
  "4-17": {
    gameBoard: [
      ["4", "-", "6", "+", "7", "5"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "9", "+", "1", "12"],
      ["-", "_", "-", "_", "-"],
      ["5", "-", "3", "+", "8", "10"],
      ["-3", "_", "12", "_", "0"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "7",
      "2-2": "9",
      "4-0": "5",
      "4-2": "3",
      "4-4": "8",
    },
    revealedNumbers: [2, 1],
  },
  "4-18": {
    gameBoard: [
      ["5", "-", "8", "-", "6", "-9"],
      ["-", "_", "-", "_", "+"],
      ["2", "+", "4", "+", "1", "7"],
      ["+", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "3", "19"],
      ["10", "_", "-5", "_", "10"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "6",
      "2-2": "4",
      "4-0": "7",
      "4-2": "9",
      "4-4": "3",
    },
    revealedNumbers: [2, 1],
  },
  "4-19": {
    gameBoard: [
      ["1", "+", "6", "+", "3", "10"],
      ["-", "_", "+", "_", "+"],
      ["5", "-", "7", "-", "8", "-10"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "2", "+", "4", "11"],
      ["5", "_", "15", "_", "7"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "6",
      "0-4": "3",
      "2-2": "7",
      "4-0": "9",
      "4-2": "2",
      "4-4": "4",
    },
    revealedNumbers: [5, 8],
  },
  "4-20": {
    gameBoard: [
      ["8", "-", "4", "-", "7", "-3"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "2", "+", "3", "14"],
      ["+", "_", "-", "_", "-"],
      ["5", "+", "1", "-", "6", "0"],
      ["4", "_", "5", "_", "-2"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "4",
      "0-4": "7",
      "2-2": "2",
      "4-0": "5",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [9, 3],
  },
  "4-21": {
    gameBoard: [
      ["6", "-", "8", "+", "2", "0"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "1", "-", "4", "4"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "3", "-", "9", "-1"],
      ["-6", "_", "12", "_", "-3"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "8",
      "0-4": "2",
      "2-2": "1",
      "4-0": "5",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [7, 4],
  },
  "4-22": {
    gameBoard: [
      ["2", "+", "8", "+", "9", "19"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "6", "-", "7", "-12"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "3", "+", "4", "6"],
      ["8", "_", "11", "_", "6"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "9",
      "2-2": "6",
      "4-0": "5",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [1, 7],
  },
  "4-23": {
    gameBoard: [
      ["4", "+", "5", "+", "8", "17"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "2", "+", "6", "11"],
      ["-", "_", "-", "_", "+"],
      ["3", "+", "9", "+", "1", "13"],
      ["-6", "_", "-6", "_", "15"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "8",
      "2-2": "2",
      "4-0": "3",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [7, 6],
  },
  "4-24": {
    gameBoard: [
      ["8", "+", "2", "+", "5", "15"],
      ["-", "_", "+", "_", "-"],
      ["7", "+", "1", "-", "4", "4"],
      ["+", "_", "+", "_", "+"],
      ["6", "+", "3", "-", "9", "0"],
      ["7", "_", "6", "_", "10"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "2",
      "0-4": "5",
      "2-2": "1",
      "4-0": "6",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [7, 4],
  },
  "4-25": {
    gameBoard: [
      ["2", "-", "4", "+", "6", "4"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "5", "+", "7", "5"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "9", "+", "8", "18"],
      ["6", "_", "-10", "_", "7"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "6",
      "2-2": "5",
      "4-0": "1",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [3, 7],
  },
  "4-26": {
    gameBoard: [
      ["9", "-", "8", "+", "2", "3"],
      ["+", "_", "+", "_", "+"],
      ["3", "+", "5", "-", "4", "4"],
      ["-", "_", "+", "_", "+"],
      ["1", "-", "6", "+", "7", "2"],
      ["11", "_", "19", "_", "13"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "2",
      "2-2": "5",
      "4-0": "1",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [3, 4],
  },
  "4-27": {
    gameBoard: [
      ["4", "+", "6", "+", "3", "13"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "2", "-", "8", "-5"],
      ["-", "_", "+", "_", "-"],
      ["1", "+", "7", "-", "9", "-1"],
      ["8", "_", "15", "_", "-14"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "3",
      "2-2": "2",
      "4-0": "1",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [5, 8],
  },
  "4-28": {
    gameBoard: [
      ["5", "-", "3", "-", "2", "0"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "9", "+", "8", "6"],
      ["-", "_", "-", "_", "-"],
      ["6", "+", "4", "+", "1", "11"],
      ["6", "_", "8", "_", "-7"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "2",
      "2-2": "9",
      "4-0": "6",
      "4-2": "4",
      "4-4": "1",
    },
    revealedNumbers: [7, 8],
  },
  "4-29": {
    gameBoard: [
      ["9", "+", "4", "-", "6", "7"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "8", "-", "7", "-10"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "3", "+", "1", "6"],
      ["2", "_", "15", "_", "-2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "6",
      "2-2": "8",
      "4-0": "2",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [5, 7],
  },
  "4-30": {
    gameBoard: [
      ["3", "-", "5", "+", "8", "6"],
      ["+", "_", "-", "_", "+"],
      ["7", "-", "2", "+", "6", "11"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "9", "-", "1", "-6"],
      ["6", "_", "12", "_", "13"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "8",
      "2-2": "2",
      "4-0": "4",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [7, 6],
  },
  "4-31": {
    gameBoard: [
      ["9", "+", "5", "+", "3", "17"],
      ["-", "_", "+", "_", "-"],
      ["2", "-", "8", "-", "7", "-13"],
      ["+", "_", "-", "_", "-"],
      ["1", "-", "6", "+", "4", "-1"],
      ["8", "_", "7", "_", "-8"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "5",
      "0-4": "3",
      "2-2": "8",
      "4-0": "1",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [2, 7],
  },
  "5-0": {
    gameBoard: [
      ["2", "+", "3", "+", "1", "6"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "5", "+", "9", "22"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "6", "+", "4", "17"],
      ["-13", "_", "2", "_", "14"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "1",
      "2-2": "5",
      "4-0": "7",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [8, 9],
  },
  "5-1": {
    gameBoard: [
      ["8", "-", "7", "+", "9", "10"],
      ["+", "_", "-", "_", "+"],
      ["3", "+", "2", "+", "5", "10"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "1", "-", "6", "-3"],
      ["7", "_", "4", "_", "20"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "7",
      "0-4": "9",
      "2-2": "2",
      "4-0": "4",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [3, 5],
  },
  "5-2": {
    gameBoard: [
      ["2", "+", "3", "+", "7", "12"],
      ["+", "_", "-", "_", "+"],
      ["9", "-", "4", "-", "1", "4"],
      ["+", "_", "+", "_", "+"],
      ["6", "+", "5", "-", "8", "3"],
      ["17", "_", "4", "_", "16"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "7",
      "2-2": "4",
      "4-0": "6",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [9, 1],
  },
  "5-3": {
    gameBoard: [
      ["2", "+", "5", "+", "1", "8"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "6", "22"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "3", "+", "4", "9"],
      ["3", "_", "-7", "_", "11"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "5",
      "0-4": "1",
      "2-2": "9",
      "4-0": "8",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [7, 6],
  },
  "5-4": {
    gameBoard: [
      ["1", "+", "3", "-", "4", "0"],
      ["-", "_", "-", "_", "+"],
      ["5", "-", "6", "+", "7", "6"],
      ["-", "_", "-", "_", "-"],
      ["8", "+", "9", "-", "2", "15"],
      ["-12", "_", "-12", "_", "9"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "4",
      "2-2": "6",
      "4-0": "8",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [5, 7],
  },
  "5-5": {
    gameBoard: [
      ["2", "-", "8", "+", "5", "-1"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "9", "-", "3", "-8"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "6", "+", "1", "2"],
      ["-1", "_", "-7", "_", "9"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "5",
      "2-2": "9",
      "4-0": "7",
      "4-2": "6",
      "4-4": "1",
    },
    revealedNumbers: [4, 3],
  },
  "5-6": {
    gameBoard: [
      ["4", "-", "1", "+", "6", "9"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "7", "-", "8", "1"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "3", "-", "5", "7"],
      ["15", "_", "5", "_", "9"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "6",
      "2-2": "7",
      "4-0": "9",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [2, 8],
  },
  "5-7": {
    gameBoard: [
      ["3", "+", "9", "-", "4", "8"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "8", "+", "2", "0"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "7", "-", "1", "-3"],
      ["14", "_", "-6", "_", "7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "9",
      "0-4": "4",
      "2-2": "8",
      "4-0": "5",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [6, 2],
  },
  "5-8": {
    gameBoard: [
      ["7", "-", "3", "+", "4", "8"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "6", "+", "5", "13"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "9", "-", "1", "16"],
      ["13", "_", "18", "_", "8"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "3",
      "0-4": "4",
      "2-2": "6",
      "4-0": "8",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [2, 5],
  },
  "5-9": {
    gameBoard: [
      ["2", "+", "5", "+", "3", "10"],
      ["-", "_", "-", "_", "+"],
      ["1", "+", "8", "+", "6", "15"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "4", "+", "7", "20"],
      ["-8", "_", "1", "_", "2"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "5",
      "0-4": "3",
      "2-2": "8",
      "4-0": "9",
      "4-2": "4",
      "4-4": "7",
    },
    revealedNumbers: [1, 6],
  },
  "5-10": {
    gameBoard: [
      ["5", "-", "9", "+", "1", "-3"],
      ["-", "_", "+", "_", "+"],
      ["6", "-", "2", "+", "7", "11"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "3", "+", "4", "9"],
      ["-9", "_", "14", "_", "12"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "1",
      "2-2": "2",
      "4-0": "8",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [6, 7],
  },
  "5-11": {
    gameBoard: [
      ["8", "-", "1", "+", "2", "9"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "4", "+", "9", "12"],
      ["-", "_", "-", "_", "-"],
      ["6", "-", "3", "+", "5", "8"],
      ["-5", "_", "2", "_", "-12"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "1",
      "0-4": "2",
      "2-2": "4",
      "4-0": "6",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [7, 9],
  },
  "5-12": {
    gameBoard: [
      ["9", "+", "3", "-", "6", "6"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "4", "-", "2", "7"],
      ["+", "_", "-", "_", "-"],
      ["7", "-", "8", "+", "1", "0"],
      ["11", "_", "-9", "_", "7"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "3",
      "0-4": "6",
      "2-2": "4",
      "4-0": "7",
      "4-2": "8",
      "4-4": "1",
    },
    revealedNumbers: [5, 2],
  },
  "5-13": {
    gameBoard: [
      ["3", "-", "1", "+", "5", "7"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "4", "+", "8", "13"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "2", "+", "6", "15"],
      ["5", "_", "-5", "_", "3"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "5",
      "2-2": "4",
      "4-0": "7",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [9, 8],
  },
  "5-14": {
    gameBoard: [
      ["3", "-", "4", "-", "5", "-6"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "1", "+", "2", "11"],
      ["+", "_", "-", "_", "+"],
      ["7", "+", "6", "-", "9", "4"],
      ["18", "_", "-1", "_", "12"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "5",
      "2-2": "1",
      "4-0": "7",
      "4-2": "6",
      "4-4": "9",
    },
    revealedNumbers: [8, 2],
  },
  "5-15": {
    gameBoard: [
      ["9", "-", "4", "-", "3", "2"],
      ["-", "_", "-", "_", "+"],
      ["2", "-", "8", "+", "6", "0"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "1", "+", "7", "13"],
      ["12", "_", "-3", "_", "2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "3",
      "2-2": "8",
      "4-0": "5",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [2, 6],
  },
  "5-16": {
    gameBoard: [
      ["2", "-", "7", "+", "4", "-1"],
      ["-", "_", "-", "_", "-"],
      ["6", "+", "8", "-", "9", "5"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "3", "-", "5", "-7"],
      ["-3", "_", "2", "_", "-10"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "7",
      "0-4": "4",
      "2-2": "8",
      "4-0": "1",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [6, 9],
  },
  "5-17": {
    gameBoard: [
      ["1", "-", "9", "+", "6", "-2"],
      ["-", "_", "+", "_", "-"],
      ["3", "-", "2", "+", "8", "9"],
      ["-", "_", "-", "_", "-"],
      ["4", "+", "7", "-", "5", "6"],
      ["-6", "_", "4", "_", "-7"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "9",
      "0-4": "6",
      "2-2": "2",
      "4-0": "4",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [3, 8],
  },
  "5-18": {
    gameBoard: [
      ["8", "+", "7", "-", "2", "13"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "6", "+", "9", "20"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "4", "+", "3", "8"],
      ["4", "_", "9", "_", "-4"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "7",
      "0-4": "2",
      "2-2": "6",
      "4-0": "1",
      "4-2": "4",
      "4-4": "3",
    },
    revealedNumbers: [5, 9],
  },
  "5-19": {
    gameBoard: [
      ["6", "+", "9", "+", "3", "18"],
      ["+", "_", "-", "_", "+"],
      ["7", "-", "2", "-", "8", "-3"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "4", "-", "5", "0"],
      ["14", "_", "3", "_", "16"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "9",
      "0-4": "3",
      "2-2": "2",
      "4-0": "1",
      "4-2": "4",
      "4-4": "5",
    },
    revealedNumbers: [7, 8],
  },
  "5-20": {
    gameBoard: [
      ["8", "-", "1", "-", "9", "-2"],
      ["-", "_", "+", "_", "-"],
      ["6", "+", "7", "+", "3", "16"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "4", "-", "2", "-1"],
      ["7", "_", "12", "_", "4"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "1",
      "0-4": "9",
      "2-2": "7",
      "4-0": "5",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [6, 3],
  },
  "5-21": {
    gameBoard: [
      ["5", "+", "2", "+", "3", "10"],
      ["+", "_", "+", "_", "-"],
      ["4", "+", "7", "-", "1", "10"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "6", "+", "9", "11"],
      ["17", "_", "3", "_", "-7"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "2",
      "0-4": "3",
      "2-2": "7",
      "4-0": "8",
      "4-2": "6",
      "4-4": "9",
    },
    revealedNumbers: [4, 1],
  },
  "5-22": {
    gameBoard: [
      ["3", "-", "2", "+", "7", "8"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "5", "-", "9", "4"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "4", "+", "6", "3"],
      ["12", "_", "3", "_", "22"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "7",
      "2-2": "5",
      "4-0": "1",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [8, 9],
  },
  "5-23": {
    gameBoard: [
      ["3", "+", "6", "-", "1", "8"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "5", "+", "2", "14"],
      ["+", "_", "-", "_", "-"],
      ["8", "+", "9", "-", "4", "13"],
      ["4", "_", "2", "_", "-1"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "1",
      "2-2": "5",
      "4-0": "8",
      "4-2": "9",
      "4-4": "4",
    },
    revealedNumbers: [7, 2],
  },
  "5-24": {
    gameBoard: [
      ["5", "-", "9", "-", "8", "-12"],
      ["-", "_", "+", "_", "+"],
      ["4", "+", "3", "+", "7", "14"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "6", "-", "2", "5"],
      ["2", "_", "6", "_", "13"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "8",
      "2-2": "3",
      "4-0": "1",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [4, 7],
  },
  "5-25": {
    gameBoard: [
      ["1", "+", "6", "+", "2", "9"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "9", "+", "5", "22"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "3", "+", "7", "14"],
      ["13", "_", "-6", "_", "0"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "6",
      "0-4": "2",
      "2-2": "9",
      "4-0": "4",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [8, 5],
  },
  "5-26": {
    gameBoard: [
      ["1", "-", "5", "-", "2", "-6"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "6", "+", "3", "18"],
      ["-", "_", "-", "_", "+"],
      ["4", "+", "7", "-", "8", "3"],
      ["-12", "_", "4", "_", "7"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "2",
      "2-2": "6",
      "4-0": "4",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [9, 3],
  },
  "5-27": {
    gameBoard: [
      ["5", "+", "9", "-", "2", "12"],
      ["-", "_", "+", "_", "+"],
      ["6", "-", "4", "+", "7", "9"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "3", "-", "1", "4"],
      ["7", "_", "10", "_", "8"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "2",
      "2-2": "4",
      "4-0": "8",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [6, 7],
  },
  "5-28": {
    gameBoard: [
      ["1", "-", "5", "-", "8", "-12"],
      ["-", "_", "-", "_", "+"],
      ["3", "-", "6", "-", "9", "-12"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "4", "-", "2", "9"],
      ["-9", "_", "-5", "_", "15"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "8",
      "2-2": "6",
      "4-0": "7",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [3, 9],
  },
  "5-29": {
    gameBoard: [
      ["4", "-", "7", "+", "6", "3"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "2", "+", "1", "12"],
      ["-", "_", "-", "_", "+"],
      ["3", "-", "8", "+", "5", "0"],
      ["10", "_", "-3", "_", "10"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "7",
      "0-4": "6",
      "2-2": "2",
      "4-0": "3",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [9, 1],
  },
  "5-30": {
    gameBoard: [
      ["4", "-", "5", "-", "2", "-3"],
      ["-", "_", "-", "_", "+"],
      ["3", "-", "1", "+", "7", "9"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "8", "-", "6", "11"],
      ["10", "_", "-4", "_", "3"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "2",
      "2-2": "1",
      "4-0": "9",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [3, 7],
  },
  "5-31": {
    gameBoard: [
      ["4", "-", "2", "-", "1", "1"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "6", "-", "3", "-2"],
      ["-", "_", "-", "_", "-"],
      ["5", "-", "9", "-", "8", "-12"],
      ["-8", "_", "-13", "_", "-10"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "1",
      "2-2": "6",
      "4-0": "5",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [7, 3],
  },
  "6-0": {
    gameBoard: [
      ["7", "-", "6", "+", "4", "5"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "2", "+", "9", "14"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "5", "+", "8", "4"],
      ["5", "_", "3", "_", "21"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "4",
      "2-2": "2",
      "4-0": "1",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [3, 9],
  },
  "6-1": {
    gameBoard: [
      ["7", "-", "3", "-", "1", "3"],
      ["-", "_", "-", "_", "-"],
      ["9", "+", "8", "-", "2", "15"],
      ["-", "_", "+", "_", "+"],
      ["5", "-", "6", "-", "4", "-5"],
      ["-7", "_", "1", "_", "3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "3",
      "0-4": "1",
      "2-2": "8",
      "4-0": "5",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [9, 2],
  },
  "6-2": {
    gameBoard: [
      ["9", "+", "1", "-", "8", "2"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "2", "-", "7", "-5"],
      ["-", "_", "-", "_", "+"],
      ["6", "-", "3", "-", "5", "-2"],
      ["7", "_", "0", "_", "6"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "8",
      "2-2": "2",
      "4-0": "6",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [4, 7],
  },
  "6-3": {
    gameBoard: [
      ["3", "-", "4", "-", "1", "-2"],
      ["+", "_", "+", "_", "-"],
      ["2", "+", "8", "-", "9", "1"],
      ["-", "_", "-", "_", "-"],
      ["5", "+", "6", "+", "7", "18"],
      ["0", "_", "6", "_", "-15"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "1",
      "2-2": "8",
      "4-0": "5",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [2, 9],
  },
  "6-4": {
    gameBoard: [
      ["6", "+", "9", "-", "4", "11"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "5", "-", "8", "0"],
      ["-", "_", "-", "_", "+"],
      ["1", "-", "2", "+", "7", "6"],
      ["8", "_", "2", "_", "3"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "9",
      "0-4": "4",
      "2-2": "5",
      "4-0": "1",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [3, 8],
  },
  "6-5": {
    gameBoard: [
      ["8", "+", "6", "-", "4", "10"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "1", "-", "3", "5"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "9", "-", "5", "-12"],
      ["17", "_", "-2", "_", "-4"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "4",
      "2-2": "1",
      "4-0": "2",
      "4-2": "9",
      "4-4": "5",
    },
    revealedNumbers: [7, 3],
  },
  "6-6": {
    gameBoard: [
      ["2", "-", "4", "+", "9", "7"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "1", "+", "5", "10"],
      ["+", "_", "-", "_", "-"],
      ["8", "+", "7", "+", "3", "18"],
      ["16", "_", "-2", "_", "1"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "9",
      "2-2": "1",
      "4-0": "8",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [6, 5],
  },
  "6-7": {
    gameBoard: [
      ["1", "-", "5", "+", "9", "5"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "7", "-", "3", "-8"],
      ["-", "_", "-", "_", "-"],
      ["4", "+", "8", "-", "6", "6"],
      ["-5", "_", "4", "_", "6"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "9",
      "2-2": "7",
      "4-0": "4",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [2, 3],
  },
  "6-8": {
    gameBoard: [
      ["7", "-", "3", "+", "1", "5"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "6", "-", "9", "-1"],
      ["-", "_", "-", "_", "+"],
      ["4", "+", "8", "+", "5", "17"],
      ["1", "_", "1", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "3",
      "0-4": "1",
      "2-2": "6",
      "4-0": "4",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [2, 9],
  },
  "6-9": {
    gameBoard: [
      ["5", "-", "3", "-", "6", "-4"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "4", "-", "7", "-2"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "9", "-", "2", "-3"],
      ["12", "_", "16", "_", "15"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "6",
      "2-2": "4",
      "4-0": "8",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [1, 7],
  },
  "6-10": {
    gameBoard: [
      ["3", "+", "7", "+", "5", "15"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "1", "+", "2", "10"],
      ["-", "_", "-", "_", "+"],
      ["6", "-", "4", "+", "8", "10"],
      ["-12", "_", "4", "_", "11"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "5",
      "2-2": "1",
      "4-0": "6",
      "4-2": "4",
      "4-4": "8",
    },
    revealedNumbers: [9, 2],
  },
  "6-11": {
    gameBoard: [
      ["9", "+", "4", "-", "3", "10"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "8", "-", "6", "7"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "2", "-", "7", "-4"],
      ["5", "_", "-6", "_", "2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "3",
      "2-2": "8",
      "4-0": "1",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [5, 6],
  },
  "6-12": {
    gameBoard: [
      ["1", "+", "9", "+", "6", "16"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "5", "-", "8", "-1"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "7", "-", "4", "-8"],
      ["0", "_", "11", "_", "18"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "9",
      "0-4": "6",
      "2-2": "5",
      "4-0": "3",
      "4-2": "7",
      "4-4": "4",
    },
    revealedNumbers: [2, 8],
  },
  "6-13": {
    gameBoard: [
      ["7", "+", "6", "-", "3", "10"],
      ["-", "_", "+", "_", "-"],
      ["8", "+", "9", "-", "2", "15"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "1", "-", "4", "2"],
      ["4", "_", "16", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "3",
      "2-2": "9",
      "4-0": "5",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [8, 2],
  },
  "6-14": {
    gameBoard: [
      ["2", "-", "8", "+", "1", "-5"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "3", "-", "6", "-2"],
      ["-", "_", "+", "_", "+"],
      ["4", "+", "5", "-", "9", "0"],
      ["-9", "_", "16", "_", "4"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "1",
      "2-2": "3",
      "4-0": "4",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [7, 6],
  },
  "6-15": {
    gameBoard: [
      ["5", "+", "2", "-", "4", "3"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "3", "-", "7", "5"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "8", "-", "6", "3"],
      ["-5", "_", "13", "_", "3"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "2",
      "0-4": "4",
      "2-2": "3",
      "4-0": "1",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [9, 7],
  },
  "6-16": {
    gameBoard: [
      ["7", "-", "2", "-", "4", "1"],
      ["+", "_", "-", "_", "-"],
      ["6", "+", "5", "+", "3", "14"],
      ["-", "_", "-", "_", "-"],
      ["9", "-", "8", "-", "1", "0"],
      ["4", "_", "-11", "_", "0"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "4",
      "2-2": "5",
      "4-0": "9",
      "4-2": "8",
      "4-4": "1",
    },
    revealedNumbers: [6, 3],
  },
  "6-17": {
    gameBoard: [
      ["3", "+", "7", "-", "6", "4"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "5", "+", "9", "5"],
      ["-", "_", "-", "_", "-"],
      ["2", "+", "8", "+", "4", "14"],
      ["0", "_", "-6", "_", "-7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "6",
      "2-2": "5",
      "4-0": "2",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [1, 9],
  },
  "6-18": {
    gameBoard: [
      ["3", "+", "9", "-", "6", "6"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "7", "-", "2", "-5"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "5", "-", "8", "-2"],
      ["8", "_", "11", "_", "12"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "9",
      "0-4": "6",
      "2-2": "7",
      "4-0": "1",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [4, 2],
  },
  "6-19": {
    gameBoard: [
      ["9", "-", "5", "-", "2", "2"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "6", "-", "3", "7"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "8", "+", "1", "16"],
      ["6", "_", "7", "_", "0"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "5",
      "0-4": "2",
      "2-2": "6",
      "4-0": "7",
      "4-2": "8",
      "4-4": "1",
    },
    revealedNumbers: [4, 3],
  },
  "6-20": {
    gameBoard: [
      ["2", "-", "6", "-", "3", "-7"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "8", "+", "5", "4"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "4", "-", "9", "-4"],
      ["-4", "_", "-6", "_", "7"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "3",
      "2-2": "8",
      "4-0": "1",
      "4-2": "4",
      "4-4": "9",
    },
    revealedNumbers: [7, 5],
  },
  "6-21": {
    gameBoard: [
      ["7", "+", "8", "+", "2", "17"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "1", "-", "3", "3"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "4", "-", "9", "1"],
      ["18", "_", "5", "_", "8"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "8",
      "0-4": "2",
      "2-2": "1",
      "4-0": "6",
      "4-2": "4",
      "4-4": "9",
    },
    revealedNumbers: [5, 3],
  },
  "6-22": {
    gameBoard: [
      ["8", "+", "3", "+", "9", "20"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "7", "-", "2", "-8"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "6", "-", "4", "-5"],
      ["4", "_", "16", "_", "3"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "3",
      "0-4": "9",
      "2-2": "7",
      "4-0": "5",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [1, 2],
  },
  "6-23": {
    gameBoard: [
      ["4", "+", "8", "-", "6", "6"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "7", "-", "5", "11"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "1", "-", "3", "0"],
      ["15", "_", "0", "_", "14"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "6",
      "2-2": "7",
      "4-0": "2",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [9, 5],
  },
  "6-24": {
    gameBoard: [
      ["7", "-", "3", "+", "5", "9"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "1", "-", "2", "3"],
      ["-", "_", "+", "_", "-"],
      ["6", "-", "9", "+", "8", "5"],
      ["-3", "_", "13", "_", "-5"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "3",
      "0-4": "5",
      "2-2": "1",
      "4-0": "6",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [4, 2],
  },
  "6-25": {
    gameBoard: [
      ["4", "-", "5", "+", "6", "5"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "7", "-", "9", "-1"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "3", "-", "2", "9"],
      ["11", "_", "15", "_", "13"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "6",
      "2-2": "7",
      "4-0": "8",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [1, 9],
  },
  "6-26": {
    gameBoard: [
      ["1", "+", "5", "+", "6", "12"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "8", "+", "4", "-2"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "7", "+", "3", "19"],
      ["-10", "_", "4", "_", "-1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "6",
      "2-2": "8",
      "4-0": "9",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [2, 4],
  },
  "6-27": {
    gameBoard: [
      ["7", "-", "5", "-", "6", "-4"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "9", "-", "8", "4"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "2", "-", "1", "1"],
      ["6", "_", "-2", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "5",
      "0-4": "6",
      "2-2": "9",
      "4-0": "4",
      "4-2": "2",
      "4-4": "1",
    },
    revealedNumbers: [3, 8],
  },
  "6-28": {
    gameBoard: [
      ["6", "-", "8", "+", "5", "3"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "7", "+", "4", "12"],
      ["-", "_", "+", "_", "-"],
      ["3", "-", "9", "+", "2", "-4"],
      ["4", "_", "24", "_", "7"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "8",
      "0-4": "5",
      "2-2": "7",
      "4-0": "3",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [1, 4],
  },
  "6-29": {
    gameBoard: [
      ["3", "-", "7", "-", "5", "-9"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "9", "-", "4", "7"],
      ["-", "_", "+", "_", "-"],
      ["6", "+", "1", "-", "8", "-1"],
      ["-1", "_", "17", "_", "1"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "5",
      "2-2": "9",
      "4-0": "6",
      "4-2": "1",
      "4-4": "8",
    },
    revealedNumbers: [2, 4],
  },
  "6-30": {
    gameBoard: [
      ["9", "+", "4", "+", "2", "15"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "8", "+", "6", "3"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "1", "-", "7", "-3"],
      ["11", "_", "13", "_", "-11"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "4",
      "0-4": "2",
      "2-2": "8",
      "4-0": "3",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [5, 6],
  },
  "6-31": {
    gameBoard: [
      ["5", "-", "6", "+", "3", "2"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "2", "-", "8", "-3"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "9", "+", "1", "14"],
      ["2", "_", "-5", "_", "-6"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "6",
      "0-4": "3",
      "2-2": "2",
      "4-0": "4",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [7, 8],
  },
  "7-0": {
    gameBoard: [
      ["8", "+", "2", "-", "9", "1"],
      ["-", "_", "-", "_", "-"],
      ["6", "+", "3", "-", "4", "5"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "1", "-", "5", "1"],
      ["-5", "_", "-2", "_", "0"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "2",
      "0-4": "9",
      "2-2": "3",
      "4-0": "7",
      "4-2": "1",
      "4-4": "5",
    },
    revealedNumbers: [6, 4],
  },
  "7-1": {
    gameBoard: [
      ["1", "+", "5", "+", "2", "8"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "3", "+", "9", "20"],
      ["+", "_", "+", "_", "+"],
      ["7", "+", "4", "+", "6", "17"],
      ["16", "_", "12", "_", "17"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "2",
      "2-2": "3",
      "4-0": "7",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [8, 9],
  },
  "7-2": {
    gameBoard: [
      ["7", "-", "4", "-", "6", "-3"],
      ["-", "_", "-", "_", "+"],
      ["9", "+", "2", "+", "5", "16"],
      ["-", "_", "-", "_", "-"],
      ["8", "+", "1", "+", "3", "12"],
      ["-10", "_", "1", "_", "8"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "6",
      "2-2": "2",
      "4-0": "8",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [9, 5],
  },
  "7-3": {
    gameBoard: [
      ["5", "-", "4", "+", "7", "8"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "8", "+", "3", "-4"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "2", "+", "6", "13"],
      ["-3", "_", "-2", "_", "4"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "4",
      "0-4": "7",
      "2-2": "8",
      "4-0": "9",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [1, 3],
  },
  "7-4": {
    gameBoard: [
      ["6", "+", "1", "+", "5", "12"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "7", "+", "4", "20"],
      ["+", "_", "+", "_", "+"],
      ["3", "-", "2", "+", "8", "9"],
      ["18", "_", "-4", "_", "17"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "5",
      "2-2": "7",
      "4-0": "3",
      "4-2": "2",
      "4-4": "8",
    },
    revealedNumbers: [9, 4],
  },
  "7-5": {
    gameBoard: [
      ["6", "-", "1", "-", "2", "3"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "8", "-", "9", "3"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "5", "+", "3", "15"],
      ["9", "_", "14", "_", "-10"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "2",
      "2-2": "8",
      "4-0": "7",
      "4-2": "5",
      "4-4": "3",
    },
    revealedNumbers: [4, 9],
  },
  "7-6": {
    gameBoard: [
      ["4", "-", "9", "-", "5", "-10"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "2", "+", "3", "8"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "8", "-", "6", "-13"],
      ["12", "_", "3", "_", "8"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "9",
      "0-4": "5",
      "2-2": "2",
      "4-0": "1",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [7, 3],
  },
  "7-7": {
    gameBoard: [
      ["8", "-", "5", "+", "6", "9"],
      ["-", "_", "-", "_", "-"],
      ["3", "+", "7", "-", "2", "8"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "4", "+", "1", "14"],
      ["14", "_", "2", "_", "3"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "5",
      "0-4": "6",
      "2-2": "7",
      "4-0": "9",
      "4-2": "4",
      "4-4": "1",
    },
    revealedNumbers: [3, 2],
  },
  "7-8": {
    gameBoard: [
      ["2", "-", "4", "+", "1", "-1"],
      ["-", "_", "-", "_", "-"],
      ["3", "-", "6", "-", "8", "-11"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "5", "21"],
      ["-8", "_", "-11", "_", "-2"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "1",
      "2-2": "6",
      "4-0": "7",
      "4-2": "9",
      "4-4": "5",
    },
    revealedNumbers: [3, 8],
  },
  "7-9": {
    gameBoard: [
      ["3", "+", "6", "+", "5", "14"],
      ["-", "_", "-", "_", "-"],
      ["1", "+", "7", "+", "4", "12"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "9", "+", "8", "1"],
      ["4", "_", "-10", "_", "-7"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "5",
      "2-2": "7",
      "4-0": "2",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [1, 4],
  },
  "7-10": {
    gameBoard: [
      ["7", "-", "9", "+", "3", "1"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "5", "-", "8", "-9"],
      ["-", "_", "+", "_", "-"],
      ["1", "-", "6", "-", "2", "-7"],
      ["10", "_", "20", "_", "9"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "3",
      "2-2": "5",
      "4-0": "1",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [4, 8],
  },
  "7-11": {
    gameBoard: [
      ["1", "-", "3", "+", "9", "7"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "6", "+", "2", "15"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "5", "-", "8", "1"],
      ["12", "_", "-8", "_", "-1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "9",
      "2-2": "6",
      "4-0": "4",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [7, 2],
  },
  "7-12": {
    gameBoard: [
      ["6", "-", "1", "+", "8", "13"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "3", "+", "7", "6"],
      ["+", "_", "+", "_", "-"],
      ["4", "+", "9", "-", "5", "8"],
      ["8", "_", "13", "_", "10"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "8",
      "2-2": "3",
      "4-0": "4",
      "4-2": "9",
      "4-4": "5",
    },
    revealedNumbers: [2, 7],
  },
  "7-13": {
    gameBoard: [
      ["7", "-", "5", "-", "1", "1"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "6", "-", "3", "0"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "2", "+", "4", "10"],
      ["-10", "_", "9", "_", "-6"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "5",
      "0-4": "1",
      "2-2": "6",
      "4-0": "8",
      "4-2": "2",
      "4-4": "4",
    },
    revealedNumbers: [9, 3],
  },
  "7-14": {
    gameBoard: [
      ["2", "-", "3", "-", "6", "-7"],
      ["-", "_", "-", "_", "+"],
      ["9", "-", "5", "+", "8", "12"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "4", "-", "7", "-10"],
      ["-8", "_", "-6", "_", "7"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "6",
      "2-2": "5",
      "4-0": "1",
      "4-2": "4",
      "4-4": "7",
    },
    revealedNumbers: [9, 8],
  },
  "7-15": {
    gameBoard: [
      ["6", "+", "4", "+", "3", "13"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "9", "-", "1", "-3"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "8", "+", "5", "-1"],
      ["15", "_", "21", "_", "9"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "3",
      "2-2": "9",
      "4-0": "2",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [7, 1],
  },
  "7-16": {
    gameBoard: [
      ["6", "-", "3", "+", "4", "7"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "2", "+", "1", "8"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "7", "+", "8", "10"],
      ["-8", "_", "12", "_", "-3"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "3",
      "0-4": "4",
      "2-2": "2",
      "4-0": "9",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [5, 1],
  },
  "7-17": {
    gameBoard: [
      ["4", "+", "3", "+", "5", "12"],
      ["+", "_", "+", "_", "-"],
      ["8", "-", "9", "+", "7", "6"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "1", "+", "6", "9"],
      ["14", "_", "11", "_", "4"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "3",
      "0-4": "5",
      "2-2": "9",
      "4-0": "2",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [8, 7],
  },
  "7-18": {
    gameBoard: [
      ["1", "+", "5", "-", "6", "0"],
      ["+", "_", "+", "_", "-"],
      ["8", "+", "9", "-", "7", "10"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "2", "-", "3", "-1"],
      ["13", "_", "16", "_", "-4"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "6",
      "2-2": "9",
      "4-0": "4",
      "4-2": "2",
      "4-4": "3",
    },
    revealedNumbers: [8, 7],
  },
  "7-19": {
    gameBoard: [
      ["3", "+", "5", "-", "7", "1"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "8", "-", "6", "-5"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "2", "+", "1", "3"],
      ["-2", "_", "15", "_", "0"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "7",
      "2-2": "8",
      "4-0": "4",
      "4-2": "2",
      "4-4": "1",
    },
    revealedNumbers: [9, 6],
  },
  "7-20": {
    gameBoard: [
      ["6", "-", "1", "+", "7", "12"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "9", "+", "5", "22"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "3", "+", "4", "9"],
      ["0", "_", "7", "_", "16"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "7",
      "2-2": "9",
      "4-0": "2",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [8, 5],
  },
  "7-21": {
    gameBoard: [
      ["7", "-", "9", "-", "5", "-7"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "1", "-", "6", "-3"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "3", "-", "2", "3"],
      ["19", "_", "5", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "5",
      "2-2": "1",
      "4-0": "8",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [4, 6],
  },
  "7-22": {
    gameBoard: [
      ["9", "-", "8", "+", "7", "8"],
      ["-", "_", "-", "_", "-"],
      ["1", "+", "6", "+", "4", "11"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "3", "-", "2", "0"],
      ["13", "_", "5", "_", "1"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "7",
      "2-2": "6",
      "4-0": "5",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [1, 4],
  },
  "7-23": {
    gameBoard: [
      ["1", "-", "8", "-", "5", "-12"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "7", "-", "6", "10"],
      ["+", "_", "-", "_", "+"],
      ["3", "-", "4", "-", "2", "-3"],
      ["-5", "_", "11", "_", "1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "5",
      "2-2": "7",
      "4-0": "3",
      "4-2": "4",
      "4-4": "2",
    },
    revealedNumbers: [9, 6],
  },
  "7-24": {
    gameBoard: [
      ["5", "-", "3", "-", "9", "-7"],
      ["+", "_", "-", "_", "-"],
      ["6", "-", "4", "+", "2", "4"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "7", "-", "8", "0"],
      ["10", "_", "6", "_", "15"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "9",
      "2-2": "4",
      "4-0": "1",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [6, 2],
  },
  "7-25": {
    gameBoard: [
      ["6", "-", "3", "-", "1", "2"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "8", "-", "5", "10"],
      ["-", "_", "-", "_", "+"],
      ["2", "-", "9", "-", "4", "-11"],
      ["11", "_", "2", "_", "0"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "3",
      "0-4": "1",
      "2-2": "8",
      "4-0": "2",
      "4-2": "9",
      "4-4": "4",
    },
    revealedNumbers: [7, 5],
  },
  "7-26": {
    gameBoard: [
      ["7", "+", "4", "-", "9", "2"],
      ["+", "_", "+", "_", "-"],
      ["3", "-", "2", "+", "8", "9"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "6", "+", "1", "0"],
      ["15", "_", "12", "_", "0"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "9",
      "2-2": "2",
      "4-0": "5",
      "4-2": "6",
      "4-4": "1",
    },
    revealedNumbers: [3, 8],
  },
  "7-27": {
    gameBoard: [
      ["9", "-", "1", "-", "7", "1"],
      ["+", "_", "+", "_", "+"],
      ["4", "+", "3", "+", "2", "9"],
      ["+", "_", "-", "_", "-"],
      ["6", "-", "5", "-", "8", "-7"],
      ["19", "_", "-1", "_", "1"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "7",
      "2-2": "3",
      "4-0": "6",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [4, 2],
  },
  "7-28": {
    gameBoard: [
      ["6", "+", "5", "+", "1", "12"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "7", "-", "2", "-6"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "8", "+", "9", "21"],
      ["13", "_", "-10", "_", "8"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "5",
      "0-4": "1",
      "2-2": "7",
      "4-0": "4",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [3, 2],
  },
  "7-29": {
    gameBoard: [
      ["5", "+", "4", "-", "6", "3"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "7", "+", "2", "17"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "3", "-", "9", "-5"],
      ["14", "_", "-6", "_", "-1"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "4",
      "0-4": "6",
      "2-2": "7",
      "4-0": "1",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [8, 2],
  },
  "7-30": {
    gameBoard: [
      ["1", "-", "2", "+", "5", "4"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "4", "+", "9", "8"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "6", "-", "8", "5"],
      ["-9", "_", "0", "_", "22"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "2",
      "0-4": "5",
      "2-2": "4",
      "4-0": "7",
      "4-2": "6",
      "4-4": "8",
    },
    revealedNumbers: [3, 9],
  },
  "7-31": {
    gameBoard: [
      ["4", "+", "9", "+", "7", "20"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "3", "-", "8", "-10"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "5", "+", "6", "13"],
      ["3", "_", "17", "_", "5"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "9",
      "0-4": "7",
      "2-2": "3",
      "4-0": "2",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [1, 8],
  },
  "8-0": {
    gameBoard: [
      ["2", "-", "8", "+", "1", "-5"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "9", "-", "3", "13"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "5", "-", "6", "-7"],
      ["5", "_", "-6", "_", "4"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "1",
      "2-2": "9",
      "4-0": "4",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [7, 3],
  },
  "8-1": {
    gameBoard: [
      ["4", "+", "1", "+", "9", "14"],
      ["+", "_", "-", "_", "-"],
      ["2", "+", "5", "+", "6", "13"],
      ["+", "_", "-", "_", "-"],
      ["8", "+", "7", "-", "3", "12"],
      ["14", "_", "-11", "_", "0"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "9",
      "2-2": "5",
      "4-0": "8",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [2, 6],
  },
  "8-2": {
    gameBoard: [
      ["1", "+", "3", "+", "7", "11"],
      ["-", "_", "+", "_", "+"],
      ["6", "+", "9", "+", "4", "19"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "5", "-", "2", "11"],
      ["-13", "_", "7", "_", "13"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "7",
      "2-2": "9",
      "4-0": "8",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [6, 4],
  },
  "8-3": {
    gameBoard: [
      ["3", "-", "5", "-", "4", "-6"],
      ["-", "_", "+", "_", "-"],
      ["2", "-", "9", "+", "1", "-6"],
      ["-", "_", "-", "_", "-"],
      ["8", "+", "7", "-", "6", "9"],
      ["-7", "_", "7", "_", "-3"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "4",
      "2-2": "9",
      "4-0": "8",
      "4-2": "7",
      "4-4": "6",
    },
    revealedNumbers: [2, 1],
  },
  "8-4": {
    gameBoard: [
      ["4", "+", "2", "-", "9", "-3"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "8", "-", "6", "9"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "3", "+", "5", "9"],
      ["-2", "_", "-9", "_", "8"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "9",
      "2-2": "8",
      "4-0": "1",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [7, 6],
  },
  "8-5": {
    gameBoard: [
      ["7", "-", "2", "-", "1", "4"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "5", "+", "6", "9"],
      ["-", "_", "-", "_", "+"],
      ["9", "+", "4", "-", "3", "10"],
      ["6", "_", "-7", "_", "-2"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "2",
      "0-4": "1",
      "2-2": "5",
      "4-0": "9",
      "4-2": "4",
      "4-4": "3",
    },
    revealedNumbers: [8, 6],
  },
  "8-6": {
    gameBoard: [
      ["9", "+", "7", "+", "8", "24"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "6", "-", "5", "2"],
      ["-", "_", "-", "_", "+"],
      ["2", "+", "4", "+", "3", "9"],
      ["6", "_", "9", "_", "16"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "7",
      "0-4": "8",
      "2-2": "6",
      "4-0": "2",
      "4-2": "4",
      "4-4": "3",
    },
    revealedNumbers: [1, 5],
  },
  "8-7": {
    gameBoard: [
      ["7", "-", "4", "+", "2", "5"],
      ["-", "_", "-", "_", "-"],
      ["5", "-", "6", "+", "3", "2"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "1", "+", "8", "16"],
      ["11", "_", "-1", "_", "-9"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "2",
      "2-2": "6",
      "4-0": "9",
      "4-2": "1",
      "4-4": "8",
    },
    revealedNumbers: [5, 3],
  },
  "8-8": {
    gameBoard: [
      ["7", "-", "4", "-", "2", "1"],
      ["+", "_", "+", "_", "-"],
      ["5", "+", "6", "-", "8", "3"],
      ["-", "_", "-", "_", "-"],
      ["1", "+", "3", "-", "9", "-5"],
      ["11", "_", "7", "_", "-15"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "2",
      "2-2": "6",
      "4-0": "1",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [5, 8],
  },
  "8-9": {
    gameBoard: [
      ["4", "-", "3", "-", "6", "-5"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "8", "-", "7", "2"],
      ["-", "_", "-", "_", "-"],
      ["5", "-", "9", "-", "2", "-6"],
      ["0", "_", "-14", "_", "-3"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "3",
      "0-4": "6",
      "2-2": "8",
      "4-0": "5",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [1, 7],
  },
  "8-10": {
    gameBoard: [
      ["1", "-", "7", "+", "2", "-4"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "5", "+", "4", "2"],
      ["-", "_", "-", "_", "-"],
      ["6", "-", "8", "-", "9", "-11"],
      ["-2", "_", "-6", "_", "-11"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "7",
      "0-4": "2",
      "2-2": "5",
      "4-0": "6",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [3, 4],
  },
  "8-11": {
    gameBoard: [
      ["4", "-", "3", "+", "9", "10"],
      ["+", "_", "-", "_", "-"],
      ["6", "+", "8", "-", "1", "13"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "5", "-", "7", "-10"],
      ["12", "_", "0", "_", "15"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "3",
      "0-4": "9",
      "2-2": "8",
      "4-0": "2",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [6, 1],
  },
  "8-12": {
    gameBoard: [
      ["4", "+", "5", "+", "9", "18"],
      ["+", "_", "+", "_", "-"],
      ["2", "+", "3", "+", "8", "13"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "1", "+", "6", "14"],
      ["-1", "_", "9", "_", "7"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "9",
      "2-2": "3",
      "4-0": "7",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [2, 8],
  },
  "8-13": {
    gameBoard: [
      ["6", "-", "1", "-", "7", "-2"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "9", "+", "3", "-2"],
      ["+", "_", "-", "_", "-"],
      ["8", "+", "2", "+", "5", "15"],
      ["18", "_", "8", "_", "-1"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "1",
      "0-4": "7",
      "2-2": "9",
      "4-0": "8",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [4, 3],
  },
  "8-14": {
    gameBoard: [
      ["5", "+", "1", "-", "4", "2"],
      ["+", "_", "+", "_", "+"],
      ["7", "+", "3", "+", "2", "12"],
      ["+", "_", "+", "_", "+"],
      ["6", "+", "9", "+", "8", "23"],
      ["18", "_", "13", "_", "14"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "1",
      "0-4": "4",
      "2-2": "3",
      "4-0": "6",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [7, 2],
  },
  "8-15": {
    gameBoard: [
      ["2", "+", "4", "-", "6", "0"],
      ["+", "_", "-", "_", "+"],
      ["7", "+", "9", "-", "1", "15"],
      ["+", "_", "+", "_", "-"],
      ["3", "-", "8", "-", "5", "-10"],
      ["12", "_", "3", "_", "2"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "6",
      "2-2": "9",
      "4-0": "3",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [7, 1],
  },
  "8-16": {
    gameBoard: [
      ["7", "-", "4", "-", "3", "0"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "1", "+", "2", "8"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "8", "-", "9", "-11"],
      ["8", "_", "13", "_", "10"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "3",
      "2-2": "1",
      "4-0": "6",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [5, 2],
  },
  "8-17": {
    gameBoard: [
      ["3", "-", "2", "-", "5", "-4"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "9", "+", "1", "-1"],
      ["-", "_", "-", "_", "+"],
      ["8", "-", "6", "-", "4", "-2"],
      ["2", "_", "5", "_", "8"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "5",
      "2-2": "9",
      "4-0": "8",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [7, 1],
  },
  "8-18": {
    gameBoard: [
      ["1", "-", "6", "-", "2", "-7"],
      ["+", "_", "-", "_", "+"],
      ["5", "-", "8", "-", "3", "-6"],
      ["+", "_", "+", "_", "+"],
      ["9", "-", "4", "+", "7", "12"],
      ["15", "_", "2", "_", "12"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "6",
      "0-4": "2",
      "2-2": "8",
      "4-0": "9",
      "4-2": "4",
      "4-4": "7",
    },
    revealedNumbers: [5, 3],
  },
  "8-19": {
    gameBoard: [
      ["6", "+", "4", "-", "2", "8"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "3", "-", "5", "1"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "1", "-", "7", "0"],
      ["23", "_", "2", "_", "4"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "2",
      "2-2": "3",
      "4-0": "8",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [9, 5],
  },
  "8-20": {
    gameBoard: [
      ["2", "-", "3", "+", "7", "6"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "9", "+", "5", "4"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "4", "+", "6", "11"],
      ["9", "_", "16", "_", "18"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "7",
      "2-2": "9",
      "4-0": "1",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [8, 5],
  },
  "8-21": {
    gameBoard: [
      ["3", "+", "2", "+", "6", "11"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "4", "+", "1", "14"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "8", "+", "5", "20"],
      ["19", "_", "-10", "_", "2"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "2",
      "0-4": "6",
      "2-2": "4",
      "4-0": "7",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [9, 1],
  },
  "8-22": {
    gameBoard: [
      ["4", "-", "5", "-", "9", "-10"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "1", "-", "6", "-4"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "2", "-", "8", "1"],
      ["-6", "_", "4", "_", "7"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "9",
      "2-2": "1",
      "4-0": "7",
      "4-2": "2",
      "4-4": "8",
    },
    revealedNumbers: [3, 6],
  },
  "8-23": {
    gameBoard: [
      ["5", "-", "1", "+", "4", "8"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "3", "+", "9", "12"],
      ["+", "_", "+", "_", "+"],
      ["8", "-", "7", "+", "2", "3"],
      ["19", "_", "11", "_", "15"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "1",
      "0-4": "4",
      "2-2": "3",
      "4-0": "8",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [6, 9],
  },
  "8-24": {
    gameBoard: [
      ["6", "+", "8", "+", "3", "17"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "9", "+", "7", "0"],
      ["+", "_", "+", "_", "-"],
      ["5", "-", "4", "+", "1", "2"],
      ["13", "_", "21", "_", "9"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "8",
      "0-4": "3",
      "2-2": "9",
      "4-0": "5",
      "4-2": "4",
      "4-4": "1",
    },
    revealedNumbers: [2, 7],
  },
  "8-25": {
    gameBoard: [
      ["6", "-", "2", "+", "5", "9"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "4", "+", "7", "12"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "9", "+", "3", "20"],
      ["-3", "_", "-3", "_", "15"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "5",
      "2-2": "4",
      "4-0": "8",
      "4-2": "9",
      "4-4": "3",
    },
    revealedNumbers: [1, 7],
  },
  "8-26": {
    gameBoard: [
      ["7", "+", "4", "+", "9", "20"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "3", "+", "2", "6"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "5", "+", "8", "9"],
      ["14", "_", "6", "_", "-1"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "9",
      "2-2": "3",
      "4-0": "6",
      "4-2": "5",
      "4-4": "8",
    },
    revealedNumbers: [1, 2],
  },
  "8-27": {
    gameBoard: [
      ["6", "-", "4", "+", "5", "7"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "2", "-", "3", "2"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "9", "+", "1", "18"],
      ["-9", "_", "11", "_", "9"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "5",
      "2-2": "2",
      "4-0": "8",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [7, 3],
  },
  "8-28": {
    gameBoard: [
      ["2", "+", "3", "+", "5", "10"],
      ["-", "_", "+", "_", "-"],
      ["6", "+", "7", "+", "8", "21"],
      ["-", "_", "-", "_", "-"],
      ["1", "+", "4", "-", "9", "-4"],
      ["-5", "_", "6", "_", "-12"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "3",
      "0-4": "5",
      "2-2": "7",
      "4-0": "1",
      "4-2": "4",
      "4-4": "9",
    },
    revealedNumbers: [6, 8],
  },
  "8-29": {
    gameBoard: [
      ["1", "-", "8", "-", "3", "-10"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "9", "+", "7", "20"],
      ["+", "_", "-", "_", "-"],
      ["5", "+", "2", "-", "6", "1"],
      ["2", "_", "15", "_", "-10"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "3",
      "2-2": "9",
      "4-0": "5",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [4, 7],
  },
  "8-30": {
    gameBoard: [
      ["3", "+", "7", "-", "5", "5"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "6", "+", "1", "15"],
      ["-", "_", "-", "_", "+"],
      ["4", "+", "9", "+", "2", "15"],
      ["-9", "_", "4", "_", "8"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "7",
      "0-4": "5",
      "2-2": "6",
      "4-0": "4",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [8, 1],
  },
  "8-31": {
    gameBoard: [
      ["3", "+", "5", "+", "7", "15"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "9", "-", "2", "11"],
      ["+", "_", "+", "_", "+"],
      ["1", "-", "6", "-", "8", "-13"],
      ["8", "_", "2", "_", "17"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "5",
      "0-4": "7",
      "2-2": "9",
      "4-0": "1",
      "4-2": "6",
      "4-4": "8",
    },
    revealedNumbers: [4, 2],
  },
  "9-0": {
    gameBoard: [
      ["4", "+", "8", "+", "2", "14"],
      ["+", "_", "-", "_", "-"],
      ["5", "+", "1", "-", "7", "-1"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "3", "-", "9", "-6"],
      ["15", "_", "10", "_", "-14"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "2",
      "2-2": "1",
      "4-0": "6",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [5, 7],
  },
  "9-1": {
    gameBoard: [
      ["7", "-", "9", "-", "1", "-3"],
      ["+", "_", "+", "_", "-"],
      ["4", "+", "5", "-", "3", "6"],
      ["+", "_", "-", "_", "-"],
      ["8", "-", "6", "-", "2", "0"],
      ["19", "_", "8", "_", "-4"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "1",
      "2-2": "5",
      "4-0": "8",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [4, 3],
  },
  "9-2": {
    gameBoard: [
      ["6", "-", "4", "-", "5", "-3"],
      ["-", "_", "-", "_", "-"],
      ["9", "+", "8", "+", "2", "19"],
      ["-", "_", "-", "_", "-"],
      ["3", "-", "1", "+", "7", "9"],
      ["-6", "_", "-5", "_", "-4"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "5",
      "2-2": "8",
      "4-0": "3",
      "4-2": "1",
      "4-4": "7",
    },
    revealedNumbers: [9, 2],
  },
  "9-3": {
    gameBoard: [
      ["6", "+", "4", "+", "5", "15"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "1", "+", "9", "13"],
      ["+", "_", "+", "_", "-"],
      ["8", "-", "7", "-", "2", "-1"],
      ["17", "_", "10", "_", "-6"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "5",
      "2-2": "1",
      "4-0": "8",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [3, 9],
  },
  "9-4": {
    gameBoard: [
      ["2", "+", "5", "-", "9", "-2"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "1", "-", "6", "1"],
      ["-", "_", "+", "_", "+"],
      ["4", "-", "3", "+", "7", "8"],
      ["6", "_", "7", "_", "22"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "5",
      "0-4": "9",
      "2-2": "1",
      "4-0": "4",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [8, 6],
  },
  "9-5": {
    gameBoard: [
      ["2", "+", "1", "-", "8", "-5"],
      ["+", "_", "-", "_", "-"],
      ["5", "+", "6", "+", "4", "15"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "7", "-", "3", "13"],
      ["16", "_", "2", "_", "1"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "1",
      "0-4": "8",
      "2-2": "6",
      "4-0": "9",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [5, 4],
  },
  "9-6": {
    gameBoard: [
      ["2", "+", "4", "+", "7", "13"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "9", "-", "8", "6"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "6", "+", "3", "10"],
      ["-2", "_", "1", "_", "18"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "7",
      "2-2": "9",
      "4-0": "1",
      "4-2": "6",
      "4-4": "3",
    },
    revealedNumbers: [5, 8],
  },
  "9-7": {
    gameBoard: [
      ["5", "+", "4", "+", "3", "12"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "2", "-", "1", "4"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "9", "+", "8", "5"],
      ["4", "_", "-7", "_", "10"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "4",
      "0-4": "3",
      "2-2": "2",
      "4-0": "6",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [7, 1],
  },
  "9-8": {
    gameBoard: [
      ["9", "-", "8", "+", "6", "7"],
      ["+", "_", "-", "_", "-"],
      ["3", "+", "5", "+", "1", "9"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "2", "-", "7", "-1"],
      ["16", "_", "1", "_", "-2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "6",
      "2-2": "5",
      "4-0": "4",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [3, 1],
  },
  "9-9": {
    gameBoard: [
      ["9", "-", "8", "+", "5", "6"],
      ["+", "_", "+", "_", "+"],
      ["7", "+", "1", "-", "6", "2"],
      ["+", "_", "-", "_", "-"],
      ["4", "+", "3", "+", "2", "9"],
      ["20", "_", "6", "_", "9"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "5",
      "2-2": "1",
      "4-0": "4",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [7, 6],
  },
  "9-10": {
    gameBoard: [
      ["7", "+", "8", "+", "2", "17"],
      ["+", "_", "-", "_", "-"],
      ["9", "+", "6", "-", "5", "10"],
      ["-", "_", "+", "_", "+"],
      ["1", "-", "3", "+", "4", "2"],
      ["15", "_", "5", "_", "1"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "8",
      "0-4": "2",
      "2-2": "6",
      "4-0": "1",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [9, 5],
  },
  "9-11": {
    gameBoard: [
      ["6", "+", "3", "+", "1", "10"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "4", "+", "7", "19"],
      ["+", "_", "+", "_", "+"],
      ["2", "-", "5", "+", "9", "6"],
      ["0", "_", "12", "_", "17"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "3",
      "0-4": "1",
      "2-2": "4",
      "4-0": "2",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [8, 7],
  },
  "9-12": {
    gameBoard: [
      ["7", "+", "4", "+", "6", "17"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "8", "-", "1", "-6"],
      ["+", "_", "+", "_", "-"],
      ["2", "+", "5", "+", "9", "16"],
      ["12", "_", "1", "_", "-4"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "6",
      "2-2": "8",
      "4-0": "2",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [3, 1],
  },
  "9-13": {
    gameBoard: [
      ["3", "+", "4", "+", "1", "8"],
      ["-", "_", "+", "_", "-"],
      ["8", "+", "6", "-", "2", "12"],
      ["+", "_", "+", "_", "+"],
      ["5", "-", "7", "+", "9", "7"],
      ["0", "_", "17", "_", "8"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "1",
      "2-2": "6",
      "4-0": "5",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [8, 2],
  },
  "9-14": {
    gameBoard: [
      ["1", "-", "6", "+", "2", "-3"],
      ["+", "_", "-", "_", "-"],
      ["7", "+", "3", "-", "5", "5"],
      ["-", "_", "+", "_", "+"],
      ["9", "-", "8", "+", "4", "5"],
      ["-1", "_", "11", "_", "1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "6",
      "0-4": "2",
      "2-2": "3",
      "4-0": "9",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [7, 5],
  },
  "9-15": {
    gameBoard: [
      ["6", "-", "3", "-", "2", "1"],
      ["+", "_", "-", "_", "+"],
      ["5", "+", "4", "+", "8", "17"],
      ["+", "_", "-", "_", "+"],
      ["9", "-", "7", "-", "1", "1"],
      ["20", "_", "-8", "_", "11"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "3",
      "0-4": "2",
      "2-2": "4",
      "4-0": "9",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [5, 8],
  },
  "9-16": {
    gameBoard: [
      ["6", "+", "7", "+", "4", "17"],
      ["-", "_", "-", "_", "-"],
      ["2", "+", "3", "-", "5", "0"],
      ["-", "_", "+", "_", "-"],
      ["1", "+", "9", "+", "8", "18"],
      ["3", "_", "13", "_", "-9"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "4",
      "2-2": "3",
      "4-0": "1",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [2, 5],
  },
  "9-17": {
    gameBoard: [
      ["9", "-", "6", "-", "2", "1"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "8", "-", "5", "10"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "3", "-", "4", "0"],
      ["3", "_", "1", "_", "11"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "6",
      "0-4": "2",
      "2-2": "8",
      "4-0": "1",
      "4-2": "3",
      "4-4": "4",
    },
    revealedNumbers: [7, 5],
  },
  "9-18": {
    gameBoard: [
      ["9", "-", "2", "-", "5", "2"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "7", "+", "1", "11"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "6", "-", "4", "10"],
      ["14", "_", "15", "_", "8"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "2",
      "0-4": "5",
      "2-2": "7",
      "4-0": "8",
      "4-2": "6",
      "4-4": "4",
    },
    revealedNumbers: [3, 1],
  },
  "9-19": {
    gameBoard: [
      ["7", "-", "5", "+", "2", "4"],
      ["+", "_", "+", "_", "-"],
      ["6", "-", "4", "-", "9", "-7"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "3", "+", "1", "6"],
      ["5", "_", "12", "_", "-6"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "5",
      "0-4": "2",
      "2-2": "4",
      "4-0": "8",
      "4-2": "3",
      "4-4": "1",
    },
    revealedNumbers: [6, 9],
  },
  "9-20": {
    gameBoard: [
      ["4", "+", "6", "+", "5", "15"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "2", "-", "1", "5"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "3", "-", "7", "-1"],
      ["-13", "_", "7", "_", "-3"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "5",
      "2-2": "2",
      "4-0": "9",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [8, 1],
  },
  "9-21": {
    gameBoard: [
      ["9", "+", "1", "-", "3", "7"],
      ["-", "_", "-", "_", "-"],
      ["5", "+", "2", "+", "8", "15"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "4", "-", "6", "5"],
      ["-3", "_", "-5", "_", "1"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "3",
      "2-2": "2",
      "4-0": "7",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [5, 8],
  },
  "9-22": {
    gameBoard: [
      ["3", "-", "1", "-", "4", "-2"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "6", "+", "8", "23"],
      ["-", "_", "-", "_", "+"],
      ["5", "-", "2", "-", "7", "-4"],
      ["7", "_", "-7", "_", "19"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "4",
      "2-2": "6",
      "4-0": "5",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [9, 8],
  },
  "9-23": {
    gameBoard: [
      ["2", "+", "7", "-", "4", "5"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "8", "-", "1", "-6"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "5", "+", "6", "20"],
      ["8", "_", "20", "_", "-1"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "7",
      "0-4": "4",
      "2-2": "8",
      "4-0": "9",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [3, 1],
  },
  "9-24": {
    gameBoard: [
      ["2", "-", "6", "+", "7", "3"],
      ["-", "_", "+", "_", "-"],
      ["8", "-", "5", "-", "4", "-1"],
      ["-", "_", "-", "_", "-"],
      ["1", "+", "3", "-", "9", "-5"],
      ["-7", "_", "8", "_", "-6"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "7",
      "2-2": "5",
      "4-0": "1",
      "4-2": "3",
      "4-4": "9",
    },
    revealedNumbers: [8, 4],
  },
  "9-25": {
    gameBoard: [
      ["6", "+", "7", "-", "1", "12"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "4", "-", "9", "-5"],
      ["-", "_", "-", "_", "-"],
      ["3", "-", "5", "+", "2", "0"],
      ["11", "_", "-2", "_", "8"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "7",
      "0-4": "1",
      "2-2": "4",
      "4-0": "3",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [8, 9],
  },
  "9-26": {
    gameBoard: [
      ["9", "-", "5", "+", "7", "11"],
      ["+", "_", "-", "_", "+"],
      ["4", "-", "6", "-", "8", "-10"],
      ["+", "_", "+", "_", "-"],
      ["1", "+", "2", "+", "3", "6"],
      ["14", "_", "1", "_", "12"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "5",
      "0-4": "7",
      "2-2": "6",
      "4-0": "1",
      "4-2": "2",
      "4-4": "3",
    },
    revealedNumbers: [4, 8],
  },
  "9-27": {
    gameBoard: [
      ["8", "-", "9", "+", "1", "0"],
      ["+", "_", "-", "_", "+"],
      ["6", "-", "4", "+", "5", "7"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "7", "+", "3", "-2"],
      ["12", "_", "-2", "_", "3"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "1",
      "2-2": "4",
      "4-0": "2",
      "4-2": "7",
      "4-4": "3",
    },
    revealedNumbers: [6, 5],
  },
  "9-28": {
    gameBoard: [
      ["5", "-", "6", "+", "7", "6"],
      ["+", "_", "+", "_", "+"],
      ["8", "+", "2", "+", "1", "11"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "9", "-", "3", "-8"],
      ["9", "_", "17", "_", "5"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "6",
      "0-4": "7",
      "2-2": "2",
      "4-0": "4",
      "4-2": "9",
      "4-4": "3",
    },
    revealedNumbers: [8, 1],
  },
  "9-29": {
    gameBoard: [
      ["6", "-", "2", "-", "5", "-1"],
      ["-", "_", "+", "_", "+"],
      ["8", "+", "9", "-", "7", "10"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "1", "+", "4", "6"],
      ["1", "_", "10", "_", "8"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "5",
      "2-2": "9",
      "4-0": "3",
      "4-2": "1",
      "4-4": "4",
    },
    revealedNumbers: [8, 7],
  },
  "9-30": {
    gameBoard: [
      ["3", "+", "6", "+", "4", "13"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "2", "-", "9", "-6"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "8", "+", "7", "16"],
      ["-3", "_", "16", "_", "2"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "4",
      "2-2": "2",
      "4-0": "1",
      "4-2": "8",
      "4-4": "7",
    },
    revealedNumbers: [5, 9],
  },
  "9-31": {
    gameBoard: [
      ["5", "-", "7", "-", "2", "-4"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "9", "-", "3", "-8"],
      ["+", "_", "-", "_", "+"],
      ["8", "+", "1", "+", "6", "15"],
      ["17", "_", "15", "_", "11"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "2",
      "2-2": "9",
      "4-0": "8",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [4, 3],
  },
  "10-0": {
    gameBoard: [
      ["1", "+", "2", "-", "4", "-1"],
      ["-", "_", "+", "_", "-"],
      ["7", "-", "6", "+", "9", "10"],
      ["+", "_", "+", "_", "-"],
      ["8", "-", "3", "+", "5", "10"],
      ["2", "_", "11", "_", "-10"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "2",
      "0-4": "4",
      "2-2": "6",
      "4-0": "8",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [7, 9],
  },
  "10-1": {
    gameBoard: [
      ["3", "+", "8", "+", "4", "15"],
      ["-", "_", "+", "_", "+"],
      ["1", "+", "5", "-", "7", "-1"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "2", "-", "9", "-1"],
      ["8", "_", "11", "_", "20"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "8",
      "0-4": "4",
      "2-2": "5",
      "4-0": "6",
      "4-2": "2",
      "4-4": "9",
    },
    revealedNumbers: [1, 7],
  },
  "10-2": {
    gameBoard: [
      ["7", "-", "1", "-", "3", "3"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "9", "+", "2", "-3"],
      ["+", "_", "+", "_", "+"],
      ["5", "+", "6", "-", "8", "3"],
      ["8", "_", "-2", "_", "13"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "1",
      "0-4": "3",
      "2-2": "9",
      "4-0": "5",
      "4-2": "6",
      "4-4": "8",
    },
    revealedNumbers: [4, 2],
  },
  "10-3": {
    gameBoard: [
      ["2", "+", "9", "+", "8", "19"],
      ["+", "_", "+", "_", "+"],
      ["5", "+", "1", "-", "3", "3"],
      ["+", "_", "-", "_", "-"],
      ["4", "-", "6", "+", "7", "5"],
      ["11", "_", "4", "_", "4"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "9",
      "0-4": "8",
      "2-2": "1",
      "4-0": "4",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [5, 3],
  },
  "10-4": {
    gameBoard: [
      ["5", "+", "7", "+", "8", "20"],
      ["-", "_", "+", "_", "-"],
      ["3", "-", "1", "+", "2", "4"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "6", "+", "9", "19"],
      ["6", "_", "2", "_", "15"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "7",
      "0-4": "8",
      "2-2": "1",
      "4-0": "4",
      "4-2": "6",
      "4-4": "9",
    },
    revealedNumbers: [3, 2],
  },
  "10-5": {
    gameBoard: [
      ["8", "-", "1", "-", "6", "1"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "2", "+", "4", "11"],
      ["-", "_", "-", "_", "-"],
      ["5", "+", "3", "-", "7", "1"],
      ["12", "_", "-4", "_", "-5"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "1",
      "0-4": "6",
      "2-2": "2",
      "4-0": "5",
      "4-2": "3",
      "4-4": "7",
    },
    revealedNumbers: [9, 4],
  },
  "10-6": {
    gameBoard: [
      ["5", "-", "9", "+", "8", "4"],
      ["+", "_", "+", "_", "-"],
      ["7", "-", "1", "-", "2", "4"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "4", "+", "6", "13"],
      ["9", "_", "14", "_", "0"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "9",
      "0-4": "8",
      "2-2": "1",
      "4-0": "3",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [7, 2],
  },
  "10-7": {
    gameBoard: [
      ["3", "-", "4", "-", "9", "-10"],
      ["+", "_", "-", "_", "+"],
      ["7", "-", "8", "-", "6", "-7"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "1", "-", "5", "-4"],
      ["12", "_", "-5", "_", "10"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "9",
      "2-2": "8",
      "4-0": "2",
      "4-2": "1",
      "4-4": "5",
    },
    revealedNumbers: [7, 6],
  },
  "10-8": {
    gameBoard: [
      ["9", "+", "1", "+", "7", "17"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "3", "-", "8", "-1"],
      ["-", "_", "+", "_", "-"],
      ["2", "+", "6", "-", "5", "3"],
      ["3", "_", "10", "_", "-6"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "7",
      "2-2": "3",
      "4-0": "2",
      "4-2": "6",
      "4-4": "5",
    },
    revealedNumbers: [4, 8],
  },
  "10-9": {
    gameBoard: [
      ["3", "+", "1", "+", "4", "8"],
      ["+", "_", "-", "_", "-"],
      ["5", "+", "6", "+", "2", "13"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "8", "24"],
      ["1", "_", "-14", "_", "10"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "1",
      "0-4": "4",
      "2-2": "6",
      "4-0": "7",
      "4-2": "9",
      "4-4": "8",
    },
    revealedNumbers: [5, 2],
  },
  "10-10": {
    gameBoard: [
      ["4", "-", "1", "-", "5", "-2"],
      ["+", "_", "+", "_", "-"],
      ["3", "-", "6", "+", "2", "-1"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "7", "-", "9", "-8"],
      ["-1", "_", "14", "_", "12"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "1",
      "0-4": "5",
      "2-2": "6",
      "4-0": "8",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [3, 2],
  },
  "10-11": {
    gameBoard: [
      ["7", "+", "9", "+", "3", "19"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "5", "-", "6", "-7"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "1", "-", "8", "-5"],
      ["5", "_", "13", "_", "5"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "9",
      "0-4": "3",
      "2-2": "5",
      "4-0": "2",
      "4-2": "1",
      "4-4": "8",
    },
    revealedNumbers: [4, 6],
  },
  "10-12": {
    gameBoard: [
      ["1", "+", "3", "-", "7", "-3"],
      ["+", "_", "+", "_", "+"],
      ["4", "+", "8", "+", "9", "21"],
      ["+", "_", "+", "_", "+"],
      ["6", "+", "5", "+", "2", "13"],
      ["11", "_", "16", "_", "18"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "3",
      "0-4": "7",
      "2-2": "8",
      "4-0": "6",
      "4-2": "5",
      "4-4": "2",
    },
    revealedNumbers: [4, 9],
  },
  "10-13": {
    gameBoard: [
      ["5", "+", "2", "+", "8", "15"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "1", "+", "7", "11"],
      ["+", "_", "+", "_", "+"],
      ["9", "+", "4", "+", "6", "19"],
      ["11", "_", "7", "_", "21"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "2",
      "0-4": "8",
      "2-2": "1",
      "4-0": "9",
      "4-2": "4",
      "4-4": "6",
    },
    revealedNumbers: [3, 7],
  },
  "10-14": {
    gameBoard: [
      ["8", "+", "6", "-", "9", "5"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "5", "+", "1", "9"],
      ["+", "_", "+", "_", "-"],
      ["2", "-", "4", "-", "7", "-9"],
      ["7", "_", "15", "_", "3"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "9",
      "2-2": "5",
      "4-0": "2",
      "4-2": "4",
      "4-4": "7",
    },
    revealedNumbers: [3, 1],
  },
  "10-15": {
    gameBoard: [
      ["4", "-", "5", "-", "2", "-3"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "1", "-", "3", "5"],
      ["+", "_", "+", "_", "-"],
      ["8", "-", "6", "+", "7", "9"],
      ["21", "_", "12", "_", "-8"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "2",
      "2-2": "1",
      "4-0": "8",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [9, 3],
  },
  "10-16": {
    gameBoard: [
      ["4", "-", "6", "+", "3", "1"],
      ["-", "_", "-", "_", "+"],
      ["7", "-", "9", "+", "1", "-1"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "2", "+", "5", "11"],
      ["-11", "_", "-1", "_", "9"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "3",
      "2-2": "9",
      "4-0": "8",
      "4-2": "2",
      "4-4": "5",
    },
    revealedNumbers: [7, 1],
  },
  "10-17": {
    gameBoard: [
      ["2", "-", "6", "+", "9", "5"],
      ["+", "_", "-", "_", "+"],
      ["1", "+", "7", "+", "5", "13"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "8", "+", "4", "-1"],
      ["6", "_", "-9", "_", "10"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "6",
      "0-4": "9",
      "2-2": "7",
      "4-0": "3",
      "4-2": "8",
      "4-4": "4",
    },
    revealedNumbers: [1, 5],
  },
  "10-18": {
    gameBoard: [
      ["1", "+", "5", "+", "3", "9"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "2", "-", "8", "3"],
      ["-", "_", "-", "_", "-"],
      ["4", "-", "6", "-", "7", "-9"],
      ["-12", "_", "1", "_", "-12"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "5",
      "0-4": "3",
      "2-2": "2",
      "4-0": "4",
      "4-2": "6",
      "4-4": "7",
    },
    revealedNumbers: [9, 8],
  },
  "10-19": {
    gameBoard: [
      ["4", "-", "6", "-", "8", "-10"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "5", "-", "3", "-7"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "2", "18"],
      ["-2", "_", "2", "_", "7"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "8",
      "2-2": "5",
      "4-0": "7",
      "4-2": "9",
      "4-4": "2",
    },
    revealedNumbers: [1, 3],
  },
  "10-20": {
    gameBoard: [
      ["3", "-", "8", "+", "2", "-3"],
      ["+", "_", "+", "_", "+"],
      ["9", "+", "7", "-", "4", "12"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "1", "-", "6", "0"],
      ["7", "_", "14", "_", "12"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "8",
      "0-4": "2",
      "2-2": "7",
      "4-0": "5",
      "4-2": "1",
      "4-4": "6",
    },
    revealedNumbers: [9, 4],
  },
  "10-21": {
    gameBoard: [
      ["5", "+", "8", "+", "3", "16"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "7", "-", "9", "-15"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "6", "-", "2", "-4"],
      ["8", "_", "7", "_", "-8"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "3",
      "2-2": "7",
      "4-0": "4",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [1, 9],
  },
  "10-22": {
    gameBoard: [
      ["4", "+", "6", "+", "8", "18"],
      ["-", "_", "+", "_", "+"],
      ["9", "+", "1", "+", "3", "13"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "7", "+", "5", "14"],
      ["-3", "_", "0", "_", "16"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "8",
      "2-2": "1",
      "4-0": "2",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [9, 3],
  },
  "10-23": {
    gameBoard: [
      ["2", "-", "8", "+", "6", "0"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "1", "+", "7", "9"],
      ["+", "_", "+", "_", "-"],
      ["4", "-", "5", "+", "9", "8"],
      ["3", "_", "14", "_", "4"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "6",
      "2-2": "1",
      "4-0": "4",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [3, 7],
  },
  "10-24": {
    gameBoard: [
      ["6", "+", "2", "-", "3", "5"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "9", "+", "4", "-4"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "7", "-", "5", "-4"],
      ["-3", "_", "0", "_", "4"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "2",
      "0-4": "3",
      "2-2": "9",
      "4-0": "8",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [1, 4],
  },
  "10-25": {
    gameBoard: [
      ["8", "+", "9", "-", "5", "12"],
      ["+", "_", "+", "_", "-"],
      ["1", "-", "6", "-", "4", "-9"],
      ["+", "_", "-", "_", "+"],
      ["3", "-", "2", "-", "7", "-6"],
      ["12", "_", "13", "_", "8"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "9",
      "0-4": "5",
      "2-2": "6",
      "4-0": "3",
      "4-2": "2",
      "4-4": "7",
    },
    revealedNumbers: [1, 4],
  },
  "10-26": {
    gameBoard: [
      ["5", "-", "4", "+", "9", "10"],
      ["-", "_", "-", "_", "+"],
      ["2", "+", "1", "+", "3", "6"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "6", "-", "8", "5"],
      ["10", "_", "9", "_", "4"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "4",
      "0-4": "9",
      "2-2": "1",
      "4-0": "7",
      "4-2": "6",
      "4-4": "8",
    },
    revealedNumbers: [2, 3],
  },
  "10-27": {
    gameBoard: [
      ["4", "-", "6", "+", "9", "7"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "5", "-", "2", "10"],
      ["-", "_", "+", "_", "+"],
      ["1", "-", "3", "+", "8", "6"],
      ["-4", "_", "4", "_", "19"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "9",
      "2-2": "5",
      "4-0": "1",
      "4-2": "3",
      "4-4": "8",
    },
    revealedNumbers: [7, 2],
  },
  "10-28": {
    gameBoard: [
      ["4", "-", "5", "-", "7", "-8"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "1", "-", "3", "7"],
      ["-", "_", "+", "_", "+"],
      ["2", "+", "6", "-", "8", "0"],
      ["-7", "_", "12", "_", "12"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "5",
      "0-4": "7",
      "2-2": "1",
      "4-0": "2",
      "4-2": "6",
      "4-4": "8",
    },
    revealedNumbers: [9, 3],
  },
  "10-29": {
    gameBoard: [
      ["2", "-", "1", "+", "9", "10"],
      ["-", "_", "+", "_", "-"],
      ["4", "+", "3", "+", "6", "13"],
      ["+", "_", "+", "_", "+"],
      ["7", "-", "8", "+", "5", "4"],
      ["5", "_", "12", "_", "8"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "1",
      "0-4": "9",
      "2-2": "3",
      "4-0": "7",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [4, 6],
  },
  "10-30": {
    gameBoard: [
      ["3", "-", "6", "+", "4", "1"],
      ["-", "_", "+", "_", "-"],
      ["9", "-", "7", "-", "2", "0"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "1", "+", "5", "12"],
      ["-14", "_", "12", "_", "-3"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "6",
      "0-4": "4",
      "2-2": "7",
      "4-0": "8",
      "4-2": "1",
      "4-4": "5",
    },
    revealedNumbers: [9, 2],
  },
  "10-31": {
    gameBoard: [
      ["7", "-", "6", "-", "4", "-3"],
      ["-", "_", "-", "_", "-"],
      ["8", "-", "9", "-", "2", "-3"],
      ["+", "_", "-", "_", "-"],
      ["1", "-", "3", "+", "5", "3"],
      ["0", "_", "-6", "_", "-3"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "6",
      "0-4": "4",
      "2-2": "9",
      "4-0": "1",
      "4-2": "3",
      "4-4": "5",
    },
    revealedNumbers: [8, 2],
  },
  "11-0": {
    gameBoard: [
      ["4", "+", "6", "+", "2", "12"],
      ["-", "_", "+", "_", "-"],
      ["3", "-", "7", "-", "8", "-12"],
      ["-", "_", "-", "_", "+"],
      ["5", "+", "9", "+", "1", "15"],
      ["-4", "_", "4", "_", "-5"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "6",
      "0-4": "2",
      "2-2": "7",
      "4-0": "5",
      "4-2": "9",
      "4-4": "1",
    },
    revealedNumbers: [3, 8],
  },
  "11-1": {
    gameBoard: [
      ["2", "+", "8", "-", "3", "7"],
      ["+", "_", "+", "_", "+"],
      ["1", "+", "5", "-", "6", "0"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "4", "-", "9", "2"],
      ["-4", "_", "9", "_", "0"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "8",
      "0-4": "3",
      "2-2": "5",
      "4-0": "7",
      "4-2": "4",
      "4-4": "9",
    },
    revealedNumbers: [1, 6],
  },
  "11-2": {
    gameBoard: [
      ["1", "-", "9", "-", "5", "-13"],
      ["+", "_", "-", "_", "-"],
      ["3", "-", "4", "+", "6", "5"],
      ["-", "_", "+", "_", "+"],
      ["8", "-", "7", "-", "2", "-1"],
      ["-4", "_", "12", "_", "1"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "9",
      "0-4": "5",
      "2-2": "4",
      "4-0": "8",
      "4-2": "7",
      "4-4": "2",
    },
    revealedNumbers: [3, 6],
  },
  "11-3": {
    gameBoard: [
      ["7", "-", "1", "-", "3", "3"],
      ["-", "_", "+", "_", "+"],
      ["9", "-", "6", "-", "2", "1"],
      ["+", "_", "-", "_", "+"],
      ["8", "-", "5", "-", "4", "-1"],
      ["6", "_", "2", "_", "9"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "1",
      "0-4": "3",
      "2-2": "6",
      "4-0": "8",
      "4-2": "5",
      "4-4": "4",
    },
    revealedNumbers: [9, 2],
  },
  "11-4": {
    gameBoard: [
      ["9", "-", "7", "-", "1", "1"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "5", "+", "8", "7"],
      ["-", "_", "-", "_", "-"],
      ["6", "-", "2", "+", "3", "7"],
      ["7", "_", "10", "_", "6"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "7",
      "0-4": "1",
      "2-2": "5",
      "4-0": "6",
      "4-2": "2",
      "4-4": "3",
    },
    revealedNumbers: [4, 8],
  },
  "11-5": {
    gameBoard: [
      ["9", "+", "1", "+", "4", "14"],
      ["+", "_", "+", "_", "-"],
      ["3", "-", "6", "-", "7", "-10"],
      ["+", "_", "-", "_", "+"],
      ["2", "+", "8", "-", "5", "5"],
      ["14", "_", "-1", "_", "2"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "1",
      "0-4": "4",
      "2-2": "6",
      "4-0": "2",
      "4-2": "8",
      "4-4": "5",
    },
    revealedNumbers: [3, 7],
  },
  "11-6": {
    gameBoard: [
      ["8", "-", "6", "+", "1", "3"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "3", "+", "5", "12"],
      ["-", "_", "-", "_", "-"],
      ["2", "+", "9", "+", "7", "18"],
      ["10", "_", "-6", "_", "-1"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "1",
      "2-2": "3",
      "4-0": "2",
      "4-2": "9",
      "4-4": "7",
    },
    revealedNumbers: [4, 5],
  },
  "11-7": {
    gameBoard: [
      ["8", "-", "6", "+", "3", "5"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "4", "+", "1", "-1"],
      ["+", "_", "-", "_", "-"],
      ["9", "-", "5", "-", "7", "-3"],
      ["15", "_", "-3", "_", "-5"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "6",
      "0-4": "3",
      "2-2": "4",
      "4-0": "9",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [2, 1],
  },
  "11-8": {
    gameBoard: [
      ["9", "+", "8", "+", "7", "24"],
      ["-", "_", "-", "_", "+"],
      ["6", "+", "4", "+", "2", "12"],
      ["-", "_", "+", "_", "+"],
      ["3", "-", "5", "+", "1", "-1"],
      ["0", "_", "9", "_", "10"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "7",
      "2-2": "4",
      "4-0": "3",
      "4-2": "5",
      "4-4": "1",
    },
    revealedNumbers: [6, 2],
  },
  "11-9": {
    gameBoard: [
      ["7", "+", "4", "-", "3", "8"],
      ["-", "_", "-", "_", "+"],
      ["6", "+", "8", "-", "2", "12"],
      ["+", "_", "-", "_", "-"],
      ["1", "-", "5", "+", "9", "5"],
      ["2", "_", "-9", "_", "-4"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "4",
      "0-4": "3",
      "2-2": "8",
      "4-0": "1",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [6, 2],
  },
  "11-10": {
    gameBoard: [
      ["4", "+", "8", "-", "1", "11"],
      ["-", "_", "-", "_", "-"],
      ["7", "+", "9", "+", "3", "19"],
      ["-", "_", "+", "_", "+"],
      ["2", "-", "5", "+", "6", "3"],
      ["-5", "_", "4", "_", "4"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "1",
      "2-2": "9",
      "4-0": "2",
      "4-2": "5",
      "4-4": "6",
    },
    revealedNumbers: [7, 3],
  },
  "11-11": {
    gameBoard: [
      ["6", "-", "4", "-", "5", "-3"],
      ["+", "_", "-", "_", "+"],
      ["1", "-", "7", "-", "3", "-9"],
      ["+", "_", "+", "_", "+"],
      ["2", "+", "8", "+", "9", "19"],
      ["9", "_", "5", "_", "17"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "4",
      "0-4": "5",
      "2-2": "7",
      "4-0": "2",
      "4-2": "8",
      "4-4": "9",
    },
    revealedNumbers: [1, 3],
  },
  "11-12": {
    gameBoard: [
      ["1", "+", "7", "-", "5", "3"],
      ["+", "_", "-", "_", "+"],
      ["3", "+", "6", "-", "9", "0"],
      ["-", "_", "+", "_", "-"],
      ["4", "-", "8", "+", "2", "-2"],
      ["0", "_", "9", "_", "12"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "7",
      "0-4": "5",
      "2-2": "6",
      "4-0": "4",
      "4-2": "8",
      "4-4": "2",
    },
    revealedNumbers: [3, 9],
  },
  "11-13": {
    gameBoard: [
      ["4", "-", "8", "-", "3", "-7"],
      ["-", "_", "+", "_", "+"],
      ["7", "+", "1", "+", "5", "13"],
      ["-", "_", "-", "_", "-"],
      ["6", "+", "2", "-", "9", "-1"],
      ["-9", "_", "7", "_", "-1"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "8",
      "0-4": "3",
      "2-2": "1",
      "4-0": "6",
      "4-2": "2",
      "4-4": "9",
    },
    revealedNumbers: [7, 5],
  },
  "11-14": {
    gameBoard: [
      ["4", "-", "2", "+", "3", "5"],
      ["-", "_", "-", "_", "-"],
      ["7", "-", "5", "+", "1", "3"],
      ["+", "_", "-", "_", "+"],
      ["9", "+", "8", "+", "6", "23"],
      ["6", "_", "-11", "_", "8"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "2",
      "0-4": "3",
      "2-2": "5",
      "4-0": "9",
      "4-2": "8",
      "4-4": "6",
    },
    revealedNumbers: [7, 1],
  },
  "11-15": {
    gameBoard: [
      ["1", "-", "8", "+", "6", "-1"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "3", "-", "4", "-2"],
      ["+", "_", "-", "_", "-"],
      ["2", "-", "7", "+", "9", "4"],
      ["-2", "_", "4", "_", "-7"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "6",
      "2-2": "3",
      "4-0": "2",
      "4-2": "7",
      "4-4": "9",
    },
    revealedNumbers: [5, 4],
  },
  "11-16": {
    gameBoard: [
      ["4", "+", "9", "-", "6", "7"],
      ["-", "_", "+", "_", "-"],
      ["5", "-", "3", "+", "2", "4"],
      ["-", "_", "-", "_", "-"],
      ["8", "+", "7", "-", "1", "14"],
      ["-9", "_", "5", "_", "3"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "9",
      "0-4": "6",
      "2-2": "3",
      "4-0": "8",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [5, 2],
  },
  "11-17": {
    gameBoard: [
      ["3", "+", "9", "+", "5", "17"],
      ["+", "_", "+", "_", "+"],
      ["4", "-", "6", "-", "1", "-3"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "7", "+", "8", "3"],
      ["5", "_", "8", "_", "-2"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "9",
      "0-4": "5",
      "2-2": "6",
      "4-0": "2",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [4, 1],
  },
  "11-18": {
    gameBoard: [
      ["2", "+", "4", "-", "1", "5"],
      ["+", "_", "+", "_", "-"],
      ["9", "-", "6", "+", "3", "6"],
      ["-", "_", "+", "_", "+"],
      ["5", "+", "7", "+", "8", "20"],
      ["6", "_", "17", "_", "6"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "4",
      "0-4": "1",
      "2-2": "6",
      "4-0": "5",
      "4-2": "7",
      "4-4": "8",
    },
    revealedNumbers: [9, 3],
  },
  "11-19": {
    gameBoard: [
      ["2", "+", "7", "+", "8", "17"],
      ["-", "_", "-", "_", "-"],
      ["1", "-", "5", "-", "6", "-10"],
      ["-", "_", "+", "_", "+"],
      ["3", "+", "9", "-", "4", "8"],
      ["-2", "_", "11", "_", "6"],
    ],
    answers: {
      "0-0": "2",
      "0-2": "7",
      "0-4": "8",
      "2-2": "5",
      "4-0": "3",
      "4-2": "9",
      "4-4": "4",
    },
    revealedNumbers: [1, 6],
  },
  "11-20": {
    gameBoard: [
      ["5", "-", "1", "-", "3", "1"],
      ["+", "_", "-", "_", "+"],
      ["7", "+", "9", "+", "8", "24"],
      ["+", "_", "-", "_", "+"],
      ["4", "+", "6", "-", "2", "8"],
      ["16", "_", "-14", "_", "13"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "1",
      "0-4": "3",
      "2-2": "9",
      "4-0": "4",
      "4-2": "6",
      "4-4": "2",
    },
    revealedNumbers: [7, 8],
  },
  "11-21": {
    gameBoard: [
      ["3", "+", "4", "+", "8", "15"],
      ["-", "_", "+", "_", "-"],
      ["5", "+", "1", "-", "2", "4"],
      ["-", "_", "+", "_", "-"],
      ["9", "+", "7", "+", "6", "22"],
      ["-11", "_", "12", "_", "0"],
    ],
    answers: {
      "0-0": "3",
      "0-2": "4",
      "0-4": "8",
      "2-2": "1",
      "4-0": "9",
      "4-2": "7",
      "4-4": "6",
    },
    revealedNumbers: [5, 2],
  },
  "11-22": {
    gameBoard: [
      ["8", "-", "5", "-", "3", "0"],
      ["+", "_", "-", "_", "-"],
      ["1", "+", "4", "+", "9", "14"],
      ["-", "_", "-", "_", "+"],
      ["7", "+", "2", "+", "6", "15"],
      ["2", "_", "-1", "_", "0"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "5",
      "0-4": "3",
      "2-2": "4",
      "4-0": "7",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [1, 9],
  },
  "11-23": {
    gameBoard: [
      ["7", "+", "1", "+", "5", "13"],
      ["-", "_", "-", "_", "+"],
      ["6", "-", "9", "-", "4", "-7"],
      ["+", "_", "-", "_", "-"],
      ["8", "+", "3", "-", "2", "9"],
      ["9", "_", "-11", "_", "7"],
    ],
    answers: {
      "0-0": "7",
      "0-2": "1",
      "0-4": "5",
      "2-2": "9",
      "4-0": "8",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [6, 4],
  },
  "11-24": {
    gameBoard: [
      ["8", "-", "4", "-", "9", "-5"],
      ["+", "_", "+", "_", "+"],
      ["6", "-", "5", "+", "2", "3"],
      ["-", "_", "+", "_", "-"],
      ["3", "+", "7", "+", "1", "11"],
      ["11", "_", "16", "_", "10"],
    ],
    answers: {
      "0-0": "8",
      "0-2": "4",
      "0-4": "9",
      "2-2": "5",
      "4-0": "3",
      "4-2": "7",
      "4-4": "1",
    },
    revealedNumbers: [6, 2],
  },
  "11-25": {
    gameBoard: [
      ["4", "-", "9", "-", "7", "-12"],
      ["-", "_", "-", "_", "-"],
      ["2", "-", "5", "+", "8", "5"],
      ["-", "_", "-", "_", "-"],
      ["6", "+", "1", "+", "3", "10"],
      ["-4", "_", "3", "_", "-4"],
    ],
    answers: {
      "0-0": "4",
      "0-2": "9",
      "0-4": "7",
      "2-2": "5",
      "4-0": "6",
      "4-2": "1",
      "4-4": "3",
    },
    revealedNumbers: [2, 8],
  },
  "11-26": {
    gameBoard: [
      ["9", "+", "8", "+", "1", "18"],
      ["-", "_", "+", "_", "+"],
      ["4", "-", "5", "-", "6", "-7"],
      ["+", "_", "+", "_", "-"],
      ["7", "+", "2", "+", "3", "12"],
      ["12", "_", "15", "_", "4"],
    ],
    answers: {
      "0-0": "9",
      "0-2": "8",
      "0-4": "1",
      "2-2": "5",
      "4-0": "7",
      "4-2": "2",
      "4-4": "3",
    },
    revealedNumbers: [4, 6],
  },
  "11-27": {
    gameBoard: [
      ["1", "+", "8", "-", "2", "7"],
      ["-", "_", "-", "_", "+"],
      ["4", "-", "3", "+", "6", "7"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "7", "-", "5", "11"],
      ["6", "_", "12", "_", "3"],
    ],
    answers: {
      "0-0": "1",
      "0-2": "8",
      "0-4": "2",
      "2-2": "3",
      "4-0": "9",
      "4-2": "7",
      "4-4": "5",
    },
    revealedNumbers: [4, 6],
  },
  "11-28": {
    gameBoard: [
      ["6", "+", "8", "+", "3", "17"],
      ["+", "_", "+", "_", "-"],
      ["2", "+", "1", "-", "7", "-4"],
      ["-", "_", "-", "_", "+"],
      ["4", "+", "5", "-", "9", "0"],
      ["4", "_", "4", "_", "5"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "8",
      "0-4": "3",
      "2-2": "1",
      "4-0": "4",
      "4-2": "5",
      "4-4": "9",
    },
    revealedNumbers: [2, 7],
  },
  "11-29": {
    gameBoard: [
      ["5", "+", "8", "-", "4", "9"],
      ["+", "_", "+", "_", "-"],
      ["9", "+", "1", "-", "7", "3"],
      ["+", "_", "-", "_", "+"],
      ["6", "+", "3", "+", "2", "11"],
      ["20", "_", "6", "_", "-1"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "8",
      "0-4": "4",
      "2-2": "1",
      "4-0": "6",
      "4-2": "3",
      "4-4": "2",
    },
    revealedNumbers: [9, 7],
  },
  "11-30": {
    gameBoard: [
      ["5", "-", "3", "-", "7", "-5"],
      ["-", "_", "-", "_", "-"],
      ["4", "+", "9", "-", "1", "12"],
      ["-", "_", "-", "_", "+"],
      ["8", "+", "2", "+", "6", "16"],
      ["-7", "_", "-8", "_", "12"],
    ],
    answers: {
      "0-0": "5",
      "0-2": "3",
      "0-4": "7",
      "2-2": "9",
      "4-0": "8",
      "4-2": "2",
      "4-4": "6",
    },
    revealedNumbers: [4, 1],
  },
  "11-31": {
    gameBoard: [
      ["6", "-", "3", "+", "2", "5"],
      ["-", "_", "+", "_", "+"],
      ["1", "-", "4", "-", "8", "-11"],
      ["-", "_", "-", "_", "+"],
      ["9", "-", "5", "+", "7", "11"],
      ["-4", "_", "2", "_", "17"],
    ],
    answers: {
      "0-0": "6",
      "0-2": "3",
      "0-4": "2",
      "2-2": "4",
      "4-0": "9",
      "4-2": "5",
      "4-4": "7",
    },
    revealedNumbers: [1, 8],
  },
};
