import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import StatsSection from "../../../common/StatsSection";

interface WinnerModalProps {
  isOpen: boolean;
  setIsModalVisible: Function;
}

const WinnerModal = ({ isOpen, setIsModalVisible }: WinnerModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setIsModalVisible(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>You win!</ModalHeader>
        <ModalBody>
          <div>Check in tomorrow for tomorrow's puzzle</div>
          <StatsSection gameName="squares" />
        </ModalBody>

        <ModalCloseButton />

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => setIsModalVisible(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WinnerModal;
