import React from "react";
import { Text } from "@chakra-ui/react";

interface GuessedWordsProps {
  guessedWords: string[];
  solutionWord: string;
}

const getLetterColor = (
  guessedWord: string,
  solutionWord: string,
  i: number
): string => {
  const letter = guessedWord[i];
  if (i > solutionWord.length - 1) {
    if (solutionWord.includes(letter)) {
      return "tomato";
    }
    return "black";
  } else if (letter === solutionWord[i]) {
    return "teal";
  } else if (solutionWord.includes(letter)) {
    return "tomato";
  }
  return "black";
};

const rows = [0, 1, 2, 3, 4, 5];

const GuessedWords = ({ guessedWords, solutionWord }: GuessedWordsProps) => {
  return (
    <div
      style={{

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "36vh",
          width: "80vw",
          maxWidth: "300px"
        }}
      >
        {rows.map((idx) => (
          <div
            style={{
              height: "6vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "solid",
              backgroundColor: guessedWords.length === idx ? "yellow" : "white",
            }}
          >
            {guessedWords[idx] &&
              Array.from(guessedWords[idx]).map((letter, i) => (
                <Text
                  as="b"
                  fontSize="xl"
                  color={getLetterColor(guessedWords[idx], solutionWord, i)}
                >
                  {letter}
                </Text>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GuessedWords;
