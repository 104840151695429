import { SimpleGrid, Container } from "@chakra-ui/react";
import React from "react";
import { SelectedSquare } from "../MathSquares";
import { SquareInputs, isRevealedNumber } from "../utils/utils";
import SelectCell from "./SelectCell";
import AnswerRowCell from "./AnswerRowCell";
import AnswerColumnCell from "./AnswerColumnCell";

interface GameDisplayProps {
  gameBoard: string[][];
  setSelectedSquare: Function;
  selectedSquare: SelectedSquare | null;
  squareInputs: any;
  answers: SquareInputs;
}

const GameDisplay = ({
  gameBoard,
  selectedSquare,
  setSelectedSquare,
  squareInputs,
  answers,
}: GameDisplayProps) => {
  return (
    <div
      style={{
        textAlign: "center",
        marginBottom: "10px",
      }}
    >
      <Container>
        {gameBoard.map((row, i) => (
          <SimpleGrid columns={6}>
            {row.map((item, j) => {
              if (i === 5) {
                return (
                  <AnswerColumnCell
                    gameBoard={gameBoard}
                    item={item}
                    column={j}
                    squareInputs={squareInputs}
                    answers={answers}
                  />
                );
              }
              if (isRevealedNumber(i, j)) {
                return (
                  <div
                    style={{
                      border: "solid",
                      borderColor: "black",
                      backgroundColor: "grey",
                      color: "white",
                    }}
                  >
                    {item}
                  </div>
                );
              }
              if (j % 2 === 0 && i % 2 === 0) {
                return (
                  <SelectCell
                    selectedSquare={selectedSquare}
                    i={i}
                    j={j}
                    setSelectedSquare={setSelectedSquare}
                    value={squareInputs[`${i}-${j}`]}
                  />
                );
              } else if (j === 5) {
                return (
                  <AnswerRowCell
                    gameBoard={gameBoard}
                    item={item}
                    row={i}
                    squareInputs={squareInputs}
                    answers={answers}
                  />
                );
              } else if (item === "_") {
                return (
                  <div
                    style={{
                      backgroundColor: "black",
                    }}
                  >
                    {""}
                  </div>
                );
              }
              return (
                <div
                  style={{
                    backgroundColor: "grey",
                  }}
                >
                  {item}
                </div>
              );
            })}
          </SimpleGrid>
        ))}
      </Container>
    </div>
  );
};

export default GameDisplay;
