import React from "react";
import Navbar from "../../common/Navbar";
import Gameplay from "./Gameplay";
import { Heading } from "@chakra-ui/react";

const Expansion = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: "10vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Heading
            style={{
              marginBottom: "2vh",
            }}
          >
            Expansion
          </Heading>
          <Gameplay />
        </div>
      </div>
    </>
  );
};

export default Expansion;
