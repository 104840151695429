import React from "react";
import {
  alphabet,
  isOnlyLettersAndNumbers,
} from "../../../common/utils/letters";
import { isMobile } from "../../../common/utils/mobile";
import { getKeyToValue } from "../utils";

interface WikiTextDisplayProps {
  text: string[];
  alphabetMapping: Map<string, string>;
  reverseMapping: Map<string, string>;
  correctlyGuessedLetters: string[];
}

const WikiTextDisplay = ({
  text,
  alphabetMapping,
  reverseMapping,
  correctlyGuessedLetters,
}: WikiTextDisplayProps) => {
  return (
    <div
      style={{
        border: "solid",
        overflowY: "auto",
        height: isMobile() ? "40vh" : "60vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div>
        {text.map((word) => {
          if (!isOnlyLettersAndNumbers(word)) {
            return word;
          }
          return (
            <span>
              {Array.from(word).map((letter) => {
                if (correctlyGuessedLetters.includes(letter)) {
                  return (
                    <span
                      style={{
                        color: "darkgreen",
                        fontWeight: "bold",
                      }}
                    >
                      {reverseMapping.get(letter.toLowerCase())}
                    </span>
                  );
                } else {
                  return (
                    <span
                      style={{
                        color: "peru",
                      }}
                    >
                      {letter}
                    </span>
                  );
                }
              })}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default WikiTextDisplay;
