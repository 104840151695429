import {
  Button,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Centered from "../../../common/Centered";
import Inputs from "./Inputs";

const GuessModal = ({
  isOpen,
  setModalOpen,
  letter,
  handleSubmit,
  correctLettersGuessed
}: {
  isOpen: boolean;
  setModalOpen: Function;
  letter: string;
  handleSubmit: Function;
  correctLettersGuessed : string[]
}) => {
  const [inputText, setInputText] = useState("");
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setModalOpen(false);
        setInputText("");
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Guess Cryptogram</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Heading
              style={{
                margin: "10px",
              }}
            >
              {letter.toUpperCase()}
            </Heading>
            <Heading
              style={{
                margin: "10px",
              }}
            >
              =
            </Heading>
            <Input
              readOnly
              htmlSize={4}
              width="auto"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
          </div>
          <Inputs handleInput={(guessedLetter : string) => handleSubmit(letter, guessedLetter)} correctLettersGuessed={correctLettersGuessed}/>
        </ModalBody>
        <div
          style={{
            marginTop: "2vh",
          }}
        >
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                setModalOpen(false);
                setInputText("");
              }}
            >
              Close
            </Button>
            {/* <Button
              onClick={() => {
                handleSubmit(letter, inputText);
                setInputText("");
              }}
            >
              Submit
            </Button> */}
          </ModalFooter>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default GuessModal;
