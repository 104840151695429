import React, { useEffect, useState } from "react";
import Inputs from "./components/Inputs";
import GameDisplay from "./components/GameDisplay";
import { getDailyHilo } from "./dailyHilo";
import { getCurrentAnswer, getLocalStorageData } from "./utils";
import WinnerModal from "./components/WinnerModal";
import LoserModal from "./components/LoserModal";
import ResultDisplay from "./components/ResultDisplay";
import EqualsColumn from "./components/EqualsColumn";
import { Heading } from "@chakra-ui/react";
import { LocalHiloData } from "./utils";
import { getDailyKey } from "../../common/utils/localStorage";
import Navbar from "../../common/Navbar";

const initGameState = () => {
  const list: string[][] = [[], [], [], [], []];
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < 7; j++) {
      list[i].push("_");
    }
  }
  return list;
};

const Hilo = () => {
  const [gameState, setGameState] = useState(initGameState());
  const [currentSquare, setCurrentSquare] = useState(0);
  const [currentRow, setCurrentRow] = useState(0);
  const [target, setTarget] = useState(-1);
  const [numbers, setNumbers] = useState<number[]>([]);
  const [resultCol, setResultCol] = useState<string[]>([
    "_",
    "_",
    "_",
    "_",
    "_",
  ]);
  const [isWinner, setWinner] = useState<boolean>(false);
  const [isModalShowing, setModalShowing] = useState<boolean>(true);

  useEffect(() => {
    init();
  }, []);

  const updateLocalStorage = (
    gameState: string[][],
    currentRow: number,
    resultCol: string[],
    isWinner: boolean
  ) => {
    const localStorageData = getLocalStorageData();
    const dailyHiloKey = getDailyKey();
    const localHiloData = {
      ...localStorageData,
      [dailyHiloKey]: {
        gameState,
        currentRow,
        resultCol,
        isWinner,
      },
    };
    localStorage.setItem("hilo", JSON.stringify(localHiloData));
  };

  const init = () => {
    const localStorageData = getLocalStorageData();
    const dailyHiloKey = getDailyKey();
    if (
      localStorageData !== null &&
      localStorageData[dailyHiloKey] !== undefined
    ) {
      const todayData = localStorageData[dailyHiloKey] as LocalHiloData;
      setGameState(todayData.gameState);
      setCurrentRow(todayData.currentRow);
      setResultCol(todayData.resultCol);
      setWinner(todayData.isWinner);
    }
    const dailyHilo = getDailyHilo();
    setTarget(dailyHilo.target);
    setNumbers(dailyHilo.startingNumbers);
  };

  const handleNumPress = (num: number) => {
    if (currentSquare < 7) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare] = num.toString();
      setGameState(updatedGameState);
      setCurrentSquare(currentSquare + 1);
    }
  };

  const handleOperationPress = (op: string) => {
    if (currentSquare < 7) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare] = op;
      setGameState(updatedGameState);
      if (currentSquare === 8) {
        setCurrentRow(currentRow + 1);
      } else {
        setCurrentSquare(currentSquare + 1);
      }
    }
  };
  const handleDelete = () => {
    if (currentSquare > 0) {
      const updatedGameState = [...gameState];
      updatedGameState[currentRow][currentSquare - 1] = "_";
      setGameState(updatedGameState);
      setCurrentSquare(currentSquare - 1);
    }
  };

  const handleSolve = () => {
    const answer = getCurrentAnswer(gameState, currentRow);
    const resultColCopy = [...resultCol];
    if (answer < target) {
      resultColCopy[currentRow] = `${answer} - Too Lo`;
    } else if (answer > target) {
      resultColCopy[currentRow] = `${answer} - Too Hi`;
    } else {
      resultColCopy[currentRow] = `${answer} - Win!`;
      setWinner(true);
    }
    updateLocalStorage(
      gameState,
      currentRow + 1,
      resultColCopy,
      answer === target
    );
    setResultCol(resultColCopy);
    setCurrentRow(currentRow + 1);
    setCurrentSquare(0);
  };
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
        }}
      >
        <div>
          {!isWinner && currentRow === 5 && (
            <LoserModal
              target={target}
              isShowing={isModalShowing}
              setModalShowing={setModalShowing}
            />
          )}
          {isWinner && (
            <WinnerModal
              isShowing={isModalShowing}
              setModalShowing={setModalShowing}
            />
          )}
          <Heading>HiLo</Heading>
          <div
            style={{
              display: "flex",
            }}
          >
            <GameDisplay
              currentRow={currentRow}
              currentSquare={currentSquare}
              gameState={gameState}
            />
            <EqualsColumn />
            <ResultDisplay
              resultCol={resultCol}
              currentRow={currentRow}
              currentSquare={currentSquare}
              gameState={gameState}
            />
          </div>
          <Inputs
            handleNumPress={handleNumPress}
            handleOperationPress={handleOperationPress}
            handleDelete={handleDelete}
            handleSolve={handleSolve}
            numbers={numbers}
            currentSquare={currentSquare}
            gameStateRow={gameState[currentRow]}
            isWinner={isWinner}
          />
        </div>
      </div>
    </>
  );
};

export default Hilo;
