import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Hilo from "./games/hilo/Hilo";
import { ChakraProvider } from "@chakra-ui/react";
import Plotle from "./games/plotle/Plotle";
import MathSquares from "./games/math-squares/MathSquares";
import { configureAnalytics } from "./configure-analytics";
import Expansion from "./games/expansion/Expansion";
import Encrypedia from "./games/encrypedia/Encrypedia";
import Account from "./pages/Account";


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/hilo",
    element: <Hilo />,
  },
  {
    path: "/plotle",
    element: <Plotle />,
  },
  {
    path: "/squares",
    element: <MathSquares />,
  },
  {
    path: "/expansion",
    element: <Expansion />,
  },
  {
    path: "/encrypedia",
    element: <Encrypedia />
  }, 
  {
    path: "/account",
    element: <Account />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider>
    <RouterProvider router={router} />
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
