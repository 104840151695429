import React from "react";

interface RedactedTextProps {
  word: string;
}

const RedactedText = ({ word }: RedactedTextProps) => {
  return (
    <>
      {Array.from(word).map((char) => (
        <span>&#9608;</span>
      ))}
    </>
  );
};

export default RedactedText;
