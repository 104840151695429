import React, { useEffect, useState } from "react";

import {
  createScrambledWordsMap,
  getPercentageUncovered,
  Scramble,
} from "../utils/wordHelpers";
import Inputs from "./Inputs";
import RedactedSection from "./RedactedSection";
import WinnerScreen from "./WinnerScreen";
import ScrambledSection from "./ScrambledSection";
import {
  getDailyKey,
  getLocalStorageData,
  setData,
} from "../../../common/utils/localStorage";
import Keyboard from "../../../common/Keyboard";
import { isMobile } from "../../../common/utils/mobile";
import HowToPlayPlotleModal from "./HowToPlayPlotleModal";
import { WikiData } from "../../../common/wikipedia/wikiapi";
import { getDailyWikiData } from "../wikipedia/wikiapi";

interface PlotleLocalStorageData {
  guessedWordsMap: any[];
  numMistakes: number;
  wikiMoviePlotData: WikiData;
  wordsMap: any[];
}

const hasGuessedMovie = (wordsMap: Map<string, string>, movieName: string) => {
  if (movieName === "") {
    return false;
  }
  if (wordsMap.has(movieName.toLowerCase())) {
    return true;
  }

  const movieNameArr = movieName.split(" ");
  for (let i = 0; i < movieNameArr.length; i++) {
    if (!wordsMap.has(movieNameArr[i].toLowerCase())) {
      return false;
    }
  }
  return true;
};

const Gameplay = () => {
  const [wikiMoviePlotData, setwikiMoviePlotData] = useState<WikiData>({
    name: "",
    plotText: [],
  });
  const [wordsMap, setWordsMap] = useState<Map<string, Scramble>>(new Map());
  const [guessedWordsMap, setGuessedWordsMap] = useState<Map<string, string>>(
    new Map()
  );
  const [guessedWordsInput, setGuessedWordsInput] = useState<string>("");
  const [numMistakes, setNumMistakes] = useState<number>(0);
  const [showHowToPlayModal, setShowHowToPlayModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gottenTitleCorrect, setGotTitleCorrect] = useState(false);
  const [wrongWordsMap, setWrongWordsMap] = useState<Map<string, string>>(
    new Map()
  );

  useEffect(() => {
    const setup = async () => {
      const wikiMoviePlotDataResult = await getDailyWikiData()
      setWordsMap(
        createScrambledWordsMap(
          wikiMoviePlotDataResult.plotText,
          wikiMoviePlotDataResult.name
        )
      );
      setwikiMoviePlotData(wikiMoviePlotDataResult);
    };
    const localStorageData = getLocalStorageData("plotle");
    if (localStorageData) {
      const plotleData = localStorageData as PlotleLocalStorageData;
      setGuessedWordsMap(new Map(plotleData.guessedWordsMap));
      setWordsMap(new Map(plotleData.wordsMap));
      setwikiMoviePlotData(plotleData.wikiMoviePlotData);
    } else {
      setup();
      setShowHowToPlayModal(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (wordsMap !== null) {
      setData(getDailyKey(), localStorage, "plotle", {
        guessedWordsMap: Array.from(guessedWordsMap.entries()),
        numMistakes,
        wikiMoviePlotData,
        wordsMap: Array.from(wordsMap!.entries()),
      });
    }
  }, [guessedWordsMap, numMistakes, wikiMoviePlotData, wordsMap]);

  const handleSubmit = () => {
    const updatedMap = new Map(guessedWordsMap);
    const guessedWordsInputLower = guessedWordsInput.toLowerCase();
    updatedMap.set(guessedWordsInputLower, "");
    if (!wordsMap!.has(guessedWordsInputLower)) {
      alert(`Oops! ${guessedWordsInput} doesn't appear in this wiki plot.`);
      const updatedWrongWordsMap = new Map(wrongWordsMap);
      updatedWrongWordsMap.set(guessedWordsInputLower, "");
      setWrongWordsMap(updatedWrongWordsMap);
    } else {
      setGuessedWordsMap(updatedMap);
    }
    if (
      !gottenTitleCorrect &&
      hasGuessedMovie(updatedMap, wikiMoviePlotData.name)
    ) {
      alert(
        "Nice! You got the movie title - now try to uncover the rest of the plot!"
      );
      setGotTitleCorrect(true);
    }

    setGuessedWordsInput("");
  };
  const splitMovieName = wikiMoviePlotData.name.split(" ");
  const isWinnerResult = false;
  if (loading) {
    return <div>Loading...</div>;
  }

  const guessedWordMapKeys = Array.from(guessedWordsMap.keys());
  const wordsMapKeys = Array.from(wordsMap.keys());
  return (
    <>
      <div>
        {showHowToPlayModal && <HowToPlayPlotleModal />}
        {guessedWordMapKeys.length >= wordsMapKeys.length && (
          <h2>Nice job! You've uncovered the plot!</h2>
        )}
        {!isWinnerResult ? (
          <div>
            <div>
              <h2>
                {wordsMap && (
                  <RedactedSection
                    textArr={splitMovieName}
                    guessedWordsMap={guessedWordsMap}
                  />
                )}
              </h2>
              <h3>
                % Uncovered:{" "}
                {getPercentageUncovered(guessedWordMapKeys, wordsMapKeys)}
              </h3>
              {guessedWordsMap && wordsMap && (
                <ScrambledSection
                  guessedWordsMap={guessedWordsMap}
                  textArr={wikiMoviePlotData.plotText}
                  wordsMap={wordsMap}
                />
              )}
            </div>
            <div>
              <Inputs
                isDisabled={guessedWordMapKeys.length >= wordsMapKeys.length}
                handleSubmit={handleSubmit}
                setGuessedWordsInput={setGuessedWordsInput}
                guessedWordsInput={guessedWordsInput}
              />
            </div>
            {isMobile() && (
              <>
                <Keyboard
                  handleClick={(letter: string) => {
                    setGuessedWordsInput(guessedWordsInput + letter);
                  }}
                  onEnterClick={() => {
                    handleSubmit();
                  }}
                  onBackClick={() => {
                    const guessedWordsInputMinusOne = guessedWordsInput.slice(
                      0,
                      guessedWordsInput.length - 1
                    );
                    setGuessedWordsInput(guessedWordsInputMinusOne);
                  }}
                />
              </>
            )}
          </div>
        ) : (
          <WinnerScreen movieName={wikiMoviePlotData.name} />
        )}
      </div>
    </>
  );
};

export default Gameplay;
