import React from "react";
import { SquareInputs } from "../utils/utils";

interface AnswerRowCellProps {
  item: string;
  row: number;
  squareInputs: SquareInputs;
  answers: SquareInputs;
  gameBoard: string[][];
}

const getBackgroundColor = (
  row: number,
  squareInputs: SquareInputs,
  answers: SquareInputs,
  item: string,
  gameBoard: string[][]
): string => {
  if (row === 2) {
    if (squareInputs["2-2"] === answers["2-2"]) {
      return "green";
    }
  } else {
    try {
      const target = parseInt(item);

      let currentTotal = parseInt(
        squareInputs[`${row}-0` as keyof SquareInputs]
      );

      if (gameBoard[row][1] === "-") {
        currentTotal -= parseInt(
          squareInputs[`${row}-2` as keyof SquareInputs]
        );
      } else {
        currentTotal += parseInt(
          squareInputs[`${row}-2` as keyof SquareInputs]
        );
      }

      if (gameBoard[row][3] === "-") {
        currentTotal -= parseInt(
          squareInputs[`${row}-4` as keyof SquareInputs]
        );
      } else {
        currentTotal += parseInt(
          squareInputs[`${row}-4` as keyof SquareInputs]
        );
      }

      if (currentTotal === target) {
        return "green";
      }
    } catch (e) {
      return "red";
    }
  }
  return "red";
};

const AnswerRowCell = ({
  item,
  row,
  squareInputs,
  answers,
  gameBoard,
}: AnswerRowCellProps) => {
  return (
    <div
      style={{
        marginLeft: "3vw",
        color: "white",
        backgroundColor: getBackgroundColor(
          row,
          squareInputs,
          answers,
          item,
          gameBoard
        ),
      }}
    >
      <div>{item}</div>
    </div>
  );
};

export default AnswerRowCell;
