export const getDailyKey = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  return `${month}-${day}`;
};

export const setData = (key : string, localStorage : Storage, gameName: string, dataToStore: any) => {
  localStorage.removeItem(gameName);
  localStorage.setItem(
    gameName,
    JSON.stringify({ [key]: { ...dataToStore } })
  );

};

export const getLocalStorageData = (gameName: string) => {
  const data = localStorage.getItem(gameName);
  const dailyKey = getDailyKey();
  if (data) {
    const localStorageData = JSON.parse(data) as any;
    if (localStorageData[dailyKey] !== undefined) {
      const todayData = localStorageData[dailyKey];
      return todayData;
    }
  }
  return undefined;
};

export const checkLocalStorageIfWinner = (gameName: string): boolean => {
  const data = localStorage.getItem(gameName);
  const squaresKey = getDailyKey();
  if (data) {
    try {
      const localStorageData = JSON.parse(data) as any;
      if (localStorageData[squaresKey] !== undefined) {
        const todayData = localStorageData[squaresKey];
        return todayData.isWinner;
      } else {
        localStorage.setItem(
          gameName,
          JSON.stringify({
            ...localStorageData,
            [squaresKey]: { isWinner: false },
          })
        );
      }
    } catch (e) {
      localStorage.removeItem(gameName);
    }
  } else {
    // first time playing
    localStorage.setItem(
      gameName,
      JSON.stringify({ [squaresKey]: { isWinner: false } })
    );
  }
  return false;
};

export const handleSettingWinner = (gameName: string) => {
  const data = localStorage.getItem(gameName);
  const squaresKey = getDailyKey();
  if (data) {
    const localStorageData = JSON.parse(data) as any;
    localStorage.setItem(
      gameName,
      JSON.stringify({
        ...localStorageData,
        [squaresKey]: { isWinner: true },
      })
    );
  } else {
    localStorage.setItem(
      gameName,
      JSON.stringify({
        [squaresKey]: { isWinner: true },
      })
    );
  }
};
