import React from "react";

const EqualsColumn = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(5, 1fr)",
        boxSizing: "border-box",
        rowGap: "5px",
      }}
    >
      {["=", "=", "=", "=", "="].map((equal) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            boxSizing: "border-box",
            columnGap: "5px",
          }}
        >
          <div
            style={{
              padding: "10px",
            }}
          >
            {equal}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EqualsColumn;
