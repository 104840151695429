import React from "react";
import { Scramble } from "../utils/wordHelpers";
import { isMobile } from "../../../common/utils/mobile";
import RedactedText from "./RedactedText";
import { isOnlyLettersAndNumbers } from "../../../common/utils/letters";

interface ScrambledSectionProps {
  guessedWordsMap: Map<string, string>;
  textArr: string[];
  wordsMap: Map<string, Scramble>;
}

const ScrambledSection = ({
  guessedWordsMap,
  textArr,
  wordsMap,
}: ScrambledSectionProps) => {
  return (
    <div
      style={{
        border: "solid",
        overflowY: "auto",
        height: isMobile() ? "40vh" : "60vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "95%",
        }}
      >
        {textArr.map((word: string) => {
          const lowerCaseWord = word.toLowerCase();
          if (!isOnlyLettersAndNumbers(word)) {
            return <span>{word}</span>
          } else if (guessedWordsMap.has(lowerCaseWord)) {
            return (
              <span style={{ backgroundColor: "green", color: "white" }}>
                {word}
              </span>
            );
          }
          return <span>{wordsMap.get(lowerCaseWord)?.scrambledWord}</span>;
        })}
      </div>
    </div>
  );
};

export default ScrambledSection;
