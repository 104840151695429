import React from "react";
import { Heading } from "@chakra-ui/react";
import Gameplay from "./components/Gameplay";
import Navbar from "../../common/Navbar";

const Plotle = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10vh",
          textAlign: "center",
        }}
      >
        <div>
          <Heading>Plotle</Heading>
          <Gameplay />
        </div>
      </div>
    </>
  );
};

export default Plotle;
