import { alphabet } from "../../common/utils/letters";

export const getKeyToValue = (map : Map<string, string>, expectedValue : string) => {
    for (const [key, value] of Array.from(map.entries())) {
        if (value === expectedValue) {
            return key
        }
    }
}

export const getLettersRemaining = (correctlyGuessedLetters : string[], reverseAlphabetMapping : Map<string, string>) => {
    const lettersRemaining : string[] = [];
    const alphabetArr = alphabet.split("");
    for (let i = 0; i < alphabetArr.length; i++) {
      if (correctlyGuessedLetters.includes(alphabetArr[i])) {
        lettersRemaining.push(reverseAlphabetMapping.get(alphabetArr[i])!);
      }
    }
    return lettersRemaining
  }